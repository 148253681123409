import React, { Component } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import Header from "../Header/Header";
import logo from '../images/login-logo.png';
import nebutech from '../images/nebutech-logo.svg';
import qrlink from '../images/signupqr.svg';

export default class ExpiredPollResults extends Component {
    state = {
        userAnswer: null,
        currentIndex: 0,
        options: [],
        count: [],
        disabled: true,
        completed: 0,
        pollQuestion: "",
        isLoading: true,
        id:this.props.id
    }
    componentDidMount() {
        this.getPollsResultsById();
        console.log("propsData", this.props)
    }
    getPollsResultsById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + `user/getPollResultByIdWeb/${this.state.id}`, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    console.log("success");
                    const result = response.data.pollResult
                    self.setState({
                        topic: result.topic,
                        pollQuestion: result.question,
                        options: result.options,
                        isLoading: false
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });

    };


    render() {

        const { isLoading, pollQuestion, options, count, topic, uAns, userAnswer } = this.state
        if (isLoading) {
            return <div>{console.log("Loading")}</div>
        }
        var percent = options.map((opt, idx) => (
            Math.round((opt.percentage + Number.EPSILON) * 100 / 100)
        ))
        console.log("percentakdhs", percent)

        return (
            <div >
                <div style={{ backgroundColor: "#31197c" }}>
                    <div className="expired-card">
                        <div className="mt-3 topic-name"><span className="">Poll from Sceem on Topic : {topic.replace(/ *\([^]*\) */g, "")}</span></div>
                        <Col xs={12}>
                            <Card className="mt-3 poll-result-card">
                                <div className="poll-question-r">{pollQuestion}</div>
                                {/* <div style={{ alignItems: "center", margin: "4px 10px" }}> */}
                                <div>
                                    {options.map((opt, idx) => (
                                        <Progressbar uAns={opt.isAnswer} id={opt.id} hValue={percent} option={opt.option} percentage={percent[idx]} />
                                    ))}
                                </div>
                            </Card></Col>
                        <br />
                        <Link to="/"> <center><Button variant="default" className="goback-poll-btn"><b> &nbsp;Home &nbsp;</b></Button></center></Link>
                    </div><br />
                </div>
                <Row style={{ width: "100%" }}>
                    <Col md={4} xs={12} sm={12}></Col>
                    <Col md={4} xs={12} sm={12}>
                        <div className="bg-border-top">
                            <div className="background-new" ><br />
                                <div className="app-border-top-pr" >
                                    <div style={{ display: "flex" }}> <img alt="" src={logo} className="sceem-logo-download-new-pr" /> <div className="ml-2 article-chain-text-pr">Creating knowledge chain</div></div>
                                    <center className="become-member">Become a member !!</center>
                                    <center> <img alt="" src={qrlink} style={{ width: "28%" }} /></center>
                                    <center className="box-text-lp-pr"><a href="https://mob.sceem.org/">https://mob.sceem.org</a></center>
                                    {/* <center className="box-text-lp-pr">Visit </center>
                                    <center className="box-text-lp-pr"><a href="https://mob.sceem.org/">mob.sceem.org</a></center>
                                    <center className="box-text-lp-pr">Or </center>
                                    <center className="box-text-lp-pr"><a href="https://www.sceem.org/signup">https://www.sceem.org/signup</a></center> */}
                                    <img alt="" src={nebutech} className="powered-by-nebutech-new-pr" />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} xs={12} sm={12}></Col>
                </Row>
            </div >
        )
    }

}
const Progressbar = (props) => {
    const { percentage, option, uAns, selected,hValue, id } = props;
    const backgroundColor = `linear-gradient(to right, #EEEBFF 0%, #EEEBFF ${percentage}%, white ${percentage}%, white 99%)`
    const containerStyles = {
        background: backgroundColor,
    }
    const fillerStyles = {
        height: '7vh',
        maxWidth: "100%",
        position: 'absolute',
        top: "-9px",
        left: 0,
        zIndex: 1,
        backgroundColor: "#EEEBFF",
        border: "1px solid #b0a4f5",
        borderRadius: 8,
        transition: "width 4s",
        transitionTimingFunction: "linear",
        width: `${percentage}%`,
    }
    const isSelected = {
        border: "2px solid #DB6C00",
        borderRadius: 10,
        background: backgroundColor
    }
    const isHighest = {
        border: "2px solid #31197C",
        borderRadius: 10,
        background: backgroundColor
    }
    const labelStyles = {
        color: 'black',
        borderColor: "#362B79",
        fontWeight: 500,
        fontFamily: "Open Sans",
        fontSize: "14px",
        position: "relative",
        marginTop: '1px',
        height: '6.7vh',
        borderRadius: 8,
    }
    const optionLabels = {
        fontWeight: 600,
        margin: 'auto',
        height: '6.7vh',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
    const largestElement = (arr) => {
        return Math.max(...arr);
    }

    return (
        <div style={ largestElement(hValue) === percentage ? isHighest : containerStyles} className="container_class">
            <div style={labelStyles}>
                <div style={optionLabels} className="ml-1">{option}</div>
            </div>
            <div style={{ fontWeight: "600", marginRight: "2px"}}>{`${percentage}%`}</div>
        </div>
     
    );
};
