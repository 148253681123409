import { React, Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Navbar, Nav, Container, NavDropdown, Form, FormControl, Row, Col } from "react-bootstrap";
import { config } from "../Config/Config"
import Header1 from '../Header/Header1';
import screenshot1 from "../images/ss1.PNG";
// import mobile from "../images/mobileapp.mp4";
import mobile from "../images/screenshot1.png";
import screenshot2 from "../images/screenshot2.png";
import screenshot3 from "../images/ss3.PNG";
import screenshot4 from "../images/ss4.PNG";
import screenshot5 from "../images/screenshot5.png";
import screenshot6 from "../images/screenshot6.png";
import mobileapp from "../images/mobileapp.png";
// import link from "../images/link.png";
import link from "../images/mob-link.png";
import installapp from "../images/installapp.png";
import term from "../images/term-ss.png";
import games from "../images/games-ss.png";
import article from "../images/article-ss.png";
import userprofile from "../images/userprofile.png";
import rewards from "../images/rewards-ss.png";
import promotesme from "../images/promotesme.png";
import poll from "../images/poll-ss.png";
import answeredpoll from "../images/poll1-ss.png";
import smeprofile from "../images/smeprofile-ss.png";
import configurefees from "../images/configurefees.png";
import smeplanned from "../images/smeplanned.png";
import smerequests from "../images/smerequests.png";
import advertisement from "../images/advertisements-ss.png";
import jitbits from "../images/jitbits-ss.png";
import smeconnect from "../images/smeconnect.png";
import smeregister from "../images/registersme.png";
import followeduserprofile from "../images/followuserprofile-ss.png";
import connect from "../images/connect-ss.png";
import connectfollowing from "../images/connect-following.png";
import menu from "../images/menu-ss.png";
import favbits from "../images/favbits1.png";
import viewopportunity from "../images/viewopportunities.png";
import createopportunity from "../images/createopportunity.png";
import requestadvertisement from "../images/requestadvertisement.png";
import viewadv from "../images/viewadv.png";
import sceemloreimg from "../images/sceemlore.png";
import referfriend from "../images/referfriend.png";
import referralstatus from "../images/referralstatus.png";
import subscriptionplan from "../images/subscriptionplan.png";
import stats from "../images/stats-ss.png";
import incidentstatus from "../images/incidentstatus.png";
import incidentmenu from "../images/incidentmenu.png";
import usermenu from "../images/user-ss.png";
import sceemermenu from "../images/sceemermenu.png";
import referralmenu from "../images/referral-ss.png";
import advmenu from "../images/adv-ss.png";
import oppmenu from "../images/opp-ss.png";
import recommendedsme from "../images/recommendedsme.png";
import smeconnectss from "../images/smeconnect-ss.png";
import bookaslot from "../images/bookaslot.png";
import expressassme from "../images/expressassme.png";
import registerss from "../images/lp-register-ss.png";
import fedduppopup from "../images/fedduppopup.png";
import feddupone from "../images/feddup-popup1.png";
import fedduptwo from "../images/feddup-popup2.png";
import reportissue from "../images/reportissue.png";
import fedduprating from "../images/fedduprating.png";
import Footer from '../Footer/Footer';
import './ArticleDescription.css';
import './LandingPage.css';
import '../Header/Header1.css';

export default class KnowMore extends Component {
    constructor(props) {
        super(props);
        this.state = { clicked: false }
    }
    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }
    render() {
        return (
            <div>
                <Header1 />
                {/* <Navbar bg="#ff942c" className="header-height1">
                    <nav>
                        <div className='know-fixed'>
                            Explore More</div>
                        <div className="menu_icon1" onClick={this.handleClick}>
                            <i className={this.state.clicked ? 'fas fa-times bar-color' : 'fas fa-bars bar-color'}></i>
                        </div>
                        <ul className={this.state.clicked ? 'nav-menu1 active' : 'nav-menu1'}>
                            <li>
                                <a href="#aboutapp" className='nav-links1'>About App</a>
                                <a href="#homescreen" className='nav-links1'>Home Screen</a>
                                <a href="#quickmenu" className='nav-links1'>Quick Menu</a>
                                <a href="#leftmenu" className='nav-links1'>Left Menu</a>
                                <a href="#smeconnect" className='nav-links1'>SME Connect</a>
                            </li>
                        </ul>
                    </nav>
                </Navbar> */}
                <div style={{ marginTop: "4rem" }}>
                    {/* <center><div style={{ borderBottomLeftRadius: "5px",borderBottomRightRadius: "5px", background: "#FF942C", padding: "6px", fontWeight: "600", fontFamily: "Open Sans", color: "white", fontSize: "18px" }}>Know more about Sceem</div></center><br /> */}

                    {/* <Row style={{ width: "100%" }}>
                        <Col md={6} xs={12}>
                            <div style={{ alignItems: "left", marginLeft: "4rem" }}><li>This is the page which comes after login</li>
                                <li>Bell icon is for notifications.</li>
                                <li>Below that are the authors following by the users.</li>
                                <li>Then topics that are selected by the users.</li>
                                <li>And then the articles which are published on last week.</li>
                                <li>Below that are the advertisements</li>
                            </div>
                        </Col>
                        <Col md={6} xs={12}>
                            <center> <img src={screenshot} style={{ width: "240px", height: "400px" }} /></center>
                        </Col>
                    </Row> */}
                    {/* <div className="know-more-lr"><h6>Home</h6><li>This is the page which comes after login</li>
                        <li>Bell icon is for notifications.</li>
                        <li>Below that are the authors following by the users.</li>
                        <li>Then topics that are selected by the users.</li>
                        <li>And then the articles which are published on last week.</li>
                        <li>Below that are the advertisements</li>
                        <h6 className='mt-2'>JitBits</h6>
                        <li>Here we are displaying the articles which are published on last one month</li>
                        <h6 className='mt-2'>FavBits</h6>
                        <li>Here we are displaying the articles which are bookmarked by the user</li>
                    </div> */}

                    <div id="aboutapp" className='service-more'>
                        <div className='papers'>
                            {/* <h2>
                                <center style={{ fontWeight: "600", fontFamily: "Open Sans", color: "black" }}>About Sceem mobile application</center>
                            </h2><br /> */}
                            <section>
                                <div class="section-heading">
                                    <h2><center style={{ fontWeight: "600", fontFamily: "Open Sans", color: "black" }}>About Sceem mobile application</center></h2>
                                </div>
                                <div class="section-content">
                                    <h3 style={{ paddingLeft: "5px" }}>How to Install</h3>
                                    <div className='mt-2'>
                                        <span className='li-dot'></span>Sceem is a <b>mobile application </b> tailored for Enthusiasts, Experts, Entrepreneurs, and Enterprises. Optimized for landscape mode, it is designed exclusively for <b>mobile devices</b>.
                                    </div>
                                    <div className='mt-2'><span className='li-dot'></span>Users can easily access it by entering <b>mob.sceem.org</b> in their mobile browser. They need not download Sceem from Google Play Store or Apple App Store </div>
                                    <center> <img src={link} className='link-image-mob' /></center><br />
                                    <div className='mt-2'><span className='li-dot'></span>Google Chrome and Microsoft Edge on Android OS allow users to add Sceem as a shortcut when prompted within the app. However, Safari on iOS does not support this feature.</div>
                                    <center> <img src={installapp} className='img-know-more-width' /></center><br />
                                </div>
                            </section>
                        </div>
                    </div>
                    <div id="homescreen" className='service-more'>
                        <div className='papers'>
                            {/* <h2>
                                <center style={{ fontWeight: "600", fontFamily: "Open Sans", color: "black" }}>Home Screen</center>
                            </h2><br /> */}
                            <section>
                                <div class="section-heading">
                                    <h2><center style={{ fontWeight: "600", fontFamily: "Open Sans", color: "black" }}>Home Screen</center></h2>
                                </div>
                                <div class="section-content">
                                    <h3 style={{ paddingLeft: "5px" }}>Home </h3>
                                    <div className='mt-2'><span className='li-dot'></span>Sceem mobile app is made into four sections</div>
                                    <div className='mt-2'>
                                        1) Connects<br />
                                        2) Glues – users like to interact more<br />
                                        3) Articles and Advertisements<br />
                                        4) Quick Menu<br />
                                    </div>
                                    <center> <img src={mobileapp} className='img-know-more-width' /></center><br />
                                    <div className='mt-2'><span className='li-dot'></span>Sceem user after successfully logs into <b>sceem mobile application</b> below screen appears with various features available.  Based on the user type, some of the features may be restricted.</div>
                                    <center> <img src={mobile} className='img-know-more-width' /></center><br />
                                    <div>
                                        <h3 style={{ paddingLeft: "5px" }}>You</h3>
                                        <span className='li-dot'></span>Click to reach your profile and view how it matures through various activities in <b>sceem</b>.  Share your profile to others to know about you.
                                    </div>
                                    <center> <img src={userprofile} className='link-image-mob' /></center><br />
                                    <div>
                                        <h3 style={{ paddingLeft: "5px" }}>Your Connects</h3>
                                        <span className='li-dot'></span>User can follow other <b>sceem</b> users.  This allows you to access to their profile and activities easily. Click on your connect to get more details and know about them.
                                    </div>
                                    <center> <img src={followeduserprofile} className='link-image-mob' /></center><br />
                                    <div>
                                        <h3 style={{ paddingLeft: "5px" }}>Rewards</h3>
                                        <span className='li-dot'></span>Click on Rewards to know about how you are rewarded to your participation and contribution to <b>sceem</b> family.
                                    </div>
                                    <center> <img src={rewards} className='link-image-mob' /></center><br />
                                    <div>
                                        <h3 style={{ paddingLeft: "5px" }}>Games</h3>
                                        <span className='li-dot'></span>Click on the Games icon to explore interactive games designed by <b>sceem</b> to enhance your knowledge and understanding of various supply chain elements.
                                    </div>
                                    <center> <img src={games} className='link-image-mob' /></center><br />
                                    <div>
                                        <h3 style={{ paddingLeft: "5px" }}>Polls</h3>
                                        <span className='li-dot'></span>Select the Polls option to participate in various polls on Sceem. This feature allows the community to gauge the pulse of opinions on supply chain management, technology trends, and global developments.
                                    </div>
                                    <Row style={{ width: "100%" }}>
                                        <Col sm={1} md={2}></Col>
                                        <Col sm={5} md={4}>
                                            <center style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>Poll Question</center>
                                            <center> <img src={poll} className='link-image-mob_' /></center>
                                        </Col>
                                        <Col sm={5} md={4}>
                                            <center style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>After Answering</center>
                                            <center> <img src={answeredpoll} className='link-image-mob_' /></center>
                                        </Col>
                                        <Col sm={1} md={2}></Col>
                                    </Row>
                                    <div>
                                        <h3 style={{ paddingLeft: "5px" }}>Term</h3>
                                        <span className='li-dot'></span>Discover a curated collection of supply chain terms, handpicked by <b>Sceem</b>. Click on the word to learn more about it and test your knowledge.
                                    </div>
                                    <center> <img src={term} className='link-image-mob' /></center><br />
                                    <div>         <h3 style={{ paddingLeft: "5px" }}>Promoted SMEs</h3>
                                        <span className='li-dot'></span>Users can connect Supply Chain SMEs on <b>sceem</b> app. Registered SMEs can promote their profiles to expand their reach, connect with a broader audience to share insights, offer expert advice, and engage in discussions on the latest trends.
                                    </div>
                                    <center> <img src={promotesme} className='link-image-mob' /></center><br />
                                    <div>
                                        <h3 style={{ paddingLeft: "5px" }}>Articles</h3>
                                        <span className='li-dot'></span>Users can access articles by clicking on an article description. They can filter their interests and choose articles. User can read the article, like, share and add to favorite.
                                    </div>
                                    <center> <img src={article} className='link-image-mob' /></center><br />
                                    <div>
                                        <h3 style={{ paddingLeft: "5px" }}>Advertisements</h3>
                                        <span className='li-dot'></span>Users get interesting advertisements on other company products or events or training or learning opportunities. By clicking on an advertisement, it will take to detailed advertisement page link.
                                    </div>
                                    <center> <img src={advertisement} className='link-image-mob' /></center>
                                </div>
                            </section>
                        </div>
                    </div>
                    {/* <div className="" style={{ marginLeft: "4px", marginRight: "4px", borderRadius: "5px", background: "#31197C", padding: "6px", fontWeight: "600", fontFamily: "Open Sans", color: "white", fontSize: "18px" }}>Quick Menu</div>
                    <div id="quickmenu" className='service-more'>
                        <div className='papers'> */}
                    <div id="quickmenu" className='service-more'>
                        <div className='papers'>
                            {/* <h2>
                                <center style={{ fontWeight: "600", fontFamily: "Open Sans", color: "black" }}>Quick Menu</center>
                            </h2><br /> */}
                            <section>
                                <div class="section-heading">
                                    <h2><center style={{ fontWeight: "600", fontFamily: "Open Sans", color: "black" }}>Quick Menu</center></h2>
                                </div>
                                <div class="section-content">
                                    <div className='mt-2'>
                                        <span className='li-dot'></span>Quick Menu is made into five sections.<br />
                                        1) Home<br />
                                        2) Jitbits<br />
                                        3) SME Connect<br />
                                        4) Connect<br />
                                        5) Left Menu
                                    </div>
                                    <div>
                                        <h3 style={{ paddingLeft: "5px" }}>Home</h3>
                                        <span className='li-dot'></span>Sceem user after successfully logs into <b>sceem mobile application</b> below screen appears with various features available.
                                    </div>
                                    <center> <img src={mobile} className='img-know-more-width' /></center><br />
                                    <div>
                                        <h3 style={{ paddingLeft: "5px" }}>Jitbits</h3>
                                        <span className='li-dot'></span>Jitbits are the last three months articles. Users can access articles by clicking on an article description.
                                    </div>
                                    <center> <img src={jitbits} className='link-image-mob' /></center><br />
                                    <div>
                                        <h3 style={{ paddingLeft: "5px" }}>SME Connect</h3>
                                        <span className='li-dot'></span>Users can click on SME Connect and book a slot.<br />
                                        <span className='li-dot'></span>Refer to this link <a href="#smeconnect">SME Connect (sceem.org)</a>
                                    </div>
                                    <Row style={{ width: "100%" }}>
                                        <Col sm={12} md={3}>
                                            <center style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>SME Connect</center>
                                            <center> <img src={smeconnect} className='link-image-mob_' /></center>
                                        </Col>
                                        <Col sm={12} md={3}>
                                            <center style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>Recommended SMEs</center>
                                            <center> <img src={recommendedsme} className='link-image-mob_' /></center>
                                        </Col>
                                        <Col sm={12} md={3}>
                                            <center style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>SME Profile</center>
                                            <center> <img src={smeconnectss} className='link-image-mob_' /></center>
                                        </Col>
                                        <Col sm={12} md={3}>
                                            <center style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>Book a Slot</center>
                                            <center> <img src={bookaslot} className='link-image-mob_' /></center>
                                        </Col>
                                    </Row>
                                    <div>
                                        <h3 style={{ paddingLeft: "5px" }}>Connect</h3>
                                        <span className='li-dot'></span>Users can follow and unfollow other users.
                                    </div>
                                    <Row style={{ width: "100%" }}>
                                        <Col sm={1} md={2}></Col>
                                        <Col sm={5} md={4}>
                                            <center style={{ fontSize: "18px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>Recommended</center>
                                            <center> <img src={connect} className='link-image-mob_' /></center>
                                        </Col>
                                        <Col sm={5} md={4}>
                                            <center style={{ fontSize: "18px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>Following</center>
                                            <center> <img src={connectfollowing} className='link-image-mob_' /></center>
                                        </Col>
                                        <Col sm={1} md={2}></Col>
                                    </Row>
                                </div>
                            </section>
                        </div>
                    </div>
                    {/* <div id="leftmenu" className='mt-2 know-more-lr service-know'><b>Left Menu:</b><br /> */}
                    {/* <div className="" style={{ marginLeft: "4px", marginRight: "4px", borderRadius: "5px", background: "#31197C", padding: "6px", fontWeight: "600", fontFamily: "Open Sans", color: "white", fontSize: "18px" }}>Left Menu</div>
                    <div id="leftmenu" className='service-more'>
                        <div className='papers'> */}
                    <div id="leftmenu" className='service-more'>
                        <div className='papers'>
                            {/* <h2>
                                <center style={{ fontWeight: "600", fontFamily: "Open Sans", color: "black" }}>Left Menu</center>
                            </h2><br /> */}
                            <section>
                                <div class="section-heading">
                                    <h2><center style={{ fontWeight: "600", fontFamily: "Open Sans", color: "black" }}>Left Menu</center></h2>
                                </div>
                                <div class="section-content">
                                    <div className='mt-2'>
                                        <span className='li-dot'></span>Users can click and view the menu.
                                    </div>
                                    <center> <img src={menu} className='link-image-mob' /></center><br />
                                    <div className='mt-2'>
                                        <div>  <h3 style={{ paddingLeft: "5px" }}>User</h3>
                                            <span className='li-dot'></span> User can view and update his profile. He can also change the password.</div>
                                        <center> <img src={usermenu} className='img-know-more-width' /></center><br />
                                        <div> <h3 style={{ paddingLeft: "5px" }}>Sceem-ER Connect and Sceem-ER Register</h3><span className='li-dot'></span> Refer to this link <a href="#smeconnect">SME Connect (sceem.org)</a></div>
                                        <center> <img src={sceemermenu} className='link-image-mob' /></center><br />
                                        {/* <div><span className="explore-more-text">Sceem-ER Register:</span><br /><span className='li-dot'></span> SME can register/update profile, they can configure fees for his topics and offerings, they can promote profile, they can see the bookig requests.</div> */}
                                        <div> <h3 style={{ paddingLeft: "5px" }}>FavBits</h3><span className='li-dot'></span> User can view all their bookmarked articles.</div>
                                        <center> <img src={favbits} className='img-know-more-width-f' /></center><br />
                                        <div> <h3 style={{ paddingLeft: "5px" }}>Opportunities</h3><span className='li-dot'></span> User can create opportunities such as job, internships and consultant.<br /><span className='li-dot'></span> Admin has to approve the created opportunities.<br /><span className='li-dot'></span> User can view opportunities after admin's approval.</div>
                                        <Row style={{ width: "100%" }}>
                                            <Col sm={12} md={4}>
                                                <center style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>Opportunity</center>
                                                <center> <img src={oppmenu} className='link-image-mob1_' /></center>
                                            </Col>
                                            <Col sm={12} md={4}>
                                                <center style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>Create Opportunity</center>
                                                <center> <img src={createopportunity} className='link-image-mob_' /></center>
                                            </Col>
                                            <Col sm={12} md={4}>
                                                <center style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>View Opportunities</center>
                                                <center> <img src={viewopportunity} className='link-image-mob_' /></center>
                                            </Col>
                                        </Row>
                                        <div><h3 style={{ paddingLeft: "5px" }}>Advertisements</h3><span className='li-dot'></span> User can request for advertisements.<br /><span className='li-dot'></span> Admin has to approve the requested advertisements.</div>
                                        <Row style={{ width: "100%" }}>
                                            <Col sm={12} md={4}>
                                                <center style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>Advertisements</center>
                                                <center> <img src={advmenu} className='link-image-mob1_' /></center>
                                            </Col>
                                            <Col sm={12} md={4}>
                                                <center style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>Request Advertisement</center>
                                                <center> <img src={requestadvertisement} className='link-image-mob_' /></center>
                                            </Col>
                                            <Col sm={12} md={4}>
                                                <center style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>View Advertisements</center>
                                                <center> <img src={viewadv} className='link-image-mob_' /></center>
                                            </Col>
                                        </Row>
                                        <div><h3 style={{ paddingLeft: "5px" }}>Referrals</h3><span className='li-dot'></span> User can refer his friends. <br /><span className='li-dot'></span> He can refer maximum 5 friends at once and also can view referral status</div>
                                        <Row style={{ width: "100%" }}>
                                            <Col sm={12} md={4}>
                                                <center style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>Referrals</center>
                                                <center> <img src={referralmenu} className='link-image-mob1_' /></center>
                                            </Col>
                                            <Col sm={12} md={4}>
                                                <center style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>Refer a Friend</center>
                                                <center> <img src={referfriend} className='link-image-mob_' /></center>
                                            </Col>
                                            <Col sm={12} md={4}>
                                                <center style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>Referral Status</center>
                                                <center> <img src={referralstatus} className='link-image-mob_' /></center>
                                            </Col>
                                        </Row>
                                        <div><h3 style={{ paddingLeft: "5px" }}>Sceemlore</h3><span className='li-dot'></span> User can register and view cards. After viewing all the cards user gets a certificate. <br /><span className='li-dot'></span> He can provide rating for the cards. </div>
                                        <center> <img src={sceemloreimg} className='link-image-mob' /></center><br />
                                        <div> <h3 style={{ paddingLeft: "5px" }}>Incidents</h3><span className='li-dot'></span>User can report any incident and can view raised incident details.</div>
                                        <Row style={{ width: "100%" }}>
                                            <Col sm={12} md={4}>
                                                <center style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>Incident</center>
                                                <center> <img src={incidentmenu} className='link-image-mob1_' /></center>
                                            </Col>
                                            <Col sm={12} md={4}>
                                                <center style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>Report Incident</center>
                                                <center> <img src={reportissue} className='link-image-mob_' /></center>
                                            </Col>
                                            <Col sm={12} md={4}>
                                                <center style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>Incident Details</center>
                                                <center> <img src={incidentstatus} className='link-image-mob_' /></center>
                                            </Col>
                                        </Row>
                                        <div><h3 style={{ paddingLeft: "5px" }}>Topics</h3><span className='li-dot'></span> User can select and change the topics of his interests.</div>
                                        <div><h3 style={{ paddingLeft: "5px", marginTop: "1rem" }}>Membership</h3><span className='li-dot'></span> User can subscribe to any of the memberships like Integrator, Collaborator and Orchestrator. By default membership is free for anticipator.</div>
                                        <div><h3 style={{ paddingLeft: "5px", marginTop: "1rem" }}>Transactions</h3><span className='li-dot'></span>User can view their membership transactions.</div>
                                        <div><h3 style={{ paddingLeft: "5px", marginTop: "1rem" }}>Backdrop</h3><span className='li-dot'></span> User can see the statistics of sceem, terms and conditions and data privacy.</div>
                                        <div><h3 style={{ paddingLeft: "5px", marginTop: "1rem" }}>Logout</h3><span className='li-dot'></span> This is for log out from the application.</div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    {/* <div className="" style={{ marginLeft: "4px", marginRight: "4px", borderRadius: "5px", background: "#31197C", padding: "6px", fontWeight: "600", fontFamily: "Open Sans", color: "white", fontSize: "18px" }}>SME Connect</div>
                    <div id="smeconnect" className='service-more'>
                        <div className='papers'> */}
                    <div id="smeconnect" className='service-more'>
                        <div className='papers'>
                            {/* <h2>
                                <center style={{ fontWeight: "600", fontFamily: "Open Sans", color: "black" }}>SME Connect</center>
                            </h2><br /> */}
                            <section>
                                <div class="section-heading">
                                    <h2><center style={{ fontWeight: "600", fontFamily: "Open Sans", color: "black" }}>SME Connect</center></h2>
                                </div>
                                <div class="section-content">
                                    <div className='mt-2'>
                                        <h3 style={{ paddingLeft: "5px" }}>Sceem-ER Connect</h3>
                                        <div> <span className='li-dot'></span>Here are two options. Connect and Appointments.<br />
                                            a) <b>Connect:</b> Users can click and book a slot.<br />
                                            <Row style={{ width: "100%" }}>
                                                <Col sm={12} md={3}>
                                                    <center style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>SME Connect</center>
                                                    <center> <img src={smeconnect} className='link-image-mob_' /></center>
                                                </Col>
                                                <Col sm={12} md={3}>
                                                    <center style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>Recommended SMEs</center>
                                                    <center> <img src={recommendedsme} className='link-image-mob_' /></center>
                                                </Col>
                                                <Col sm={12} md={3}>
                                                    <center style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>SME Profile</center>
                                                    <center> <img src={smeconnectss} className='link-image-mob_' /></center>
                                                </Col>
                                                <Col sm={12} md={3}>
                                                    <center style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>Book a Slot</center>
                                                    <center> <img src={bookaslot} className='link-image-mob_' /></center>
                                                </Col>
                                            </Row>
                                            b) <b>Appointments:</b> Booked slot status can be viewed.</div><br />
                                        <center> <img src={smeplanned} className='link-image-mob' /></center><br />

                                        <h3 style={{ paddingLeft: "5px" }}>Sceem-ER Register:</h3>
                                        <div><span className='li-dot'></span> Here are four options. Register/Update Profile, Configure Fees, Promote Profile and Appointments.<br />
                                            a) <b>Register/Update Profile:</b> Only paid users can register as SME. Once the user register's as SME, admin has to approve the request. He can update the profile here.<br />
                                            {/* <Row style={{ width: "100%" }}>
                                            <Col sm={1} md={2}></Col>
                                            <Col sm={5} md={4}>
                                                <center style={{ fontSize: "18px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>Register</center>
                                                <center> <img src={smeregister} className='link-image-mob_' /></center>
                                            </Col>
                                            <Col sm={5} md={4}>
                                                <center style={{ fontSize: "18px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>Update</center>
                                                <center> <img src={smeprofile} className='link-image-mob_' /></center>
                                            </Col>
                                            <Col sm={1} md={2}></Col>
                                        </Row> */}
                                            <Row style={{ width: "100%" }}>
                                                <Col sm={12} md={4}>
                                                    <center style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>Express as SME</center>
                                                    <center> <img src={expressassme} className='link-image-mob_' /></center>
                                                </Col>
                                                <Col sm={12} md={4}>
                                                    <center style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>Register</center>
                                                    <center> <img src={smeregister} className='link-image-mob_' /></center>
                                                </Col>
                                                <Col sm={12} md={4}>
                                                    <center style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#ff942c" }}>Update</center>
                                                    <center> <img src={smeprofile} className='link-image-mob_' /></center>
                                                </Col>
                                            </Row>
                                            b) <b>Configure Fees:</b> User can configure fees for his topics and offerings.<br />
                                            <center> <img src={configurefees} className='link-image-mob' /></center><br />
                                            c) <b>Promote profile:</b>This option provides extra visibility to users profile. It appears on sceem homepage by paying additional fees.  Users can click and view their detailed profile to directly book a slot to obtain the services.<br />
                                            <center> <img src={promotesme} className='link-image-mob' /></center><br />
                                            d) <b>Appointments:</b> SME can see the bookig requests.</div>
                                        <center> <img src={smerequests} className='link-image-mob' /></center><br />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <div id="reviews" className='service-more'>
                        <div className='papers'>
                            <section>
                                <div class="section-heading">
                                    <h2><center style={{ fontWeight: "600", fontFamily: "Open Sans", color: "black" }}>Review System</center></h2>
                                </div>
                                <div class="section-content">
                                    <h3 style={{ paddingLeft: "5px" }}>Feddup Popup</h3>
                                    <div className='mt-2'>
                                        <span className='li-dot'></span>FeddUp is a review system. User gets a popup when he clicks on the icon.<br />
                                        <span className='li-dot'></span>User can click on any of below items and can give rating, feddback and suggestion.<br />
                                        <span className='li-dot'></span>Latest 3 ratings can be shown. Average of all ratings is shown here as a milestone.
                                    </div>
                                    <Row style={{ width: "100%" }} className='no-gutters'>
                                        <Col sm={12} md={2}> <center> <img src={fedduppopup} className='link-image-mob1_' /></center>
                                        </Col>
                                        <Col sm={12} md={3}> <center> <img src={feddupone} className='link-image-mob1_' /></center>
                                        </Col>
                                        <Col sm={12} md={3}> <center> <img src={fedduptwo} className='link-image-mob1_' /></center>
                                        </Col>
                                        <Col sm={12} md={4}> <center> <img src={fedduprating} className='link-image-mob1_' /></center>
                                        </Col>
                                    </Row>
                                </div>
                            </section>
                        </div>
                    </div>
                    {/* <div className='papers'>
                        <section>
                            <div class="section-heading">
                                <h2><center style={{ fontWeight: "600", fontFamily: "Open Sans", color: "black" }}>Report Issue</center></h2>
                            </div>
                            <div class="section-content">
                                <h3 style={{ paddingLeft: "5px" }}>Report issue in sceem mobile app</h3>
                                <div className='mt-2'>
                                    <span className='li-dot'></span>User can report any issue related to sceem.
                                </div>
                                <center> <img src={reportissue} className='link-image-mob' /></center><br />
                            </div>
                        </section>
                    </div> */}
                </div>
                {/* <center>  <video autostart autoPlay className='video' src={mobile} type="video/mp4" /></center><br /> */}

                {/* <div className="know-more-lr"><h6><b>Articles</b></h6>
                        <li>Like: User can like an article from detailed article page.</li>
                        <li>Share: User can share an article from detailed article page.</li>
                        <li>Favourite: User can bookmark an article from detailed article page. All bookmarked articles can be viewed in FavBits</li>
                        <li>Report: User can report an article from detailed article page.</li>
                        <li>Article Poll: Author can publish a poll along with article. Users can answer this article poll.</li>
                    </div>
                    <div className="mt-3 know-more-lr"><h6><b>Rewards</b></h6>
                        <b>User:</b>
                        <li>User can get reward points by playing games, viewing an article, answering article poll, answering poll, registers for sceemlore, completes sceemlore card, refers a person, following other users and user is followed by other users. If new user registers, he also gets reward points.</li>
                        <b>Author:</b>
                        <li>Author can get reward points when he creates an article, poll and any user likes that article.</li>
                        <b>Reviewer:</b>
                        <li>Reviewer can get reward points when he creates a poll.</li>
                        <b>SME:</b>
                        <li>SME can get reward points when he creates a poll, answers questions.</li>
                    </div><br />
                    <div className="know-more-lr">
                        <li><b>Sceem-ER Connect:</b> Here are two options. Connect and Appointments.<br />
                            a) Connect: Users can click and book a slot.<br />
                            b) Appointments: Booked slot status can be viewed.<br />
                        </li>
                        <li><b>Sceem-ER Register:</b>  Here are four options. Register/Update Profile, Configure Fees, Promote Profile and Appointments.<br />
                            a) Register/Update Profile: Only paid users can register as SME. Once the user register's as SME, admin has to approve the request. He can update the profile here.<br />
                            b) Configure Fees: User can configure fees for his topics and offerings.<br />
                            c) Promote profile:This option provides extra visibility to users profile. It appears on sceem homepage by paying additional fees.  Users can click and view their detailed profile to directly book a slot to obtain the services.<br />
                            d) Appointments: SME can see the bookig requests.</li>
                    </div><br />
                    <div className="know-more-lr"><h6><b>Menu</b></h6>
                    </div>
                    <center> <img src={screenshot2} style={{ width: "240px", height: "485px" }} /></center> */}



                {/* <div className="know-more-lr"><h6><b>Author</b></h6>
                    <li>Users can click on their profile icon, and if they are paid users, they can express their interest in becoming an author.</li>
                    <li>Here author can create, edit, save and view the articles, polls</li>
                </div>
                <center className='mt-2'> <img src={screenshot3} className='img-know-more-width' /></center><br />
                <div className="know-more-lr"><h6><b>Reviewer</b></h6>
                    <li>Users can click on their profile icon, and if they are paid users, they can express their interest in becoming reviewer.</li>
                    <li>Here reviewer can review the articles. He can create, edit, save and view the polls.</li>
                </div>
                <center className='mt-2'> <img src={screenshot4} className='img-know-more-width' /></center><br />
                <div className="know-more-lr"><h6><b>SME</b></h6>
                    <li>Here SME can answer the questions. He can create, edit, save and view the polls and sceemlore.</li>

                </div >
                <center className='mt-2'> <img src={screenshot5} className='img-know-more-width' /></center><br />
                <div className="know-more-lr"><h6><b>Admin</b></h6>
                    <li>Here Admin can view the dashboard. </li>
                    <li>He can create, save, edit and view the advertisements, announcements, polls, sceemlore and games.</li>
                    <li>He can review the articles, expression of interests and opportunities. </li>
                    <li>He can block the articles. </li>
                    <li>He can verify transactions. </li>
                    <li>He can view the reports. </li>
                </div>
                <center className='mt-2'> <img src={screenshot6} className='img-know-more-width' /></center><br /> */}
                <Footer />
            </div >

        )
    }
}
