import React, { useState, useEffect } from "react";
import './ArticleDescription.css';
import './DetailedArticle.css';
import axios from 'axios';
import { Card, Button, Col, Row, Carousel } from "react-bootstrap";
import background from '../images/background.png';
import googleplay from '../images/googleplay.svg';
import sceemlogo from '../images/logo-white.svg';
import logo from '../images/login-logo.png';
import angleright from '../images/angle-right-svgrepo-com.svg';
import qrlink from '../images/signupqr.svg';
import nebutech from '../images/nebutech-logo.svg';
import article from '../images/article-new.jpeg';
import { useParams, useHistory, Link } from 'react-router-dom';
import moment from "moment-timezone";
import polls from '../images/polls-new.svg';
import { Bar } from 'react-chartjs-2';
import sceemlore from '../images/sceemlore-logo.svg';
import articlesPublished from '../images/articles published.png';
import articlesRead from '../images/articles read.png';
import milestone from '../images/milestone feddup.svg';
import campaigns from '../images/campaigns.png';
import aboutPolls from '../images/about-polls.png';
import eminentAuthors from '../images/eminent authors.png';
import qna from '../images/QnA.svg';
import opportunities from '../images/opportunity.png';
import { config } from '../Config/Config';
import { Helmet } from "react-helmet";
import Header from "../Header/Header";
import TermsAndConditions from "../Terms/Terms";
import Register from "../SignUp/Register";
import Popup from "./Popup";
import tshirt from '../images/tshirt.jpeg';
import tshirtback from '../images/tshirtback.jpeg';
import feddupicon from '../images/feddupicon.png';
import flash from '../images/flash-on-svgrepo-com.svg';
import AppLinkPopup from "./AppLinkPopup";
import StarRating from "./StarRating";

export default function DetailedArticle() {
    const history = useHistory();
    const [articleData, setArticleData] = useState([]);
    const [topicName, setTopicName] = useState("");
    const [authorName, setAuthorName] = useState("");
    const [postId, setPostId] = useState()
    const [authorImage, setAuthorImage] = useState("");
    const [authorDescription, setAuthorDescription] = useState("");
    const [campaignsCount, setCampaignsCount] = useState("");
    const [likesCount, setLikesCount] = useState("");
    const [pollsCount, setPollsCount] = useState("");
    const [publishedPostsCount, setPublishedPostsCount] = useState("");
    const [registeredUserCount, setRegisteredUserCount] = useState("");
    const [totalArticleViews, setTotalArticleViews] = useState("");
    const [totalQuestions, setTotalQuestions] = useState("");
    const [postCount, setPostCount] = useState("");
    const [cardManagementCount, setCardManagementCount] = useState("");
    const [opportunitiesCount, setOpportunitiesCount] = useState("");
    const [topicDetails, setTopicDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [productId, setProductId] = useState("");
    const [averageRatingCount, setAverageRatingCount] = useState("");
    const [noOfRaters, setNoOfRaters] = useState("");
    const [url, setUrl] = useState("");
    const [views, setViews] = useState("");
    const [question, setQuestion] = useState("");
    const [options, setOptions] = useState([]);
    const [postOptions, setPostOptions] = useState([]);
    const [submitOption, setSubmitOption] = useState(false);
    const [disabled, setDisabled] = useState(false)
    const [userAnswer, setUserAnswer] = useState(null);
    const [showSignup, setShowSignup] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [latestPosts, setLatestPosts] = useState([]);
    const [newArticle, setNewArticle] = useState(false);
    const [newId, setNewId] = useState();
    const [newTitle, setNewTitle] = useState();
    const [campaigns, setCampaigns] = useState([]);
    const { id } = useParams();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    useEffect(() => {
        getPostById();
        getLatestPosts();
        getAboutPageDetails();
        getProductDetails();
    }, []);
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSignup(!showSignup)
        }, 10000);

        return () => clearTimeout(timer);
    }, []);
    const closePopup = () => {
        setShowSignup(!showSignup)
        // setRedirect(!redirect)
        // setTimeout(() => {
        //     setRedirect(false);
        // }, 2000)
    }
    const closeRedirect = () => {
        setRedirect(!redirect)
    }
    const submitPostQuestion = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var payload = {
            "postId": parseInt(postId),
            "postQuestionOptionId": parseInt(userAnswer)
        }
        console.log("payload", payload)
        axios.post(config.userUrl + 'user/submitPostByUnregisteredUsers', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    setSubmitOption(true)
                    getPostQuestionResultByIdUnregisteredUser()
                    console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });

    };
    const getPostQuestionResultByIdUnregisteredUser = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        axios.get(config.userUrl + `user/getPostQuestionResultByIdUnregisteredUser/${id}`, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    console.log("success");
                    const result = response.data.postQuestionResult
                    if (result.length == 0) {
                        setSubmitOption(false)
                    } else
                        setQuestion(result.question)
                    setPostOptions(result.options)
                    setSubmitOption(true)
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });

    };
    const getPostById = () => {
        axios.get(config.userUrl + `user/getPostByIdWeb/${id}`)
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    console.log("success!!");
                    const articleList = response.data.post;
                    setArticleData(articleList)
                    setPostId(response.data.post[0]?.id)
                    const topic = response.data.topicDetails.topicName;
                    setTopicName(topic)
                    const authorName = response.data.authorDetails.name;
                    setAuthorName(authorName)
                    const postCount = response.data.postCount;
                    setPostCount(postCount)
                    const authorImage = response.data.authorDetails.profilePicture;
                    setAuthorImage(authorImage)
                    const authorDescription = response.data.authorDetails.description;
                    setAuthorDescription(authorDescription)
                    setTotalQuestions(response.data.authorDetails.totalQuestions)
                    setViews(response.data.userViews)
                    setQuestion(response.data.post[0]?.question)
                    setOptions(response.data.postQuestionData)
                    setCampaigns(response.data?.campaigns)

                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getProductDetails = () => {
        let data = document.getElementById('#feddup12345')?.innerHTML;
        if (data) {
            let dataId = data.split("\"");
            if (dataId[dataId.length - 1].length > 12) {
                dataId = dataId[dataId.length - 1];
                setProductId(dataId)
            }
            else if (dataId[dataId.length - 2].length > 12) {
                dataId = dataId[dataId.length - 2];
                setProductId(dataId)
            }
            else {
                setProductId(dataId)
            }
            var payload = {
                "productId": dataId
            }
        }
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        console.log("payload", payload)
        axios.post(config.feddupUserUrl + "user/getTopThreeRatingByProductId", payload, { headers: headers })
            .then(function (response) {
                console.log("getTopThreeRatingByProductId", response);
                if (response.data.status === true) {
                    setAverageRatingCount(response.data.averageRating[0].avgrating)
                    setNoOfRaters(response.data.numberOfRaters)
                    setUrl(response.data.url)
                    // avgRating: `${((Math.round(response.data.averageRating[0].avgrating * 10) / 10) / 5) * 100}%`,
                    // rating: "100" - `${((Math.round(response.data.averageRating[0].avgrating * 10) / 10) / 5) * 100}`,
                    // ratings: response.data.numberOfRating,
                    console.log("res", response.data)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    function parseMarkdown(markdownText) {
        var newMarkdownText = markdownText
            .replace(/^#(.*$)/gim, `<h5 class="heading-color"><b>$1</b></h5>`)
            .replace(/^>(.*$)/gim, '<li>$1</li>')
            .replace(/\_(.+?)\_/gim, '<u>$1</u>')
            .replace(/\%(.+?)\%/gim, '<i>$1</i>')
            .replace(/\*(.+?)\*/gim, '<b>$1</b>')
            .replace(/\n*$/gim, '<p />')
        console.log("text", newMarkdownText)
        return newMarkdownText
    }
    const b = articleData.map((i) => {
        console.log("info", (i.publishOn))
        var a = new Date(i.publishOn)
        var publish = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
        return publish;
    })
    const getAboutPageDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/aboutPageDetails', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    setCampaignsCount(response.data.campaignsCount);
                    setLikesCount(response.data.likesCount);
                    setPollsCount(response.data.pollsCount);
                    setPublishedPostsCount(response.data.publishedPostsCount);
                    setRegisteredUserCount(response.data.registeredUserCount);
                    setTopicDetails(response.data.topicDetails);
                    setTotalArticleViews(response.data.totalArticleViews);
                    setTotalQuestions(response.data.totalQuestions);
                    setOpportunitiesCount(response.data.totalOpportunitiesCount);
                    setCardManagementCount(response.data.cardManagementCount);
                    setIsLoading(false);
                }
            })
            .catch(function (error) {
                console.log("error", error);
            })
    };
    const getLatestPosts = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getLatestPosts', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    setLatestPosts(response.data.posts);
                }
            })
            .catch(function (error) {
                console.log("error", error);
            })
    };
    const redirectToLatestArticle = (id, title) => {
        setNewArticle(!newArticle)
        setNewId(id)
        setNewTitle(title)
    }
    useEffect(() => {
        if (newArticle) {
            history.push(`/${newTitle.replace(/ /g, "_")}/${newId}`);
        }
    }, [newArticle, history]);
    if (isLoading) {
        console.debug("renders: Loading...");
        return <h3 className="App"></h3>;
    }

    let filterTopics = [];
    for (let i = 0; i < topicDetails.length; i++) {
        if (topicDetails[i].postsCount >= 2) {
            filterTopics.push(topicDetails[i]);
        }
    }
    console.log("labels", filterTopics)
    function kFormatter(num) {
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K' : Math.sign(num) * Math.abs(num)
    }
    const sortedTopics = topicDetails?.sort((a, b) => b.postsCount - a.postsCount);

    // Get the top 4 topics
    const topTopics = sortedTopics?.slice(0, 4);
    const barData = {
        labels: topTopics?.map(topic => topic.topicName.replace(/ *\([^]*\) */g, "")),
        datasets: [
            {
                data: topTopics?.map(topic => topic.postsCount),
                fill: true,
                backgroundColor: ["#b587f4"],
                borderColor: "rgba(75,192,192,1)",
                barThickness: 40,
                borderRadius: ["10px"]
            }
        ]
    }
    var percent = postOptions?.map((opt, idx) => (
        Math.round((opt?.percentage + Number.EPSILON) * 100 / 100)
    ))
    const checkAnswer = answer => {
        setUserAnswer(answer)
        setDisabled(false)
    }
    const extractHeadings = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const headings = doc.querySelectorAll('h1');
        return Array.from(headings).map(h1 => h1.textContent);
    };
    return (
        <div style={{ background: "#f5f5f5" }}>
            <div>
                <Header />
                <div className="adv-left-right-gap-d"><br />
                    {/* React Bootstrap Carousel - Visible only on mobile (xs) */}
                    <Carousel interval={5000} indicators={true} className="d-block d-md-none">
                        {campaigns.map((campaign, i) => (
                            <Carousel.Item key={i}>
                                <Row className="d-flex justify-content-center" style={{ width: "100%", marginTop: "3.5rem" }}>
                                    <Col xs={12} md={4}>
                                        <Link
                                            to={`/adv/${moment(campaign?.createdAt).format('YYYYMMDD')}/${campaign?.id}`}
                                            className="link"
                                            key={`ad-${campaign?.id}`}
                                            style={{ fontFamily: 'Open Sans', zIndex: 2000 }}
                                        >
                                            <Card className="adv-card">
                                                <Row style={{ width: '100%' }} className="no-gutters">
                                                    <Col md={3} xs={3}>
                                                        <img
                                                            src={
                                                                campaign?.imageUrl === 'uploads/1649942289609advertisement.png'
                                                                    ? config.userUrl + campaign?.imageUrl
                                                                    : campaign?.imageUrl
                                                            }
                                                            className="adv-image-home-detail"
                                                            alt="Advertisement"
                                                            style={{ width: '100%' }}
                                                        />
                                                    </Col>
                                                    <Col md={8} xs={8}>
                                                        <div className="adv-detail-text">
                                                            <img src={campaign?.bannerImg} className="bannerImg-d" alt="Banner" style={{ width: '100%' }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={1} xs={1}>
                                                        <div className="arrow-adv">
                                                            <img src={angleright} style={{ width: '20px' }} alt="Arrow" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Link>
                                    </Col>
                                </Row>
                            </Carousel.Item>
                        ))}
                    </Carousel>

                    {/* Web/Tablet View - Display Grid of Campaigns */}
                    <Row className="d-none d-md-flex" style={{ marginTop: '3.5rem', width: "100%" }}>
                        {campaigns.map((campaign, i) => (
                            <Col key={i} md={4} xs={12} className="mb-3">

                                <Link
                                    to={`/adv/${moment(campaign?.createdAt).format('YYYYMMDD')}/${campaign?.id}`}
                                    className="link"
                                    key={`ad-${campaign?.id}`}
                                    style={{ fontFamily: 'Open Sans', zIndex: 2000 }}
                                >
                                    <Card className="adv-card">
                                        <Row style={{ width: '100%' }} className="no-gutters">
                                            <Col md={3} xs={3}>
                                                <img
                                                    src={
                                                        campaign?.imageUrl === 'uploads/1649942289609advertisement.png'
                                                            ? config.userUrl + campaign?.imageUrl
                                                            : campaign?.imageUrl
                                                    }
                                                    className="adv-image-home-detail"
                                                    alt="Advertisement"
                                                    style={{ width: '100%' }}
                                                />
                                            </Col>
                                            <Col md={8} xs={8}>
                                                <div className="adv-detail-text">
                                                    <img src={campaign?.bannerImg} className="bannerImg-d" alt="Banner" style={{ width: '100%' }} />
                                                </div>
                                            </Col>
                                            <Col md={1} xs={1}>
                                                <div className="arrow-adv">
                                                    <img src={angleright} style={{ width: '20px' }} alt="Arrow" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Link>
                            </Col>
                        ))}
                    </Row>
                </div>
                {articleData.map((data, id) => {
                    const headings = extractHeadings(data.content);
                    const modifiedContent = data.content.replace(/<h1>(.*?)<\/h1>/g, (match, p1) => {
                        const id = p1.replace(/\s+/g, '-').toLowerCase();
                        return `<h1 class="art-d" id="${id}">${p1}</h1>`;
                    });
                    return (
                        <div className="article-left-right-d">
                            <Row style={{ width: "100%" }} className="mt-3">
                                <Col md={8}>
                                    <Row style={{ width: "100%" }} className="no-gutters">
                                        <Col md={1} xs={4}><img alt="" src={authorImage === null ? "../author5.png" : config.userUrl + authorImage} className="author-icon-d" /></Col>
                                        <Col md={2} xs={8}>
                                            <div className="ml-2 mt-2">
                                                <span style={{ color: "black", fontFamily: "Open Sans", fontSize: "18px", fontWeight: "700" }}>{authorName}</span>
                                                <div style={{ marginTop: "2px", fontFamily: "Open Sans", fontSize: "12px" }}>{postCount == 1 ? <span>{postCount} Article Published.</span> : <span>{postCount} Articles Published.</span>}</div>
                                            </div>
                                        </Col>
                                        <Col md={9} xs={12}>
                                            <div style={{ marginTop: "0.6rem" }} className="author-desc-d">
                                                <i style={{ fontFamily: "Open Sans", fontFamily: "Raleway, sans-serif" }}>  {authorDescription} </i>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="_heading-d">{data.title}</div>
                                    <div className="mt-2 topic-art-d" style={{ fontFamily: "Raleway,sans-serif", color: "gray" }} > <span style={{ fontSize: "16px", fontWeight: "500" }}>Topic:</span> {topicName.replace(/ *\([^]*\) */g, "")}</div>
                                    <div className="topic-art-d" style={{ fontFamily: "Raleway,sans-serif", color: "gray" }}> <span style={{ fontSize: "16px", fontWeight: "500" }}>Published On:</span> {moment(data.publishOn).format("DD MMM,YYYY")} <span className="ml-2"><i class="fa fa-eye" aria-hidden="true"></i>&nbsp;{views} Views</span></div>
                                    <div className="stickytable">
                                        <div className="mt-2 table-of-contents-container">
                                            <div className="table-content" style={{ fontFamily: "Raleway,sans-serif" }}>Table of Contents</div>
                                            <div>
                                                <div onClick={toggleDropdown} style={{ border: "none" }}>
                                                    {isDropdownOpen ? <i class="fa fa-angle-up angle-size" aria-hidden="true"></i> : <i class="fa fa-angle-down angle-size" aria-hidden="true"></i>} {/* Toggle arrow */}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            {isDropdownOpen && (
                                                <div className="dd-content">
                                                    <ol>
                                                        {headings.map((heading, index) => {
                                                            const id = heading.replace(/\s+/g, '-').toLowerCase(); // Create the same ID
                                                            return (
                                                                <li key={index} className="mt-1">
                                                                    <a
                                                                        href={`#${id}`}
                                                                        style={{ color: "black" }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault(); // Prevent the default anchor behavior
                                                                            toggleDropdown();
                                                                            const element = document.getElementById(id);
                                                                            if (element) {
                                                                                element.scrollIntoView({ behavior: 'smooth' });
                                                                            }
                                                                        }}
                                                                    >
                                                                        {heading}
                                                                    </a>
                                                                </li>
                                                            );
                                                        })}
                                                        {data.question && (
                                                            <li className="mt-1">
                                                                <a href="#article-poll" onClick={toggleDropdown} style={{ color: "black" }}>
                                                                    Article Poll
                                                                </a>
                                                            </li>
                                                        )}
                                                    </ol>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="mt-3 html-text-new-des" style={{ wordWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: modifiedContent }} />

                                    <Row style={{ width: "100%" }} className="no-gutters">
                                        <Col md={12}>  {data.question == "" ? <div style={data.question ? {} : { display: "none" }}></div> :
                                            <div id="article-poll" className="art-d-poll"> <center className="art-poll-text-d mb-3"><span><img src={polls} style={{ width: "20px" }} /></span>&nbsp;<span>Article Poll</span></center>
                                                <Row style={data.question ? { width: "100%" } : { display: "none", width: "100%" }}>
                                                    {submitOption ?
                                                        <Col xs={12} md={12}>
                                                            <div className="poll-question-card-post-d">
                                                                <center className="question_post">{question}</center>
                                                            </div>
                                                            <Card className="mb-1 poll-result-card-post-d">
                                                                <div>
                                                                    {postOptions.map((opt, idx) => (
                                                                        <Progressbar uAns={opt.isAnswer} answer={userAnswer} id={opt.id} hValue={percent} option={opt.option} percentage={percent[idx]} />
                                                                    ))}
                                                                </div>
                                                            </Card>
                                                        </Col>
                                                        :
                                                        <Col xs={12} md={12}>
                                                            <div className="poll-question-card-post-d">
                                                                <center className="question_post">{question}</center>
                                                            </div>
                                                            <Card className="poll-answer-card-post-d">
                                                                {options.map((option, idx) => {
                                                                    return (
                                                                        <div onClick={() => checkAnswer(option.id)} className={`polloptions-card-post-d ${userAnswer === option.id ? "selected" : null}`}><div className="poll-text-d">{option.option}</div></div>
                                                                    )
                                                                })}<br />
                                                                {userAnswer === null ? <center><Button variant="default" style={{ fontFamily: "Raleway, sans-serif" }} disabled={true} className="mb-1 q-submit-btn" >&nbsp;Submit &nbsp;</Button></center>
                                                                    :
                                                                    <center><Button variant="default" className="mb-1 q-submit-btn" style={{ fontFamily: "Raleway, sans-serif" }} onClick={submitPostQuestion}>&nbsp;Submit &nbsp;</Button></center>}
                                                            </Card>


                                                        </Col>
                                                    }
                                                </Row>
                                            </div>
                                        }

                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={4}>

                                    {/* <div className="table-of-contents">
                                        <div className="poll-question-card-post-d">
                                            <center className="table-content">Table of Contents</center>
                                        </div>
                                        <ol>
                                            {headings.map((heading, index) => {
                                                const id = heading.replace(/\s+/g, '-').toLowerCase(); // Create the same ID
                                                return (
                                                    <li key={index} className="mt-1">
                                                        <a href={`#${id}`} style={{ color: "black" }} onClick={(e) => {
                                                            e.preventDefault(); // Prevent the default anchor behavior
                                                            const element = document.getElementById(id);
                                                            if (element) {
                                                                element.scrollIntoView({ behavior: 'smooth' });
                                                            }
                                                        }}>
                                                            {heading}
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                            {data.question === "" ? <></> : <a href="#article-poll" style={{ color: "black" }}><li className="mt-1">Article Poll</li></a>}
                                        </ol>
                                    </div> */}
                                    {/* <div className="mt-2 article_image-d">
                                        <center><img alt="" src={config.userUrl + data.image} className="Article_Image_width-d" /></center>
                                    </div> */}
                                    <div className="latest-dd merchandise">
                                        <center className="table-content">Merchandise
                                        </center>
                                    </div>

                                    <div className="ts-left-right-d">
                                        <center><img alt="" src={config.userUrl + data.image} className="Article_Image_width-d" /></center>
                                        <Row style={{ width: "100%" }} className="mt-2 no-gutters">
                                            <Col md={6} xs={6}>
                                                <center>
                                                    <div style={{ position: 'relative', display: 'inline-block' }}>
                                                        <img src={tshirt} className="tshirt-img" alt="T-shirt" />
                                                        <img
                                                            src={config.userUrl + data.image}
                                                            style={{
                                                                position: 'absolute',
                                                                top: '39%',
                                                                left: '36%',
                                                                width: '28%',
                                                                height: 'auto',
                                                            }}
                                                            alt="Overlay"
                                                        />
                                                    </div>
                                                </center>
                                            </Col>
                                            <Col md={6} xs={6}>
                                                <center>
                                                    <div style={{ position: 'relative', display: 'inline-block' }}>
                                                        <img src={tshirtback} className="tshirt-img1" alt="T-shirt" />
                                                        <img
                                                            src={''}
                                                            style={{
                                                                display: "none",
                                                                position: 'absolute',
                                                                top: '35%',
                                                                left: '32%',
                                                                width: '32%',
                                                                height: 'auto',
                                                            }}
                                                            alt="Overlay"
                                                        />
                                                    </div>
                                                </center>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="mt-3 tshirt-left"><span className="tshirt">T-Shirt Design &nbsp;</span><span className="tshirt-sm"> ({data.title})</span></div>
                                    <div className="mt-2 tshirt tshirt-left">Rs 599</div>
                                    <div><Link to="/placeorder">
                                        <Button variant="default" className="mt-4 btn btn-block block-d" style={{ background: "#31197C", color: "white", fontFamily: "Open Sans" }}>Buy Now</Button>
                                    </Link>
                                    </div>

                                    <div className="latest-top-gap"></div>
                                    <div className="mt-4 latest-dd">
                                        <center className="table-content">Latest Articles</center>
                                    </div>
                                    <div >
                                        {latestPosts?.length !== 0 ? (
                                            latestPosts.slice(0, 5)
                                                .filter(data => postId !== data.id)
                                                .map((data, i) => (
                                                    <Card
                                                        className="mb-4 latest-art-card-d"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => redirectToLatestArticle(data.id, data.title)}
                                                    >
                                                        <Row style={{ width: "100%" }} className="no-gutters">
                                                            <Col md={4} xs={4}>
                                                                <div>
                                                                    <center>
                                                                        <img
                                                                            src={config.userUrl + data.image}
                                                                            alt={data.title}
                                                                            className="article-sm-img"
                                                                        />
                                                                    </center>
                                                                </div>
                                                            </Col>
                                                            <Col md={8} xs={8}>
                                                                <div className="ml-2">
                                                                    {data.title !== null && data.title.length > 100 ? (
                                                                        <div className="favbits-title-latest-d">{data.title.substring(0, 100)}...</div>
                                                                    ) : (
                                                                        <div className="favbits-title-latest-d ">{data.title}</div>
                                                                    )}
                                                                    <div className="mt-2 topic-d">
                                                                        Topic: {data.topicName.replace(/ *\([^]*\) */g, "")}
                                                                    </div>
                                                                    <div style={{ padding: "2px", display: "flex", flexDirection: "column" }}>
                                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>

                                                                            <div style={{ display: "flex", alignItems: "center", fontFamily: "Open Sans" }}>
                                                                                <img
                                                                                    alt=""
                                                                                    src={config.userUrl + data.authorProfilePicture}
                                                                                    className="favbits-author"
                                                                                />
                                                                                <span style={{ fontSize: "12px" }}>&nbsp;{data.authorName}</span>
                                                                            </div>
                                                                        </div>

                                                                        <div style={{ display: "flex", cursor: "pointer", color: "#b587f4", fontWeight: 600 }}
                                                                            className="mt-2 favbits-title-latest-d ">Read more &nbsp; <i class="fa fa-angle-right" aria-hidden="true" style={{ marginTop: "4.5px", fontWeight: 600 }}></i></div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                ))
                                        ) : (
                                            <center></center>
                                        )}
                                    </div>


                                </Col>
                            </Row>
                            <div className="d-block d-md-none">
                                <div className="mt-1 become-a-member-dd-m">
                                    <center className="table-content">Become a Member !!
                                    </center>
                                </div>
                                <Card className="articles-Published-dd-m">
                                    <Row style={{ width: "100%" }} className="no-gutters">
                                        <Col xs={4}>
                                            <div className="mt-1 feddup-icon-d">
                                                <img src={feddupicon} />
                                            </div>
                                            <StarRating rating={averageRatingCount} />
                                        </Col>
                                        <Col xs={8}>
                                            <div className="mt-1 ml-1 article-ratings-text">Love the Article? Share a quick review</div>
                                            <a href={config.sceemlink + url} target="_blank" style={{ textDecoration: "none" }}>
                                                <Button variant="default" className="mt-1 ml-1 btn-block visit-btn-d">Visit Feddup page</Button>
                                            </a>
                                        </Col>
                                    </Row>
                                </Card>
                                <Card className="mt-3 articles-Published-dd-m">
                                    <Row style={{ width: "100%" }} className="no-gutters">
                                        <Col xs={7}>
                                            <img alt="" src={logo} className="mt-1 sceem-logo-download-new-d-m" />
                                            <div className="mt-2 scanner-text-d-m">Creating Knowledge Chain</div>
                                            <div className="mt-2 scanner-text-sm-d-m">Unifying Knowledge, Elevate Supply Chain: Where Innovation Meets Insight.</div>
                                        </Col>
                                        <Col xs={5}>
                                            <div style={{ marginTop: "10px", fontSize: "12px", fontWeight: 600, fontFamily: "Open Sans" }}>
                                                <a href="https://mob.sceem.org/" style={{ color: "black" }}>https://mob.sceem.org</a>
                                            </div>
                                            <center className="mt-3"><img alt="" src={qrlink} style={{ width: "50%", border: "1px solid gray" }} /></center>
                                            <center className="mt-2"><img alt="" src={nebutech} className="powered-by-nebutech-new-d-m" /></center>
                                        </Col>
                                    </Row>
                                </Card>

                                <div className="ml-2 mr-2">
                                    <Row style={{ width: "100%" }} className="mt-3 no-gutters">
                                        <Col xs={4}>
                                            <Card className="articles-Published-d-m">
                                                <center>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <img alt="" src={articlesPublished} style={{ width: "18px", height: "18px" }} />
                                                        <div className="ml-1 text-in-about-d-m">Published</div>
                                                    </div>
                                                    <div className="bold-text-d-m">{kFormatter(publishedPostsCount)}</div>
                                                </center>
                                            </Card>
                                        </Col>
                                        <Col xs={4}>
                                            <Card className="articles-Published-d-m">
                                                <center>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <img alt="Read Icon" src={articlesRead} style={{ width: "18px", height: "18px" }} />
                                                        <div className="ml-1 text-in-about-d-m">Read</div>
                                                    </div>
                                                    <div className="bold-text-d-m">{kFormatter(totalArticleViews)}</div>
                                                </center>
                                            </Card>
                                        </Col>
                                        <Col xs={4}>
                                            <Card className="articles-Published-d-m">
                                                <center>
                                                    <div style={{ display: "flex", alignItems: "center" }}> <img alt="" src={aboutPolls} style={{ width: "18px", height: "18px" }} />  <div className="ml-1 text-in-about-d-m">Polls</div></div>
                                                    <div className="bold-text-d-m">{kFormatter(pollsCount)}</div>
                                                </center>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                                <Row style={{ width: "100%" }}>
                                    <Col>
                                        <Card className="mt-3 articles-graph-d-m">
                                            <div> <center style={{ marginTop: "-8px" }} className="top-articles-per-topic-d-m">Top Articles per Topic</center>
                                                <Bar data={barData}
                                                    options={{
                                                        scales: {
                                                            scaleOverride: true,
                                                            x: {
                                                                grid: {
                                                                    display: false,
                                                                },
                                                            },
                                                            y: {
                                                                grid: {
                                                                    display: false,
                                                                },
                                                                ticks: {
                                                                    display: false,
                                                                },
                                                            },
                                                        },
                                                        label: {
                                                            display: true
                                                        },
                                                        plugins: {
                                                            legend: { display: false }
                                                        },
                                                        tooltips: {
                                                            callbacks: {
                                                                label: function (tooltipItem) {
                                                                    return tooltipItem.yLabel;
                                                                }
                                                            }
                                                        }

                                                    }
                                                    }
                                                />
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                            <div className="d-none d-md-flex">
                                <div>
                                    {/* <div className="mt-2 ml-2 latest-art-d">Statistics</div> */}
                                    <div className="mt-4 latest-stats">
                                        <center className="table-content">Become a Member !!</center>
                                    </div>
                                    <Row style={{ width: "100%" }}>
                                        <div className="col-md-3">
                                            <Card className="articles-Published-d">
                                                <center>
                                                    <div style={{ display: "flex", marginTop: "10px" }}> <img alt="" src={articlesPublished} style={{ width: "32px", height: "32px" }} />  <div className="ml-1 text-in-about-d">Published</div></div>
                                                    <div className="bold-text-d">{kFormatter(publishedPostsCount)}</div>

                                                </center>
                                            </Card>
                                        </div>
                                        <div className="col-md-3">
                                            <Card className="articles-Published-d">
                                                <center>
                                                    <div style={{ display: "flex", marginTop: "10px" }}> <img alt="" src={articlesRead} style={{ width: "32px", height: "32px" }} />  <div className="ml-1 text-in-about-d">Read</div></div>
                                                    <div className="bold-text-d">{kFormatter(totalArticleViews)}</div>
                                                </center>
                                            </Card>
                                        </div>
                                        <div className="col-md-3">
                                            <Card className="articles-Published-d">
                                                <center>
                                                    <div style={{ display: "flex", marginTop: "10px" }}> <img alt="" src={aboutPolls} style={{ width: "32px", height: "32px" }} />  <div className="ml-1 text-in-about-d">Polls</div></div>
                                                    <div className="bold-text-d">{kFormatter(pollsCount)}</div>
                                                </center>
                                            </Card>
                                        </div>
                                        <div className="col-md-3">
                                            <Card className="articles-Published-feddup">
                                                <Row style={{ width: "100%" }} className="no-gutters">
                                                    <Col md={4}>
                                                        <div className="feddup-icon-d">
                                                            <img src={feddupicon} />
                                                        </div>
                                                        <StarRating rating={averageRatingCount} />
                                                    </Col>
                                                    <Col md={8}>
                                                        <div className="ml-1 article-ratings-text">Love the Article? Share a quick review</div>
                                                        <a href={config.sceemlink + url} target="_blank" style={{ textDecoration: "none" }}>
                                                            <Button variant="default" className="mt-1 ml-1 btn-block visit-btn-d">Visit Feddup page</Button>
                                                        </a>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </div>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col>
                                            <Card className="mt-2 articles-graph-d">
                                                <div> <center style={{ marginTop: "-8px" }} className="top-articles-per-topic-d">Top Articles per Topic</center>
                                                    <Bar data={barData}
                                                        options={{
                                                            scales: {
                                                                scaleOverride: true,
                                                                x: {
                                                                    grid: {
                                                                        display: false,
                                                                    },
                                                                },
                                                                y: {
                                                                    grid: {
                                                                        display: false,
                                                                    },
                                                                    ticks: {
                                                                        display: false,
                                                                    },
                                                                },
                                                            },
                                                            label: {
                                                                display: true
                                                            },
                                                            plugins: {
                                                                legend: { display: false }
                                                            },
                                                            tooltips: {
                                                                callbacks: {
                                                                    label: function (tooltipItem) {
                                                                        return tooltipItem.yLabel;
                                                                    }
                                                                }
                                                            }

                                                        }
                                                        }
                                                    />
                                                </div>
                                            </Card>
                                        </Col>
                                        <Col>

                                            <Card className="mt-2 scanner-card-d">
                                                <Row style={{ width: "100%" }}>
                                                    <Col>
                                                        <img alt="" src={logo} className="sceem-logo-download-new-d" />
                                                        <div className="mt-4 scanner-text-d">Creating Knowledge Chain</div>
                                                        <div className="mt-4 scanner-text-sm-d">Unifying Knowledge, Elevate Supply Chain: Where Innovation Meets Insight.</div>
                                                        <div style={{ padding: "5px", fontFamily: "Open Sans" }}>
                                                            <div className="mt-2 link-text-d">Click on this link</div>
                                                        </div>
                                                        <div style={{ padding: "5px", fontFamily: "Open Sans" }}>
                                                            <a className="mt-2" href="https://mob.sceem.org/"><b style={{ color: "black", fontFamily: "Open Sans", fontSize: "15px" }}>https://mob.sceem.org</b></a>
                                                        </div><br />
                                                    </Col>
                                                    <Col>
                                                        <center className="mt-5 member-d">Scan QR Code</center>
                                                        <center className="mt-4"><img alt="" src={qrlink} style={{ width: "50%", border: "1px solid gray" }} /></center>
                                                        <center className="mt-2"><img alt="" src={nebutech} className="powered-by-nebutech-new-d" /></center>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div >
            {showSignup && (<Popup showSignup={showSignup} closePopup={closePopup} />)}

            {redirect && <AppLinkPopup redirect={redirect} closeRedirect={closeRedirect} />}
        </div >
    )
}


{/* <div className="row mt-3" style={{ width: "100%" }}>
    <div className="col-xs-12">
        <img alt="" src={authorImage === null ? "../author5.png" : authorImage} className="author-icon" />
        <span className="authorName-leftAlign">{authorName}
            <div className="topicName-rightAlign"> Topic: {topicName}</div>
        </span>
        <div className="authorName-d">I am {authorDescription}</div>
    </div>
</div> */}

const Progressbar = (props) => {
    const { percentage, option, uAns, selected, hValue, id, answer } = props;
    const backgroundColor = `linear-gradient(to right, #EEEBFF 0%, #EEEBFF ${percentage}%, white ${percentage}%, white 99%)`
    const containerStyles = {
        background: backgroundColor,
    }

    const isSelected = {
        border: "2px solid #DB6C00",
        borderRadius: 10,
        background: backgroundColor,
    }
    const isHighest = {
        border: "2px solid #31197C",
        borderRadius: 10,
        background: backgroundColor
    }
    const labelStyles = {
        color: 'black',
        borderColor: "#362B79",
        fontWeight: 500,
        fontFamily: "Raleway, sans-serif",
        fontSize: "14px",
        position: "relative",
        height: '6.5vh',
        marginTop: '1px',
        borderRadius: 8,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
    const optionLabels = {
        fontWeight: 500,
        margin: 'auto',
        height: '6.7vh',
        display: 'flex',
        fontFamily: "Raleway, sans-serif",
        justifyContent: 'space-between',
        alignItems: 'center',
    }
    const largestElement = (arr) => {
        return Math.max(...arr);
    }
    const percentStyle = {
        fontWeight: 600,
        fontFamily: "Raleway, sans-serif",
        marginRight: "2px"
    }

    return (
        <div style={id === answer ? isSelected : largestElement(hValue) === percentage ? isHighest : containerStyles} className="container_class">
            <div style={labelStyles}>
                <div style={optionLabels} className="ml-1">{option}</div>
            </div>

            <div style={percentStyle}>{`${percentage}%`}</div>
        </div>

    );
};
