import { React, Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Navbar, Nav, Container, NavDropdown, Form, FormControl } from "react-bootstrap";
import navlogo from '../images/logo-white.svg';
import './Header.css';
import { config } from "../Config/Config"

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = { clicked: false }
    }
    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }
    render() {
        return (
            <Navbar bg="#31197c" className="header-height" fixed='top'>
                <Navbar.Brand><a href="/"> <img className="header-logo" src={navlogo} /></a> </Navbar.Brand>
                <Nav className="ml-auto sceem-org">
               {/* <span style={{ color: "white",fontFamily:"Open Sans" }}>To know more visit<a href="https://www.sceem.org" style={{ color: "white" }}> <b style={{ fontSize: "20px" }}>www.sceem.org</b></a> </span> */}
                </Nav>
            </Navbar>

        )
    }
}

            // <Navbar style={{ boxShadow: "0px 1px 15px #999 " }} className="nav-height" fixed="top">
            //     <nav>
            //         <Link to="/">
            //             <img className="navbar-logo" src={navlogo} /></Link>

            //         <div className="menu-icon" onClick={this.handleClick}>
            //             <i className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
            //         </div>
            //         <ul className={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>
            //             <li>
            //                 <Form className="d-flex">
            //                     <FormControl
            //                         type="search"
            //                         placeholder="Search"
            //                         className="me-2"
            //                         aria-label="Search"
            //                     />
            //                 </Form>
            //                 <Link to="/articles" className='nav-links'>Read Articles</Link>
            //                 <Link to="/login">
            //                     <Button variant="default" className='nav-links-jellybtn btn-jelly'><b>Admin</b></Button>
            //                 </Link>
            //                 <Link to="/account"><img src={notifications} style={{ width: "30px" }} /></Link>
            //                 <Link to="/account-notifications"> <img src={settings} style={{ width: "3%", paddingLeft: "10px" }} /></Link>

            //             </li>
            //         </ul>
            //     </nav>
            // </Navbar>