import React, { Component } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import PricingData from "./PricingData.json";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { Link, Redirect } from 'react-router-dom';
import Header from "../Header/Header";
import "./Pricing.css"
import Footer from "../Footer/Footer";
import PricingHeader from "./PricingHeader";

export default class Pricing extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        return (
            <div>
                <PricingHeader />
                <div className="ml-p">
                    <span> <center><div style={{fontWeight:600, marginTop: "4.8rem",fontFamily:"Open Sans", color: "#FF942C", fontSize: "25px" }}>Membership</div></center></span>
                    <Row style={{ width: "100%", marginTop: "1.5rem"}}>

                        {PricingData.pricing.slice(0, 1).map((data, id) => {
                            return (
                                <Col md={3} xs={12} className="pricing-ml">
                                    <div className="scroll-pricing" >
                                        <center className="free">{data.planName}<br /></center>
                                        {data.planDescription.map((item, idx) =>
                                        (
                                            <div className="pricing-inner-text price"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1">{item}</span></div>
                                        ))}
                                        <center className="money-color"> {data.planAmount}</center>
                                    </div><br />
                                    {/* <div className="start mt-3"><Button variant="default" className="ml-3 get-btn">Get Started</Button></div> */}
                                </Col>
                            )
                        })}

                        {PricingData.pricing.slice(1, 2).map((data, id) => {
                            return (
                                <Col md={3} xs={12} className="pricing-ml">
                                    <div className="scroll-pricing" >
                                        <center className="free">{data.planName}<br /></center>
                                        {data.planDescription.map((item, idx) =>
                                        (
                                            <div className="pricing-inner-text price"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1">{item}</span></div>
                                        ))}

                                        <center className="money-color"><span className="rupee">&#8377;</span> {data.planAmount}</center>
                                    </div><br />

                                </Col>
                            )
                        })}
                        {PricingData.pricing.slice(2, 3).map((data, id) => {
                            return (
                                <Col md={3} xs={12} className="pricing-ml">
                                    <div className="scroll-pricing" >
                                        <center className="free">{data.planName}<br /></center>
                                        {data.planDescription.map((item, idx) =>
                                        (
                                            <div className="pricing-inner-text price"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1">{item}</span></div>
                                        ))}
                                        <center className="money-color"><span className="rupee">&#8377;</span> {data.planAmount}</center>
                                    </div><br />

                                </Col>
                            )
                        })}
                        {PricingData.pricing.slice(3, 4).map((data, id) => {
                            return (
                                <Col md={3} xs={12} className="pricing-ml">
                                    <div className="scroll-pricing" >
                                        <center className="free">{data.planName}<br /></center>
                                        {data.planDescription.map((item, idx) =>
                                        (
                                            <div className="pricing-inner-text"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1">{item}</span></div>
                                        ))}
                                        <center className="money-color"><span className="rupee">&#8377;</span> {data.planAmount}</center>
                                    </div><br />

                                </Col>
                            )
                        })}
                    </Row>

                </div >
                <Footer />

            </div >
        )
    }
}

{/* <div className="mt-2 price"><AiOutlineCheck className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">{data.planDescription[1]}</span></div>
                                        <div className="mt-2 price"><AiOutlineCheck className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">{data.planDescription[2]}</span></div>
                                        <div className="mt-2 price"><AiOutlineCheck className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">{data.planDescription[3]}</span></div>
                                        <div className="mt-2 price"><AiOutlineCheck className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">{data.planDescription[4]}</span></div>
                                        <div className="mt-2 price"><AiOutlineCheck className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">{data.planDescription[5]}</span></div>
                                        <div className="mt-2 price"><AiOutlineCheck className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">{data.planDescription[6]}</span></div>
                                        <div className="mt-2 price"><AiOutlineCheck className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">{data.planDescription[7]}</span></div> */}
