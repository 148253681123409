import React, { Component } from "react";
import { Button, Card, Row, Col } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import { enqueueSnackbar } from 'notistack';
import moment from "moment-timezone";
import Header from "../Header/Header";
import advusericon from '../images/adv-usericon.svg';
import advwebicon from '../images/adv-web.svg';
import advphoneicon from '../images/adv-phoneicon.svg';
import advemailicon from '../images/adv-email.svg';

export default class Advertisement extends Component {
    state = {
        campaignId: this.props.match.params.id,
        isLoading: true,
        showEmpty: false,
        campaignName: "",
        campaignUrl: "",
        campaignPaymentType: "",
        description: "",
        startDate: "",
        endDate: "",
        createdAt: "",
        imageUrl: "",
        type: "",
        advertiserEmail: "",
        advertiserName: "",
        advertiserNumber: "",
        designation: "",
        bannerImg:""
    }

    componentDidMount() {
        console.log("campaign-id", this.state.campaignId)
        this.getCampaignById();
    }
    getCampaignById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + `user/getCampaignByIdWeb/${this.props.match.params.id}`)
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    console.log("success!!");
                    if (response.data.campaign.length == 0) {
                        self.setState({
                            isLoading: false,
                            showEmpty: true
                        })
                    } else {
                        self.setState({
                            campaignName: response.data.campaign.campaignName,
                            campaignPaymentType: response.data.campaign.campaignPaymentType,
                            campaignUrl: response.data.campaign.campaignUrl,
                            advertiserName: response.data.campaign.advertiserName,
                            advertiserNumber: response.data.campaign.advertiserNumber,
                            advertiserEmail: response.data.campaign.advertiserEmail,
                            description: response.data.campaign.description,
                            designation: response.data.campaign.designation,
                            startDate: response.data.campaign.startDate,
                            endDate: response.data.campaign.endDate,
                            createdAt: response.data.campaign.createdAt,
                            imageUrl: response.data.campaign.imageUrl,
                            type: response.data.campaign.type,
                            bannerImg: response.data.campaign.bannerImg,
                            isLoading: false
                        })
                    }
                }
                else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    onClickUrl = () => {
        window.open(this.state.campaignUrl)
    }
    render() {

        const { isLoading, campaignName, showEmpty, campaignUrl, campaignPaymentType, description, imageUrl, startDate, createdAt, endDate } = this.state
        if (isLoading) {
            return <div>{console.log("loading")}</div>
        }

        if (showEmpty) {
            return (
                <div>
                    <div >
                        <Header /><br />
                        <div className="shared-adv">
                            <center className="expired-t"><b>Advertisement Expired </b></center>
                            <center className="mt-3 expired-t"><b>Thanks for your interest</b></center>
                        </div>
                        <br />
                    </div>
                </div>
            )
        }
        return (

            // <div >
            //     <Header />
            //     <div className="enable-center mx-4">
            //         <div>
            //             <center><img src={imageUrl === "uploads/1649942289609advertisement.png" ? config.userUrl + imageUrl : imageUrl} className="full-adv" style={{ marginTop: "5rem" }} /></center>
            //             <div className="campaign-name">{campaignName}</div>
            //             <p className="mt-2 adv-sm">{description}</p>
            //             <p className="adv-sm">Start Date: {startDate.split("T")[0].split("-").reverse().join("-")}</p>
            //             <p className="adv-sm">End Date: {endDate.split("T")[0].split("-").reverse().join("-")}</p>
            //             <div className="campaign-url" style={{ wordWrap: "break-word" }}>Url: <span onClick={this.onClickUrl}>{campaignUrl}</span></div>
            //         </div>
            //     </div>
            // </div >
            <div >
                <Header /><br />
                <Row style={{ width: "100%" }} className="no-gutters">
                    <Col xs={0} md={4}></Col>
                    <Col xs={12} md={4}>
                        <div className="detailed-adv-card">
                            <div style={{ marginTop: "1rem", border: "3px solid #ffffff", borderRadius: "40px", padding: "10px 4px 16px 4px" }}>
                                <Row style={{ width: "100%" }} className="no-gutters">
                                    <Col xs={7}>
                                        <div style={{ fontFamily: "Open Sans" }}>
                                            <div style={{ display: "flex", alignItems: "center", fontWeight: 600 }}>
                                                <img src={advusericon} style={{ width: "40px", marginRight: "8px" }} alt="Advertiser Icon" />
                                                <div style={{ display: "flex", flexDirection: "column", marginTop: "13px" }}>
                                                    <span>{this.state.advertiserName}</span>
                                                    <p style={{ fontSize: "13px", fontWeight: 500, margin: 0 }}>{this.state.designation}</p>
                                                </div>
                                            </div>
                                            <div style={{ marginTop: "1rem", display: "flex", alignItems: "center", fontSize: "12px" }}><img src={advphoneicon} style={{ width: "30px" }} />&nbsp;{this.state.advertiserNumber}</div>
                                            <div style={{ display: "flex", alignItems: "center", fontSize: "12px" }}><img src={advemailicon} style={{ width: "30px" }} /><span className="ml-1">{this.state.advertiserEmail}</span></div>
                                            <div style={{ display: "flex", alignItems: "center", fontSize: "12px" }}><img src={advwebicon} style={{ width: "30px" }} />&nbsp;<span onClick={this.onClickUrl}>{this.state.campaignUrl}</span></div>
                                        </div>
                                    </Col>
                                    <Col xs={5} className="d-flex justify-content-end">
                                        <div className="detailed-adv-img-border mr-1">
                                            <div className="adv-image-container">
                                                <img
                                                    src={this.state.imageUrl === "uploads/1649942289609advertisement.png" ? config.userUrl + this.state.imageUrl : this.state.imageUrl}
                                                    alt="Advertisement"
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{ borderTop: "3px solid #ffffff", borderRadius: "40px", marginTop: "-2px", padding: "10px 3px" }}>
                                {/* <div className="mt-2 detailed-event-text ml-2 mr-2">{this.state.campaignName}</div>
                                <div className="ml-2 mr-2" style={{ fontSize: "14px", fontFamily: "Open Sans" }}>{this.state.description}</div> */}
                                 <center className="mt-2"><img src={this.state.bannerImg ? this.state.bannerImg : this.state.bannerImg} style={{ width: "300px", height: "120px" }} /></center>

                                <div className="mt-2 detailed-event-text ml-2 mr-2">{this.state.campaignName}</div>
                                <div className="adv-text-details">
                                    <div className="ml-2 mr-2" style={{ fontSize: "14px", fontFamily: "Open Sans" }}>{this.state.description}</div>
                                </div>

                            </div>
                        </div>
                    </Col>
                    <Col xs={0} md={4}></Col>
                </Row>
            </div >

        )
    }
}

