import { React, Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Navbar, Nav, Container, NavDropdown, Form, FormControl } from "react-bootstrap";
import navlogo from '../images/logo-white.svg';
import '../Header/Header.css';
import { config } from "../Config/Config"

export default class PricingHeader extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Navbar bg="#31197c" className="header-height" fixed='top'>
                <Navbar.Brand><a href="/"> <img className="header-logo" src={navlogo} /></a> </Navbar.Brand>
                <Nav className="ml-auto">
                    <a href="/signup"><Button variant="default" className='p-signup-btn'>Sign Up</Button></a>
                </Nav>
            </Navbar>

        )
    }
}