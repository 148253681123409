import React from 'react';
import './Loading.css';

export default class Loading extends React.Component {
    render() {
        return (
            <div className='loading-bg'>
                <img alt="" src="logo-gif.gif" class="logo-gif" /> 
                <img alt="" src="nebutech.png" class="nebutech" />
            </div>
        )
    }
}