import React, { useEffect, useRef } from "react";
import './App.css';
import ArticleDescription from "./LandingPage/ArticleDescription";
import LandingPage from './LandingPage/LandingPage';
import { BrowserRouter as Router, Switch, Route, useParams } from 'react-router-dom';
import TermsAndConditions from "./Terms/Terms";
import PrivacyPolicy from "./Terms/PrivacyPolicy";
import FAQ from "./Terms/FAQ";
import CodeOfConduct from "./Terms/CodeOfConduct";
import Article from "./LandingPage/Article";
import SignUp from "./SignUp/SignUp";
import SignUpReferral from "./SignUp/SignUpReferral";
import RedirectToMobile from "./SignUp/VerifyOtp";
import Statistics from "./LandingPage/Statistics";
import Pricing from "./Pricing/Pricing";
import KnowMore from "./LandingPage/KnowMore";
import ScrollToTop from "./ScrollToTop";
import AnswerPoll from "./Polls/AnswerPoll";
import PollResults from "./Polls/PollResults";
import ProfileScreen from "./LandingPage/ProfileScreen";
import Register from "./SignUp/Register";
import ReferralSignUp from "./SignUp/ReferralSignUp";
import VerifyOtp from "./SignUp/VerifyOtp";
import RedirectLink from "./SignUp/RedirectLink";
import Advertisement from "./Advertisements/Advertisement";
import JobOpportunity from "./JobOpportunity/JobOpportunity";
import SmeProfile from "./LandingPage/SmeProfile";
import DetailedArticle from "./LandingPage/DetailedArticle";
import PlaceOrderForm from "./LandingPage/PlaceOrderForm";
// import { useOnLoadImages } from "./hooks/useOnLoadImages";

function App() {
  // const wrapperRef = useRef < HTMLDivElement > (null);
  // const imagesLoaded = useOnLoadImages(wrapperRef);

  useEffect(() => {
    const clearCookiesOnPageRefresh = () => {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      }
    };
    window.addEventListener('beforeunload', clearCookiesOnPageRefresh);
    return () => {
      window.removeEventListener('beforeunload', clearCookiesOnPageRefresh);
    };
  }, []);

  return (
    <div>
      <Router>
        <ScrollToTop />
        <Switch>
          {/* <Route exact path="/:title/:id" children={<ArticleDescription />} /> */}
          {/* <Route path="/:id" children={<Article />} /> */}
          <Route exact path="/" component={LandingPage} />
          <Route path="/terms" component={TermsAndConditions} />
          <Route path="/privacypolicy" component={PrivacyPolicy} />
          <Route path="/faq" component={FAQ} />
          <Route path="/poll/:date/:id" component={AnswerPoll} />
          <Route path="/adv/:date/:id" component={Advertisement} />
          <Route path="/jobopportunity/:date/:id" component={JobOpportunity} />
          <Route path="/profile/:id" component={ProfileScreen} />
          <Route path="/pollresults/:date/:id" component={PollResults} />
          <Route path="/knowmore" component={KnowMore} />
          <Route path="/pricing" component={Pricing} />
          <Route path="/statistics" component={Statistics} />
          <Route path="/codeofconduct" component={CodeOfConduct} />
          <Route exact path="/signup/:id" component={ReferralSignUp} />
          <Route path="/signup" component={Register} />
          <Route path="/verifyotp" component={VerifyOtp} />
          <Route path="/link" component={RedirectLink} />
          {/* <Route path="/sme/:id" component={SmeProfile} /> */}
          {/* <Route path="/:title/:id" render={(props) => <ArticleDescription key={props.match.params.id} {...props} />} /> */}
          
          <Route path="/placeorder" render={(props) => <PlaceOrderForm {...props} />} />
          <Route path="/:title/:id" render={(props) => <DetailedArticle key={props.match.params.id} {...props} />} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
