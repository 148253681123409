// import { React, Component } from 'react';
// import { Link } from 'react-router-dom';
// import { Button, Navbar, Nav, Container, NavDropdown, Form, FormControl } from "react-bootstrap";
// import navlogo from '../images/logo-white.svg';
// import './Header.css';
// import { config } from "../Config/Config"

// export default class Header extends Component {
//     constructor(props) {
//         super(props);
//         this.state = { clicked: false }
//     }
//     handleClick = () => {
//         this.setState({ clicked: !this.state.clicked })
//     }
//     render() {
//         return (
//             <Navbar bg="#31197c" className="header-height" fixed='top'>
//                 <Navbar.Brand><a href="/"> <img className="header-logo" src={navlogo} /></a> </Navbar.Brand>
//             </Navbar>

//         )
//     }
// }
import { React, Component } from 'react';
import { Button, Navbar } from "react-bootstrap";
import navlogo from '../images/logo-white.svg';
import './Header1.css';

export default class Header1 extends Component {
    constructor(props) {
        super(props);
        this.state = { clicked: false }
    }
    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }
    render() {
        return (
            <Navbar bg="#31197c" className="header-height" fixed='top'>
                <nav className='mt-3'>
                    <div className='explore-top'>
                        <div>     <a href="/"><img className="h-logo" src={navlogo} /></a></div>
                        <div className='know-fixed' style={{ color: "white" }}>Explore more</div>
                    </div>

                    <div className="menu_icon1" onClick={this.handleClick}>
                        <i className={this.state.clicked ? 'fas fa-times bar-color' : 'fas fa-bars bar-color'}></i>
                    </div>
                    <ul className={this.state.clicked ? 'nav-menu1 active' : 'nav-menu1'}>
                        <li>
                            {/* <a className='know-fixed' style={{color:"white"}}>Explore more</a> */}
                            <a href="#aboutapp" className='nav-links1'>About App</a>
                            <a href="#homescreen" className='nav-links1'>Home Screen</a>
                            <a href="#quickmenu" className='nav-links1'>Quick Menu</a>
                            <a href="#leftmenu" className='nav-links1'>Left Menu</a>
                            <a href="#smeconnect" className='nav-links1'>SME Connect</a>
                            <a href="#reviews" className='nav-links1'>Reviews</a>
                        </li>
                    </ul>
                </nav>
            </Navbar>
            // <Navbar bg="#31197c" className="header-height" fixed='top'>
            //     <Navbar.Brand><a href="/"> <img className="header-logo" src={navlogo} /></a> </Navbar.Brand>

            // </Navbar>
        )
    }
}
