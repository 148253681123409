import React, { useState, useEffect } from "react";
import './ArticleDescription.css';
import axios from 'axios';
import { Card, Button, Col, Row } from "react-bootstrap";
import background from '../images/background.png';
import googleplay from '../images/googleplay.svg';
import sceemlogo from '../images/logo-white.svg';
import logo from '../images/login-logo.png';
import qrlink from '../images/signupqr.svg';
import nebutech from '../images/nebutech-logo.svg';
import article from '../images/article-new.jpeg';
import { useParams, useHistory, Link } from 'react-router-dom';
import moment from "moment-timezone";
import polls from '../images/polls-new.svg';
import { Bar } from 'react-chartjs-2';
import sceemlore from '../images/sceemlore-logo.svg';
import articlesPublished from '../images/articles published.png';
import articlesRead from '../images/articles read.png';
import milestone from '../images/milestone feddup.svg';
import campaigns from '../images/campaigns.png';
import aboutPolls from '../images/about-polls.png';
import eminentAuthors from '../images/eminent authors.png';
import qna from '../images/QnA.svg';
import opportunities from '../images/opportunity.png';
import { config } from '../Config/Config';
import { Helmet } from "react-helmet";
import Header from "../Header/Header";
import TermsAndConditions from "../Terms/Terms";
import Register from "../SignUp/Register";
import Popup from "./Popup";
import flash from '../images/flash-on-svgrepo-com.svg';
import AppLinkPopup from "./AppLinkPopup";

export default function ArticleDescription() {
    const history = useHistory();
    const [articleData, setArticleData] = useState([]);
    const [topicName, setTopicName] = useState("");
    const [authorName, setAuthorName] = useState("");
    const [postId, setPostId] = useState()
    const [authorImage, setAuthorImage] = useState("");
    const [authorDescription, setAuthorDescription] = useState("");
    const [campaignsCount, setCampaignsCount] = useState("");
    const [likesCount, setLikesCount] = useState("");
    const [pollsCount, setPollsCount] = useState("");
    const [publishedPostsCount, setPublishedPostsCount] = useState("");
    const [registeredUserCount, setRegisteredUserCount] = useState("");
    const [totalArticleViews, setTotalArticleViews] = useState("");
    const [totalQuestions, setTotalQuestions] = useState("");
    const [postCount, setPostCount] = useState("");
    const [cardManagementCount, setCardManagementCount] = useState("");
    const [opportunitiesCount, setOpportunitiesCount] = useState("");
    const [topicDetails, setTopicDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [productId, setProductId] = useState("");
    const [averageRatingCount, setAverageRatingCount] = useState("");
    const [noOfRaters, setNoOfRaters] = useState("");
    const [url, setUrl] = useState("");
    const [views, setViews] = useState("");
    const [question, setQuestion] = useState("");
    const [options, setOptions] = useState([]);
    const [postOptions, setPostOptions] = useState([]);
    const [submitOption, setSubmitOption] = useState(false);
    const [disabled, setDisabled] = useState(false)
    const [userAnswer, setUserAnswer] = useState(null);
    const [showSignup, setShowSignup] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [latestPosts, setLatestPosts] = useState([]);
    const [newArticle, setNewArticle] = useState(false);
    const [newId, setNewId] = useState();
    const [newTitle, setNewTitle] = useState();
    const [campaign, setCampaign] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        getPostById();
        getLatestPosts();
        getAboutPageDetails();
        getProductDetails();
    }, []);
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSignup(!showSignup)
        }, 10000);

        return () => clearTimeout(timer);
    }, []);
    const closePopup = () => {
        setShowSignup(!showSignup)
        // setRedirect(!redirect)
        // setTimeout(() => {
        //     setRedirect(false);
        // }, 2000)
    }
    const closeRedirect = () => {
        setRedirect(!redirect)
    }
    const submitPostQuestion = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var payload = {
            "postId": parseInt(postId),
            "postQuestionOptionId": parseInt(userAnswer)
        }
        console.log("payload", payload)
        axios.post(config.userUrl + 'user/submitPostByUnregisteredUsers', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    setSubmitOption(true)
                    getPostQuestionResultByIdUnregisteredUser()
                    console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });

    };
    const getPostQuestionResultByIdUnregisteredUser = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        axios.get(config.userUrl + `user/getPostQuestionResultByIdUnregisteredUser/${id}`, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    console.log("success");
                    const result = response.data.postQuestionResult
                    if (result.length == 0) {
                        setSubmitOption(false)
                    } else
                        setQuestion(result.question)
                    setPostOptions(result.options)
                    setSubmitOption(true)
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });

    };
    const getPostById = () => {
        axios.get(config.userUrl + `user/getPostByIdWeb/${id}`)
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    console.log("success!!");
                    const articleList = response.data.post;
                    setArticleData(articleList)
                    setPostId(response.data.post[0]?.id)
                    const topic = response.data.topicDetails.topicName;
                    setTopicName(topic)
                    const authorName = response.data.authorDetails.name;
                    setAuthorName(authorName)
                    const postCount = response.data.postCount;
                    setPostCount(postCount)
                    const authorImage = response.data.authorDetails.profilePicture;
                    setAuthorImage(authorImage)
                    const authorDescription = response.data.authorDetails.description;
                    setAuthorDescription(authorDescription)
                    setTotalQuestions(response.data.authorDetails.totalQuestions)
                    setViews(response.data.userViews)
                    setQuestion(response.data.post[0]?.question)
                    setOptions(response.data.postQuestionData)
                    setCampaign(response.data?.campaign)

                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getProductDetails = () => {
        let data = document.getElementById('#feddup12345')?.innerHTML;
        if (data) {
            let dataId = data.split("\"");
            if (dataId[dataId.length - 1].length > 12) {
                dataId = dataId[dataId.length - 1];
                setProductId(dataId)
            }
            else if (dataId[dataId.length - 2].length > 12) {
                dataId = dataId[dataId.length - 2];
                setProductId(dataId)
            }
            else {
                setProductId(dataId)
            }
            var payload = {
                "productId": dataId
            }
        }
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        console.log("payload", payload)
        axios.post(config.feddupUserUrl + "user/getTopThreeRatingByProductId", payload, { headers: headers })
            .then(function (response) {
                console.log("getTopThreeRatingByProductId", response);
                if (response.data.status === true) {
                    setAverageRatingCount(response.data.averageRating[0].avgrating)
                    setNoOfRaters(response.data.numberOfRaters)
                    setUrl(response.data.url)
                    // avgRating: `${((Math.round(response.data.averageRating[0].avgrating * 10) / 10) / 5) * 100}%`,
                    // rating: "100" - `${((Math.round(response.data.averageRating[0].avgrating * 10) / 10) / 5) * 100}`,
                    // ratings: response.data.numberOfRating,
                    console.log("res", response.data)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    function parseMarkdown(markdownText) {
        var newMarkdownText = markdownText
            .replace(/^#(.*$)/gim, `<h5 class="heading-color"><b>$1</b></h5>`)
            .replace(/^>(.*$)/gim, '<li>$1</li>')
            .replace(/\_(.+?)\_/gim, '<u>$1</u>')
            .replace(/\%(.+?)\%/gim, '<i>$1</i>')
            .replace(/\*(.+?)\*/gim, '<b>$1</b>')
            .replace(/\n*$/gim, '<p />')
        console.log("text", newMarkdownText)
        return newMarkdownText
    }
    const b = articleData.map((i) => {
        console.log("info", (i.publishOn))
        var a = new Date(i.publishOn)
        var publish = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
        return publish;
    })
    const getAboutPageDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/aboutPageDetails', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    setCampaignsCount(response.data.campaignsCount);
                    setLikesCount(response.data.likesCount);
                    setPollsCount(response.data.pollsCount);
                    setPublishedPostsCount(response.data.publishedPostsCount);
                    setRegisteredUserCount(response.data.registeredUserCount);
                    setTopicDetails(response.data.topicDetails);
                    setTotalArticleViews(response.data.totalArticleViews);
                    setTotalQuestions(response.data.totalQuestions);
                    setOpportunitiesCount(response.data.totalOpportunitiesCount);
                    setCardManagementCount(response.data.cardManagementCount);
                    setIsLoading(false);
                }
            })
            .catch(function (error) {
                console.log("error", error);
            })
    };
    const getLatestPosts = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getLatestPosts', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    setLatestPosts(response.data.posts);
                }
            })
            .catch(function (error) {
                console.log("error", error);
            })
    };
    const redirectToLatestArticle = (id, title) => {
        setNewArticle(!newArticle)
        setNewId(id)
        setNewTitle(title)
    }
    useEffect(() => {
        if (newArticle) {
            history.push(`/${newTitle.replace(/ /g, "_")}/${newId}`);
        }
    }, [newArticle, history]);
    if (isLoading) {
        console.debug("renders: Loading...");
        return <h3 className="App"></h3>;
    }

    let filterTopics = [];
    for (let i = 0; i < topicDetails.length; i++) {
        if (topicDetails[i].postsCount >= 2) {
            filterTopics.push(topicDetails[i]);
        }
    }
    console.log("labels", filterTopics)
    function kFormatter(num) {
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K' : Math.sign(num) * Math.abs(num)
    }
    const data = {
        labels: [topicDetails[0].topicName.replace(/ *\([^]*\) */g, ""), topicDetails[1].topicName.replace(/ *\([^]*\) */g, ""), topicDetails[2].topicName.replace(/ *\([^]*\) */g, ""), topicDetails[3].topicName.replace(/ *\([^]*\) */g, "")],
        datasets: [
            {
                data: [topicDetails[0].postsCount, topicDetails[14].postsCount, topicDetails[2].postsCount, topicDetails[3].postsCount],
                fill: true,
                backgroundColor: ["#31197C"],
                borderColor: "rgba(75,192,192,1)",
                barThickness: 25
            }
        ]
    }
    var percent = postOptions?.map((opt, idx) => (
        Math.round((opt?.percentage + Number.EPSILON) * 100 / 100)
    ))
    const checkAnswer = answer => {
        setUserAnswer(answer)
        setDisabled(false)
    }
    return (
        <div>

            <div>
                <Header />
                <Row style={{ marginTop: "4.03rem", width: "100%" }}>
                    <Col md={1} ></Col>
                    <Col md={7} xs={12}>
                        {articleData.map((data, id) => {
                            return (
                                <div>
                                    <Helmet>
                                        <title>{data.title}</title>
                                        <meta name="description" content={authorName} />
                                    </Helmet>
                                    <Row>
                                        <Col md={6} xs={12}>
                                            <div style={!campaign ? { display: "none" } : {}}>
                                                <div className="adv-fix">
                                                    <Link to={`/adv/${(moment(campaign?.createdAt).format("YYYYMMDD"))}/${campaign?.id}`} className="link" key={`ad-${campaign?.id}`} style={{ fontFamily: "Noto Sans", zIndex: 2000 }}>
                                                        <div style={{ marginLeft: "10px", marginTop: "7px", marginRight: "12px", border: "1px solid #DDDDDD", borderRadius: "10px", height: "4.3rem", background: "#F1FDF8", position: "relative", boxShadow: "2px 2px 4px #bebebe" }}>
                                                            <Row style={{ width: "100%" }} className="no-gutters">
                                                                <Col xs={9} style={{ borderRight: "1px solid #DDDDDD", height: "4.2rem", marginTop: "-4px", position: "relative" }}>
                                                                    <div style={{ marginTop: "6px" }}>
                                                                        {campaign?.campaignName?.length > 75 ? (
                                                                            <div className="camp-name-home">{campaign?.campaignName.substring(0, 75)}...</div>
                                                                        ) : (
                                                                            <div className="camp-name-home">{campaign?.campaignName}</div>
                                                                        )}
                                                                        {/* {campaign?.hasViewed ? (
                                                                        <div style={{ position: "absolute", left: "4px", bottom: "-2px", color: 'green' }}><i class="fa fa-circle" aria-hidden="true"></i></div>
                                                                    ) : (
                                                                        <div style={{ position: "absolute", left: "4px", bottom: "-2px", color: 'red' }}><i class="fa fa-circle" aria-hidden="true"></i></div>

                                                                    )} */}
                                                                    </div>
                                                                    <div className="camp-col">
                                                                        <i className="fa fa-certificate fa-stack-2x"></i>
                                                                        <span className="fa fa-stack-1x" style={{ color: "#31197C" }}>
                                                                            <span style={{ marginLeft: "6px", fontWeight: "600", fontFamily: "Open Sans", fontSize: "19px" }}>
                                                                                {campaign?.campaignPaymentType === "Paid" ? "P" : campaign?.campaignPaymentType === "Free" ? "F" : "D"}
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <img
                                                                        src={campaign?.imageUrl === "uploads/1649942289609advertisement.png" ? config.userUrl + campaign?.imageUrl : campaign?.imageUrl}
                                                                        className="adv-image-home"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className={!campaign ? "no-adv-top" : "show-adv-top"}>  <center><img alt="" src={config.userUrl + data.image} className="Article_Image_width" /></center>
                                                {/* <p className="_heading">{data.title}</p> */}
                                            </div>
                                        </Col>
                                        <Col md={6} xs={12} style={{ marginTop: "1.5rem" }}>
                                            <div className="ml-4">
                                                <hr style={{ height: "1px", backgroundColor: "lightgrey" }} />
                                                <Row>
                                                    <Col md={3} ><img alt="" src={authorImage === null ? "../author5.png" : config.userUrl + authorImage} className="author-icon" /></Col>
                                                    <Col md={9} className="mr-ml" > <span><span style={{ color: "#FF942D", fontFamily: "Open Sans", fontSize: "18px", fontWeight: "700" }}>{authorName} </span><i style={{ fontFamily: "Open Sans" }}>  {authorDescription} </i></span><div style={{ fontFamily: "Open Sans", fontSize: "10px" }}>{postCount == 1 ? <span>{postCount} article published.</span> : <span>{postCount} articles published.</span>}</div></Col>
                                                </Row>

                                                <hr style={{ height: "1px", backgroundColor: "lightgrey" }} />

                                                <div className="mr-ml">
                                                    <div style={{ fontFamily: "Open Sans" }} > <span style={{ fontSize: "18px", fontWeight: "600" }}>TOPIC:</span> {topicName.replace(/ *\([^]*\) */g, "")}</div>
                                                    <div style={{ fontFamily: "Open Sans" }}> <span style={{ fontSize: "18px", fontWeight: "600" }}>PUBLISHED:</span> {b[id]}</div>
                                                    <div style={{ fontFamily: "Open Sans" }}> <span style={{ fontSize: "18px", fontWeight: "600" }}>VIEWS:</span> {views}</div>
                                                </div>
                                                <hr style={{ height: "7px", backgroundColor: "grey" }} />
                                            </div>
                                            <div className="bg-border-top">
                                                <div className="background-new" ><br />
                                                    <div className="app-border-top" >
                                                        <div style={{ display: "flex" }}> <img alt="" src={logo} className="sceem-logo-download-new" /> <div className="ml-2 article-chain-text">Creating knowledge chain</div></div>
                                                        <center className="become-member">Become a member !!</center>
                                                        <center> <img alt="" src={qrlink} style={{ width: "28%" }} /></center>
                                                        {/* <center className="box-text-lp">Visit </center> */}
                                                        <center className="box-text-lp"><a href="https://mob.sceem.org/">https://mob.sceem.org</a></center>
                                                        {/* <center className="box-text-lp">Or </center>
                                                    <center className="box-text-lp"><a href="https://www.sceem.org/signup">https://www.sceem.org/signup</a></center> */}
                                                        <img alt="" src={nebutech} className="powered-by-nebutech-new" />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <p className="_heading">{data.title}</p>
                                    <div className="mt-3 html-text-new" style={{ wordWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: data.content }} />
                                    {data.question == "" ? <div style={data.question ? {} : { display: "none" }}></div> :
                                        <Row style={data.question ? {} : { display: "none" }}>
                                            {submitOption ?
                                                <Col xs={12} md={12}>
                                                    <Card className="quiz-card-border">
                                                        <center className="art-poll-text-h"><span><img src={polls} style={{ width: "20px" }} /></span>&nbsp;<span>Article Poll</span></center>
                                                        <Card className="mt-2 mb-1 poll-result-card-post">
                                                            <center className="question_post">{question}</center>
                                                            <div>
                                                                {postOptions.map((opt, idx) => (
                                                                    <Progressbar uAns={opt.isAnswer} answer={userAnswer} id={opt.id} hValue={percent} option={opt.option} percentage={percent[idx]} />
                                                                ))}
                                                            </div>
                                                        </Card>
                                                    </Card>
                                                </Col>
                                                :
                                                <Col xs={12} md={12}>
                                                    <Card className="quiz-card-border">
                                                        <center className="art-poll-text-h"><span><img src={polls} style={{ width: "20px" }} /></span>&nbsp;<span>Article Poll</span></center>
                                                        <Card className="mt-2 poll-answer-card-post">
                                                            <center className="question_post">{question}</center>
                                                            {options.map((option, idx) => {
                                                                return (
                                                                    <div onClick={() => checkAnswer(option.id)} className={`polloptions-card-post ${userAnswer === option.id ? "selected" : null}`}><div className="poll-text">{option.option}</div></div>
                                                                )
                                                            })}
                                                        </Card>
                                                        {userAnswer === null ? <center><Button variant="default" disabled={true} className="mt-2 mb-1 q-submit-btn" >&nbsp;Submit &nbsp;</Button></center>
                                                            :
                                                            <center><Button variant="default" className="mt-2 mb-1 q-submit-btn" onClick={submitPostQuestion}>&nbsp;Submit &nbsp;</Button></center>}
                                                    </Card>
                                                </Col>
                                            }
                                        </Row>}
                                    <hr style={{ height: "1px", backgroundColor: "lightgrey" }} className="ml-4" />



                                </div>
                            )
                        })}
                        {latestPosts?.length !== 0 ?
                            <div >
                                <div className="d-flex align-items-center justify-content-center mt-3">
                                    <img src={flash} style={{ width: "30px" }} />
                                    <span className="read-also">Read also</span>
                                </div>
                                <div className="latest-art">Latest Articles</div>
                                {latestPosts.slice(0, 3)
                                    .filter(data => id != data.id) // Filter out items where id matches
                                    .map((data, i) => (
                                        <Card key={data.id} style={{ display: "flex" }} className="mt-2 latest-art-card align-items-center justify-center" onClick={() => redirectToLatestArticle(data.id, data.title)}>
                                            <Row style={{ width: "100%" }} className="no-gutters">
                                                <Col xs={2} sm={2} md={1} style={{ display: "flex", alignItems: "center" }}>
                                                    <img alt="" src={config.userUrl + data.authorProfilePicture} className="ml-2 favbits-author" />
                                                </Col>
                                                <Col xs={10} sm={10} md={11} style={{ display: "flex", alignItems: "center" }}>
                                                    {data.title !== null && data.title.length > 100 ?
                                                        <div className="ml-1 favbits-title">{data.title.substring(0, 100)}...</div> :
                                                        <div className="ml-1 favbits-title-latest">{data.title}</div>}
                                                </Col>
                                            </Row>
                                        </Card>
                                    ))}
                            </div> : <center></center>}
                    </Col>
                    <Col md={3} xs={12}>
                        <div className="ml-2 container" >
                            <h6 className="stats-head">Statistics</h6>
                            <div className="row">
                                <div className="col-xs-4">
                                    <Card className="articles-Published">
                                        <center>
                                            <img alt="" src={articlesPublished} style={{ width: "32px", height: "32px" }} />
                                            <div className="bold-text">{kFormatter(publishedPostsCount)}</div>
                                            <div className="text-in-about">Published</div>
                                        </center>
                                    </Card>
                                </div>
                                <div className="col-xs-4">
                                    <Card className="articles-Published">
                                        <center>
                                            <img alt="" src={articlesRead} style={{ width: "32px", height: "32px" }} />
                                            <div className="bold-text">{kFormatter(totalArticleViews)}</div>
                                            <div className="text-in-about">Read</div>
                                        </center>
                                    </Card>
                                </div>
                                <div className="col-xs-4">
                                    <Card className="articles-Published">
                                        <center>
                                            <img alt="" src={opportunities} style={{ width: "40px", height: "40px" }} />
                                            <div style={{ marginTop: "-10px" }}>
                                                <div className="bold-text">{kFormatter(opportunitiesCount)}</div>
                                                <div className="text-in-about">Opportunities</div>
                                            </div>
                                        </center>
                                    </Card>
                                </div>
                            </div>
                        </div>
                        <Card className="mt-2 articles-graph">
                            <div> <center style={{ marginTop: "-8px" }} className="text-in-about">Top Articles per Topic</center>
                                <Bar data={data}
                                    options={{
                                        scales: {
                                            scaleOverride: true,
                                            x: {
                                                grid: {
                                                    display: false,
                                                },
                                            },
                                            y: {
                                                grid: {
                                                    display: false,
                                                },
                                            },
                                        },
                                        label: {
                                            display: true
                                        },
                                        plugins: {
                                            legend: { display: false }
                                        },
                                        tooltips: {
                                            callbacks: {
                                                label: function (tooltipItem) {
                                                    return tooltipItem.yLabel;
                                                }
                                            }
                                        }

                                    }
                                    }
                                />
                            </div>
                        </Card>
                        <div className="mt-2 ml-2 container">
                            <div className="row" >
                                <div className="col-xs-4">
                                    <Card className="campaigns-polls">
                                        <div className="row no-gutters">
                                            <Col xs={5}>
                                                <center className="ml-2"> <img alt="" src={campaigns} style={{ width: "30px", height: "30px" }} /></center>
                                            </Col>
                                            <Col xs={7}>
                                                <center style={{ marginTop: "-1px" }}>
                                                    <div className="bold-text">{kFormatter(campaignsCount)}</div>
                                                </center>
                                            </Col>
                                            <div className="text-in-about">Advertisements</div>
                                        </div>
                                    </Card>
                                </div>
                                <div className="col-xs-4">
                                    <Card className="campaigns-polls">
                                        <div className="row no-gutters">
                                            <Col xs={4}>
                                                <center className="ml-2"> <img alt="" src={aboutPolls} style={{ width: "30px", height: "30px" }} /></center>
                                            </Col>
                                            <Col xs={8}>
                                                <center style={{ marginTop: "-1px" }}>
                                                    <div className="bold-text">{kFormatter(pollsCount)}</div>
                                                </center>
                                            </Col>
                                            <div className="text-in-about">Polls</div>
                                        </div>
                                    </Card>
                                </div>
                                <div className="col-xs-4">
                                    <Card className="campaigns-polls">
                                        <div className="row no-gutters">
                                            <Col xs={4}>
                                                <center className="ml-2"> <img alt="" src={qna} style={{ width: "30px", height: "30px" }} /></center>
                                            </Col>
                                            <Col xs={8}>
                                                <center style={{ marginTop: "-1px" }}>
                                                    <div className="bold-text">{kFormatter(totalQuestions)}</div>
                                                </center>
                                            </Col>
                                            <div className="text-in-about">Q&A</div>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </div>
                        <div className="mt-2 ml-2 container">
                            <div className="row" >
                                <div className="col-xs-8">
                                    <Card className="eminent-e">
                                        <div className="row">
                                            <Col xs={4}>
                                                <center className="ml-2"> <img alt="" src={eminentAuthors} style={{ width: "32px", height: "32px", marginTop: "-5px" }} /></center>
                                            </Col>
                                            <Col xs={8}>
                                                <center style={{ marginTop: "-5px" }}>
                                                    <div className="bold-text">{kFormatter(registeredUserCount)}</div>
                                                </center>
                                            </Col>
                                            <center className="text-in-about">Enterprises, Entrepreneurs, <br />Experts, Enthusiasts</center>
                                        </div>
                                    </Card>
                                </div>
                                <div className="col-xs-4">
                                    <Card className="campaigns-polls">
                                        <div className="row no-gutters">
                                            <Col xs={4}>
                                                <center className="ml-2"> <img alt="" src={sceemlore} style={{ width: "30px", height: "30px" }} /></center>
                                            </Col>
                                            <Col xs={8}>
                                                <center style={{ marginTop: "-1px" }}>
                                                    <div className="bold-text">{kFormatter(cardManagementCount)}</div>
                                                </center>
                                            </Col>
                                            <div className="text-in-about">Sceemlore</div>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </div>
                        <div className="mt-2 feddback_card-art">
                            <div className="feed-ratings-art">
                                <Row style={{ width: "100%" }} className="mt-4 no-gutters">
                                    <Col md={3} xs={3}>
                                        <div className="rating-v-align-art">
                                            <div className="avgRatingCount-art">{averageRatingCount}</div>
                                            <img src={milestone} className="milestone-img-art" /></div>

                                    </Col>
                                    <Col md={9} xs={9}>
                                        <div className="mt-2 ml-2 article-ratings-text">We Listen to your feedback</div>
                                        <div className="ml-2 article-ratings-text">Received insightful feedback from {noOfRaters} users</div>
                                        <a href={config.sceemlink + url} target="_blank" style={{ textDecoration: "none" }}>
                                            <Button variant="default" className="ml-2 btn-block visit-btn">Visit our Feddup page</Button>
                                        </a>
                                    </Col>
                                </Row>

                            </div>
                        </div>
                        {/* <Card className="mt-2 eminent-authors">
                        <div className="row">
                            <Col xs={4}>
                                <center className="ml-1 mt-2"> <img alt="" src={eminentAuthors} style={{ width: "42px", height: "42px" }} /></center>
                            </Col>
                            <Col xs={8}>
                                <center style={{ marginTop: "-10px", marginLeft: "-20px" }}>
                                    <div className="bold-text">{kFormatter(registeredUserCount)}</div>
                                    <div className="text-in-about">Enterprises, Entrepreneurs, Experts, Enthusiasts</div>
                                </center>
                            </Col>
                        </div>
                    </Card> */}
                        <br />
                        {/* <div className="bg-border-top">
                        <div className="background-new" ><br />
                            <div className="app-border-top" >
                                <img alt="" src={logo} className="sceem-logo-download-new" /><br />
                                <center className="article-chain-text">Creating knowledge chain</center>
                                <img alt="" src={nebutech} className="powered-by-nebutech-new" />
                            </div>
                        </div>
                    </div> */}
                        {/* <div className="bg-border-top">
                        <div className="background-new" ><br />
                            <div className="app-border-top" >
                                <img alt="" src={logo} className="sceem-logo-download-new" /><br />
                                <center className="article-chain-text">Creating knowledge chain</center>
                                <img alt="" src={nebutech} className="powered-by-nebutech-new" />
                            </div>
                        </div>
                    </div> */}
                    </Col >
                </Row >
            </div>
            {showSignup && (<Popup showSignup={showSignup} closePopup={closePopup} />)}

            {redirect && <AppLinkPopup redirect={redirect} closeRedirect={closeRedirect} />}
        </div >
    )
}


{/* <div className="row mt-3" style={{ width: "100%" }}>
    <div className="col-xs-12">
        <img alt="" src={authorImage === null ? "../author5.png" : authorImage} className="author-icon" />
        <span className="authorName-leftAlign">{authorName}
            <div className="topicName-rightAlign"> Topic: {topicName}</div>
        </span>
        <div className="authorName-d">I am {authorDescription}</div>
    </div>
</div> */}

const Progressbar = (props) => {
    const { percentage, option, uAns, selected, hValue, id, answer } = props;
    const backgroundColor = `linear-gradient(to right, #EEEBFF 0%, #EEEBFF ${percentage}%, white ${percentage}%, white 99%)`
    const containerStyles = {
        background: backgroundColor,
    }

    const isSelected = {
        border: "2px solid #DB6C00",
        borderRadius: 10,
        background: backgroundColor,
    }
    const isHighest = {
        border: "2px solid #31197C",
        borderRadius: 10,
        background: backgroundColor
    }
    const labelStyles = {
        color: 'black',
        borderColor: "#362B79",
        fontWeight: 500,
        fontFamily: "Open Sans",
        fontSize: "14px",
        position: "relative",
        height: '6.5vh',
        marginTop: '1px',
        borderRadius: 8,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
    const optionLabels = {
        fontWeight: 600,
        margin: 'auto',
        height: '6.7vh',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
    const largestElement = (arr) => {
        return Math.max(...arr);
    }
    const percentStyle = {
        fontWeight: 600,
        marginRight: "2px"
    }

    return (
        <div style={id === answer ? isSelected : largestElement(hValue) === percentage ? isHighest : containerStyles} className="container_class">
            <div style={labelStyles}>
                <div style={optionLabels} className="ml-1">{option}</div>
            </div>

            <div style={percentStyle}>{`${percentage}%`}</div>
        </div>

    );
};

{/* <div style={{ marginTop: "6rem" }}>
<Link to={`/advertisement/${campaign?.id}`} className="link" key={`ad-${campaign?.id}`} style={{ fontFamily: "Noto Sans", zIndex: 2000 }}>
    <div style={{ marginLeft: "10px", marginTop: "7px", marginRight: "12px", border: "1px solid #DDDDDD", borderRadius: "10px", height: "4.3rem", background: "#F1FDF8", position: "relative", boxShadow: "2px 2px 4px #bebebe" }}>
        <Row style={{ width: "100%" }} className="no-gutters">
            <Col xs={9} style={{ borderRight: "1px solid #DDDDDD", height: "4.2rem", marginTop: "-4px", position: "relative" }}>
                <div style={{ marginTop: "6px" }}>
                    {campaign?.campaignName?.length > 75 ? (
                        <div className="camp-name-home">{campaign?.campaignName.substring(0, 75)}...</div>
                    ) : (
                        <div className="camp-name-home">{campaign?.campaignName}</div>
                    )}
                    {campaign?.hasViewed ? (
                        <div style={{ position:"absolute",left:"4px",bottom:"-2px",color: 'green' }}><i class="fa fa-circle" aria-hidden="true"></i></div>
                    ) : (
                        <div style={{ position:"absolute",left:"4px",bottom:"-2px",color: 'red' }}><i class="fa fa-circle" aria-hidden="true"></i></div>
                       
                    )}
                </div>
                <div className="camp-col">
                    <i className="fa fa-certificate fa-stack-2x"></i>
                    <span className="fa fa-stack-1x" style={{ color: "#31197C" }}>
                        <span style={{ marginLeft: "6px", fontWeight: "600", fontFamily: "Open Sans", fontSize: "19px" }}>
                            {campaign?.campaignPaymentType === "Paid" ? "P" : campaign?.campaignPaymentType === "Free" ? "F" : "D"}
                        </span>
                    </span>
                </div>
            </Col>
            <Col xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img
                    src={campaign?.imageUrl === "uploads/1649942289609advertisement.png" ? config.userUrl + campaign?.imageUrl : campaign?.imageUrl}
                    className="adv-image-home"
                />
            </Col>
        </Row>
    </div>
</Link>
</div> */}

