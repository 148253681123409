import React, { Component } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import axios from 'axios';
import { config } from '../Config/Config';
import { Button, Form } from "react-bootstrap";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";


export default class VerifyOtp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: this.props.location.state.email,
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            otp5: "",
            otp6: "",
            sort: false,
            try: false,
            registerFlow: true,
            newPassword: false,
            redirect: false,
            authKey: ""
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    inputfocus = (elmnt) => {
        if (elmnt.key === "Backspace" || elmnt.key === "Delete") {
            const next = elmnt.target.tabIndex - 2;
            console.log("ext", elmnt.target.tabIndex, next)
            if (next > -1) {
                elmnt.target.form.elements[next].focus()
            }
        }
        else {
            const next = elmnt.target.tabIndex;
            console.log("next", elmnt.target.tabIndex, next);
            if (next < 6) {
                elmnt.target.form.elements[next].focus()
            }
        }

    }
    verifyOtp = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        var self = this;
        var payload = {
            "email": this.state.email,
            "otp": this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4 + this.state.otp5 + this.state.otp6
        }
        axios.post(config.apiUrl + 'auth/verifyEmail', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1);
                if (response.data.status === true) {
                    console.log("Email verified successfully!!")
                    console.log("payload", payload)
                    self.setState({
                        redirect: true
                    })
                } else {
                    console.log(response.data.message)
                    document.getElementById('status').innerText = msg
                }
            })
            .catch(function (error) {
                console.log("payload", payload)
                console.log(error);
                document.getElementById('status').innerText = error
            });
    }
    resendOtp = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        var payload = {
            "email": this.state.email,
            "registerFlow": this.state.registerFlow
        }
        axios.post(config.apiUrl + 'auth/sendOtp', payload, { headers: headers })
            .then(function (response) {
                const msg = "Otp sent successfully!!";
                if (response.data.status === true) {
                    console.log("Otp sent successfully!!")
                    console.log("payload", payload)
                    document.getElementById('otp').innerText = msg
                } else {
                    console.log(response.data.message)
                    console.log("err", payload)
                }
            })
            .catch(function (error) {
                console.log("payload", payload)
                console.log(error);
            });
    }
    handleSubmit(event) {
        const data = new FormData(event.target);
        console.log(this.state);
        event.preventDefault()
        this.verifyOtp();
    }
    // handleChange(value1, event) {
    //     this.setState({ [value1]: event.target.value });
    // }
    handleChange(value1, event) {
        // Regular expression to allow only numbers
        const regex = /^[0-9\b]+$/;
        // Check if the entered value matches the regular expression
        if (event.target.value === '' || regex.test(event.target.value)) {
            // If it matches, update the state with the entered value
            this.setState({ [value1]: event.target.value });
        }
    }
    render() {
        const { redirect } = this.state
        if (redirect) {
            return <Redirect
                to={{
                    pathname: "/link"
                }} />
        }
        return (
            <div>
                <Header />
                <center className="center-align">
                    <h3><b>Thanks for registering !</b></h3>
                    <h4 className="mt-3">You will receive a OTP to verify on your mail</h4>
                    <h4 className="mt-3">Please enter here and complete the process</h4>
                    <Form className="mt-5 forgot-email" onSubmit={this.handleSubmit}>
                        <center>
                            <div style={{ width: "80%" }} className="form-group verify">

                                <input
                                    name="otp1"
                                    type="number"
                                    inputMode="numeric"
                                    autoComplete="off"
                                    className="form-control digit-width"
                                    value={this.state.otp1}
                                    onKeyPress={this.keyPressed}
                                    onChange={e => this.handleChange("otp1", e)}
                                    tabIndex="1" maxLength={1} onKeyUp={e => this.inputfocus(e)}

                                />
                                <input
                                    name="otp2"
                                    type="number"
                                    inputMode="numeric"
                                    autoComplete="off"
                                    className="form-control digit-width"
                                    value={this.state.otp2}
                                    onChange={e => this.handleChange("otp2", e)}
                                    tabIndex="2" maxLength={1} onKeyUp={e => this.inputfocus(e)}

                                />
                                <input
                                    name="otp3"
                                    type="number"
                                    inputMode="numeric"
                                    autoComplete="off"
                                    className="form-control digit-width"
                                    value={this.state.otp3}
                                    onChange={e => this.handleChange("otp3", e)}
                                    tabIndex="3" maxLength="1" onKeyUp={e => this.inputfocus(e)}

                                />
                                <input
                                    name="otp4"
                                    type="number"
                                    inputMode="numeric"
                                    autoComplete="off"
                                    className="form-control digit-width"
                                    value={this.state.otp4}
                                    onChange={e => this.handleChange("otp4", e)}
                                    tabIndex="4" maxLength={1} onKeyUp={e => this.inputfocus(e)}
                                />

                                <input
                                    name="otp5"
                                    type="number"
                                    inputMode="numeric"
                                    autoComplete="off"
                                    className="form-control digit-width"
                                    value={this.state.otp5}
                                    onChange={e => this.handleChange("otp5", e)}
                                    tabIndex="5" maxLength={1} onKeyUp={e => this.inputfocus(e)}
                                />
                                <input
                                    name="otp6"
                                    type="number"
                                    inputMode="numeric"
                                    autoComplete="off"
                                    className="form-control digit-width"
                                    value={this.state.otp6}
                                    onChange={e => this.handleChange("otp6", e)}
                                    tabIndex="6" maxLength={1} onKeyUp={e => this.inputfocus(e)}
                                />
                            </div><div id="status" className="errormsg"></div>
                            <br /><br />
                            <Button variant="default" type="submit" className="btn-block login-btn">Verify</Button><br />
                            <p >Did not get the OTP?<span className="try cursor-pointer" onClick={this.resendOtp}> Resend OTP</span></p>
                            {/* <div id="otp" className="errormsg"></div>{this.state.registerFlow ?
                            null : <p >Facing issue with OTP?<span className="try" onClick={this.detectDevice}> Try another ways</span></p>} */}
                            <div id="tryanother" className="errormsg"></div>
                        </center>
                    </Form>
                    {/* <h4>Please use the below link and add it to home screen</h4>
                    <br /><a href="https://mob.sceem.org/"><b>https://mob.sceem.org/</b></a> */}
                </center>
            </div >
        )
    }
}
// import React, { Component } from "react";
// import Footer from "../Footer/Footer";
// import Header from "../Header/Header";
// import axios from 'axios';
// import { config } from '../Config/Config';
// import { Button, Form } from "react-bootstrap";
// import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

// export default class VerifyOtp extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             email: this.props.location.state.email,
//             otp1: "",
//             otp2: "",
//             otp3: "",
//             otp4: "",
//             otp5: "",
//             otp6: "",
//             redirect: false
//         }
//         this.handleChange = this.handleChange.bind(this);
//         this.handleSubmit = this.handleSubmit.bind(this);
//     }

//     handleChange(event) {
//         const { name, value } = event.target;
//         this.setState({ [name]: value });
//     }

//     handleSubmit(event) {
//         event.preventDefault();
//         this.verifyOtp();
//     }

//     verifyOtp = () => {
//         const { email, otp1, otp2, otp3, otp4, otp5, otp6 } = this.state;
//         const otp = otp1 + otp2 + otp3 + otp4 + otp5 + otp6;

//         axios.post(config.apiUrl + 'auth/verifyEmail', { email, otp })
//             .then(response => {
//                 const { status, message } = response.data;
//                 if (status === true) {
//                     console.log("Email verified successfully!!");
//                     this.setState({ redirect: true });
//                 } else {
//                     console.log(message);
//                     document.getElementById('status').innerText = message.charAt(0).toUpperCase() + message.slice(1);
//                 }
//             })
//             .catch(error => {
//                 console.log(error);
//                 document.getElementById('status').innerText = error;
//             });
//     }

//     render() {
//         const { redirect } = this.state;
//         if (redirect) {
//             return <Redirect to={{ pathname: "/link" }} />;
//         }

//         return (
//             <div>
//                 <Header />
//                 <center className="center-align">
//                     <h3><b>Thanks for registering!</b></h3>
//                     <h4 className="mt-3">You will receive an OTP to verify on your mail</h4>
//                     <h4 className="mt-3">Please enter here and complete the process</h4>
//                     <Form className="mt-5 forgot-email" onSubmit={this.handleSubmit}>
//                         <center>
//                             <div style={{ width: "80%" }} className="form-group verify">
//                                 {[1, 2, 3, 4, 5, 6].map(index => (
//                                     <input
//                                         key={index}
//                                         name={`otp${index}`}
//                                         type="number"
//                                         autoComplete="off"
//                                         className="form-control digit-width"
//                                         value={this.state[`otp${index}`]}
//                                         onChange={this.handleChange}
//                                         maxLength={1}
//                                     />
//                                 ))}
//                             </div>
//                             <div id="status" className="errormsg"></div>
//                             <br /><br />
//                             <Button variant="default" type="submit" className="btn-block login-btn">Verify</Button><br />
//                             <p >Did not get the OTP?<span className="try cursor-pointer" onClick={this.resendOtp}> Resend OTP</span></p>
//                             <div id="tryanother" className="errormsg"></div>
//                         </center>
//                     </Form>
//                 </center>
//             </div>
//         )
//     }
// }
