import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Card, Button } from "react-bootstrap";
import mobileAppImg from '../images/mobileAppImg.png';
import webApp from '../images/webApp.png';
import articlePage from '../images/articlePage.png';
import referralCode from '../images/referralCode.png';
import verify from '../images/verifyotp.png';
import profile from '../images/addprofile.png';
import info from '../images/info.svg';
import tick from '../images/tick-popup.svg';
import smiley from '../images/smiley-popup.svg';
import './LandingPagePopup.css';
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function LandingPagePopup({ showSignUp, closePopup }) {
    const [activeCircle, setActiveCircle] = useState(0);
    const circleLabels = ['SignUp', 'Verify', 'Profile'];
    const initialCircleText = [
        'On web app (www.sceem.org/signup) \n On mobile app (mob.sceem.org/signup)\n Popup while reading a shared article \n Referral link sent to your email from a friend',
        'Enter OTP sent to your signed-up email',
        'Configure your profile details'
    ];
    const [circleText, setCircleText] = useState(initialCircleText);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [totalCircles, setTotalCircles] = useState(3)
    const images = {
        0: [webApp, mobileAppImg, articlePage, referralCode],
        1: [verify],
        2: [profile]
    };

    // useEffect(() => {
    //     if (activeCircle === 0) {
    //         const imageInterval = setInterval(() => {
    //             setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images[0].length);
    //         }, 2500);

    //         return () => clearInterval(imageInterval);
    //     }
    // }, [activeCircle]);
    useEffect(() => {
        if (activeCircle === 0) {
            setCurrentImageIndex(0);
            const imageInterval = setInterval(() => {
                setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images[0].length);
            }, 2500);

            return () => clearInterval(imageInterval);
        }
    }, [activeCircle]);

    useEffect(() => {
        let interval = setInterval(() => {
            let newActiveCircle = (activeCircle + 1) % totalCircles
            setActiveCircle(newActiveCircle)
        }, 10000)
        return () => clearInterval(interval)
    }, [activeCircle])

    const handleCircleClick = (index) => {
        setActiveCircle(index);
    };
    const handleLineClick = (index) => {
        setCurrentImageIndex(index);
    };
    return (

        <Modal show={showSignUp} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="mod-height">
                <div style={{ margin: "1rem 0.5rem", display: "flex", justifyContent: "space-between" }}>
                    <div className="signup-text">Registration Process</div>
                    <div> <i onClick={closePopup} className="mt-1 cursor-pointer fa fa-times fa-lg" aria-hidden="true"></i></div>
                </div>

                <div className="circle-text" >
                    <div className="circle-info active">
                        <div className="info-row">
                            <div className="text-container">
                                <div className="circle-container">
                                    <div className="horizontal-line"></div>
                                    {circleLabels.map((label, index) => (
                                        <div key={index} className="circle-wrapper">
                                            <div
                                                className={`circle-p ${activeCircle === index ? 'active' : ''}`}
                                                onClick={() => handleCircleClick(index)}
                                            >
                                                {index + 1}
                                            </div>
                                            <div className={`circle-label ${activeCircle === index ? 'active' : ''}`}>
                                                {label}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {circleText[activeCircle].split('\n').map((line, index) => (
                                    <Card key={index} className="line-lp loading-animation">
                                        <div style={{ display: "flex" }}>
                                            <img src={activeCircle === 0 ? info : activeCircle === 1 ? tick : smiley} className={activeCircle === 0 ? "activeCircle1-img" : "activeCircle-img"} />
                                            <div className={`line-padding ${activeCircle === 0 && currentImageIndex === index ? 'bold-text' : ''}`}
                                                onClick={() => handleLineClick(index)}>{line}</div>
                                        </div>
                                    </Card>
                                ))}
                            </div>
                            <div className="image-container">
                                <center>
                                    {activeCircle === 0 && (
                                        <div className="popup-img-border loading-animation">
                                            <img
                                                src={images[0][currentImageIndex]}
                                                alt="Rotating display"
                                                style={{ maxWidth: '200px' }}
                                                className="loading-animation"
                                            />
                                        </div>
                                    )}
                                    {activeCircle === 1 && (
                                        <div className="popup-img-border loading-animation">
                                            <img
                                                src={verify}
                                                alt="Verify"
                                                style={{ maxWidth: '200px' }}
                                            />
                                        </div>
                                    )}
                                    {activeCircle === 2 && (
                                        <div className="popup-img-border loading-animation">
                                            <img
                                                src={profile}
                                                alt="profile"
                                                style={{ maxWidth: '200px' }}
                                            />
                                        </div>
                                    )}
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
                <center>
                    <Link to="/signup"><Button variant="default" style={{ textDecoration: "none" }} className="mt-4 mb-4 btn-block visit-btn">Register&nbsp;NOW!</Button></Link>
                </center>
            </Modal.Body>
        </Modal>
    );
}