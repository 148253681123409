import React, { Component } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";


export default class CodeOfConduct extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div>
                <Header />
                <p className="terms-heading">Code of Conduct</p>
                <div className="ml-4 mr-2">
                    <b className="heading terms-color">Author</b>
                    <p className="terms_Text">As an author, contribute to sceem community through conceptualisation, analysis, interpretation of data, in the topic of interest in the form of article. Drafting of the article is based on individual expertise and intellectual content. <br /><div className="mt-2"> Agreement to be accountable for all aspects of the work in the article in ensuring accuracy or integrity of any part of the work are appropriately addressed. Content of article has no commercial or promotional objective.</div></p>

                    <b className="heading terms-color mt-2">Reviewer </b>
                    <p className="terms_Text">As a reviewer, contribute to sceem community through reviewing article concept, analysis, interpretation of data based on individual subject matter expertise. Provide constructive and objective feedback while approving or rejecting the article. <br /><div className="mt-2"> Agreement to be accountable for all aspects of the work in the review of article and seek clarifications if necessary related to the accuracy or integrity of any part of the work.</div></p>
                    <b className="heading terms-color" >SME</b>
                    <p className="terms_Text">As a SME, contribute to sceem community through answering the questions raised by users. Provide constructive answers while answering the questions. <br /><div className="mt-2">  Agreement to be accountable for all aspects of the work in answering questions.</div></p>
                    <b className="heading terms-color" >User</b>
                    <p className="terms_Text">As a user, contribute to sceem community by promoting articles, authors, opportunities, raise relevant questions and answer polls.<div className="mt-2">  Agreement to be accountable for all aspects of the work in providing inputs to community.</div></p>
                </div>
                <br />
                <Footer />
            </div >
        )
    }
}
