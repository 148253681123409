import React from 'react';

const StarRating = ({ rating }) => {
  const filledStars = Math.floor(rating); 
  const halfStar = rating % 1 >= 0.25; 
  const isQuarterFilled = rating % 1 >= 0.1 && rating % 1 < 0.25; 

  return (
    <div className="star-rating">
      {[...Array(5)].map((_, index) => {
        if (index < filledStars) {
          return <span key={index} className="star filled">&#9733;</span>;
        } else if (index === filledStars && halfStar) {
          return <span key={index} className="star half-filled">&#9733;</span>;
        } else if (index === filledStars && isQuarterFilled) {
          return <span key={index} className="star quarter-filled">&#9733;</span>;
        } else {
          return <span key={index} className="star">&#9734;</span>; 
        }
      })}
    </div>
  );
};

export default StarRating;