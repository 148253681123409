import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, InputGroup, FormControl } from 'react-bootstrap';
import axios from 'axios';
import { Link, Redirect } from 'react-router-dom';
import { config } from '../Config/Config';
import Header from '../Header/Header';


const PlaceOrderForm = () => {

    const [size, setSize] = useState('M');
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(599);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [color, setColor] = useState('White');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [redirect, setRedirect] = useState(false);

    const handleSizeChange = (event) => {
        setSize(event.target.value);
    };
    const handleColorChange = (event) => {
        setColor(event.target.value);
    };
    const handleQuantityChange = (event) => {
        const qty = parseInt(event.target.value);
        setQuantity(qty);
        setPrice(qty * 599);
    };

    const handleAddressChange = (event) => {
        setAddress(event.target.value);
    };

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };
    const handleSubmit = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var payload = {
            "size": size,
            "quantity": quantity,
            "color": color,
            "price": price,
            "address": address,
            "phoneNumber": phoneNumber,
            "name": name,
            "email": email
        }
        console.log("payload", payload)
        axios.post(config.userUrl + 'user/placeTshirtOrderByWeb', payload)
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    enqueueSnackbar('Order Placed Successfully', { variant: "success" })
                    setRedirect(true);
                }
            })
            .catch(function (error) {
                console.log("error", error);
            })


    }
    if (redirect) {
        return <Redirect
            to={{
                pathname: `/`
            }}
        />
    }
    return (
        <div >
            <Header />
            <div style={{ marginTop: "4rem" }}><br />
                <Row style={{ width: "100%" }}>
                    <Col md={4} xs={12}></Col>
                    <Col md={4} xs={12}>
                        <div className="your-jitbits">Order Tshirt</div>
                        <div style={{ fontFamily: "Open Sans" }}>
                            <Form className="ml-4">
                                <Row style={{ width: "100%" }} className='no-gutters'>
                                    <Col>
                                        <Form.Group controlId="name">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter your name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="email">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter your email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row style={{ width: "100%" }} className='no-gutters'>
                                    <Col>
                                        <Form.Group controlId="sizeSelect">
                                            <Form.Label>Size</Form.Label>
                                            <Form.Control as="select" value={size} onChange={handleSizeChange}>
                                                <option>S</option>
                                                <option>M</option>
                                                <option>L</option>
                                                <option>XL</option>
                                                <option>XXL</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="colorSelect">
                                            <Form.Label>Color</Form.Label>
                                            <Form.Control as="select" value={color} onChange={handleColorChange}>
                                                <option>White</option>
                                                <option>Navy Blue</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row style={{ width: "100%" }} className='no-gutters'>
                                    <Col>
                                        <Form.Group controlId="quantitySelect">
                                            <Form.Label>Quantity</Form.Label>
                                            <Form.Control as="select" value={quantity} onChange={handleQuantityChange}>
                                                {[1, 2, 3, 4, 5].map((qty) => (
                                                    <option key={qty} value={qty}>
                                                        {qty}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="price">
                                            <Form.Label>Price</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text>Rs.</InputGroup.Text>
                                                <FormControl readOnly value={price} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Group controlId="phoneNumber">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your phone number"
                                        value={phoneNumber}
                                        onChange={handlePhoneNumberChange}
                                    />
                                </Form.Group>
                                <div className='form-group'>
                                    <Form.Label>Address</Form.Label>
                                    <textarea
                                        className='form-control'
                                        rows={4}
                                        style={{ resize: "none", width: "100%", padding: "10px", borderRadius: "4px" }}
                                        placeholder="Enter your address"
                                        value={address}
                                        onChange={handleAddressChange}
                                    />
                                </div>

                                <Button variant="default" className="mt-4 btn btn-block" onClick={handleSubmit} style={{ background: "#31197C", color: "white", fontFamily: "Open Sans" }}>Buy Now</Button>
                            </Form>
                        </div>
                    </Col>
                    <Col md={4} xs={12}></Col>
                </Row>
            </div>
        </div>
    );
};

export default PlaceOrderForm;