import React, { Component } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import DatePicker from "react-datepicker";
import { config } from '../Config/Config';
import { enqueueSnackbar } from 'notistack';
import moment from "moment-timezone";
import Header from "../Header/Header";

export default class JobOpportunity extends Component {
    state = {
        isLoading: true,
        showEmpty: false,
        opportunity: []
    }

    componentDidMount() {
        console.log("job",)
        this.getOpportunityById();
    }
    getOpportunityById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        var self = this;
        axios.get(config.userUrl + `user/getOpportunityByIdWeb/${this.props.match.params.id}`)
            .then(function (response) {
                if (response.data.status === true) {
                    if (response.data.getOpportunityById.length == 0) {
                        self.setState({
                            isLoading: false,
                            showEmpty: true
                        })
                    } else {
                        self.setState({
                            opportunity: response.data.getOpportunityById,
                            isLoading: false
                        })
                        console.log("res", response.data)
                    }
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    render() {

        const { isLoading, opportunity, showEmpty } = this.state
        const updatedDate = opportunity.map((i, idx) => {
            var a = new Date(i.applyByDate)
            var start = parseInt(a.getMonth() + 1) + "-" + a.getDate() + "-" + a.getFullYear();
            return start;
        })

        const selectedStartDate = new Date(updatedDate)
        if (isLoading) {
            return <div>{console.log("loading")}</div>
        }

        if (showEmpty) {
            return (
                <div>
                    <div >
                        <Header /><br />
                        <div className="shared-adv">
                            <center className="expired-t"><b>Job Opportunity Expired</b></center>
                            <center className="mt-3 expired-t"><b>Thanks for your interest</b></center>
                        </div>
                        <br />
                    </div>
                </div>
            )
        }
        return (

            <div >
                <Header />
                <div className="enable-center mx-2" style={{ fontFamily: "Open Sans" }}>
                    <div style={{ marginTop: "5.4rem", marginLeft: "25px" }}>
                        <div className="opp-heading"><b style={{ color: "#ff942C", fontSize: "18px" }}>Detailed Opportunity Details</b></div></div>

                    <div className="mt-3">
                        {opportunity.map((data, i) => (
                            <div className="job-card_" >
                                {/* <Card className="createopportunity-card"> <div style={{ marginLeft: "3%", marginRight: "3%" }} > <div className="form-group mt-1">
                                    <label className="view-opp-label">Organization Name</label>
                                    <input type="text"
                                        className="form-control form-bg-opp"
                                        aria-describedby="text"
                                        value={data.company}
                                        readOnly
                                        name="company"
                                        placeholder="Organization Name"
                                    />
                                </div>

                                    <div className="form-group mt-3">
                                        <label className="view-opp-label">Organization Url</label>
                                        <input type="text"
                                            className="form-control form-bg-opp"
                                            aria-describedby="text"
                                            value={data.url}
                                            readOnly
                                            name="url"
                                            placeholder="Organization Url"
                                        />
                                    </div>
                                </div>
                                </Card>
                                <Card className="createopportunity-card"> <div style={{ marginLeft: "3%", marginRight: "3%" }} >
                                    <div className="form-group mt-2">
                                        <div style={{ fontSize: "12px" }}>{data.type === 1 ? <span>Part-Time {data.typeOfOpportunity} for {data.tenure} </span> : <span>Full-Time {data.typeOfOpportunity} for {data.tenure} </span>}</div>

                                        <Row style={{ width: "99.8%" }} className="mt-2 no-gutters">
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label className="view-opp-label">Vacancies</label>
                                                    <input type="number"
                                                        className="form-control form-bg-opp"
                                                        aria-describedby="text"
                                                        name="vacancies"
                                                        readOnly
                                                        placeholder={`${data.vacancies} Vacancies`}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label className="ml-1 view-opp-label">Location</label>
                                                    <input type="text"
                                                        className="ml-1 form-control form-bg-opp"
                                                        aria-describedby="text"
                                                        value={data.location}
                                                        name="location"
                                                        readOnly
                                                        placeholder="Location"
                                                    />

                                                </div>
                                            </Col>
                                        </Row>
                                        <Row style={{ width: "99.8%" }} className="mt-2 no-gutters">
                                            <Col xs={6}>
                                                <label className="view-opp-label">Remuneration</label>
                                                <input type="text"
                                                    className="form-control form-bg-opp"
                                                    aria-describedby="text"
                                                    name="remuneration"
                                                    readOnly
                                                    placeholder={`${data.remuneration} (INR/month)`}
                                                />
                                            </Col>
                                            <Col xs={6}>
                                                <label className="ml-1 view-opp-label">Apply by Date</label>
                                                <DatePicker
                                                    className="ml-1 form-control form-bg-opp-date"
                                                    dateFormat="dd-MM-yyyy"
                                                    readOnly
                                                    selected={selectedStartDate}
                                                    minDate={new Date()}
                                                    placeholderText="Last date to apply"
                                                />
                                            </Col>
                                        </Row>
                                        <div className="form-group mt-3">
                                            <label className="view-opp-label">Job Role</label>
                                            <input type="text"
                                                className="form-control form-bg-opp"
                                                aria-describedby="text"
                                                value={data.jobrole}
                                                readOnly
                                                name="jobRole"
                                                placeholder="Job role"
                                            />

                                        </div>
                                        <div className="form-group mt-3">
                                            <label className="view-opp-label">Brief Description</label>
                                            <textarea rows={2} type="text"
                                                className="form-control"
                                                style={{ resize: "none", fontSize: "12px" }}
                                                value={data.briefJobDescription}
                                                readOnly
                                                name="briefJobDescription"
                                                placeholder="Brief Job Description"
                                            />

                                        </div>
                                    </div>
                                </div>
                                </Card >
                                <Card className="createopportunity-card"> <div style={{ marginTop: "-5px", marginLeft: "3%", marginRight: "3%" }} >
                                    <Row style={{ width: "99.8%" }} className="mt-2 no-gutters">
                                        <Col xs={6}>
                                            <div className="form-group">
                                                <label className="ml-1 view-opp-label">Contact Name</label>
                                                <input type="text"
                                                    className="form-control form-bg-opp"
                                                    aria-describedby="text"
                                                    readOnly
                                                    value={data.nameOfTheContactPerson}
                                                    name="nameOfTheContactPerson"
                                                    placeholder="Contact person"
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                            <div className="form-group">
                                                <label className="ml-2 view-opp-label">Email/Number</label>
                                                <input type="text"
                                                    className="ml-1 form-control form-bg-opp"
                                                    aria-describedby="text"
                                                    readOnly
                                                    value={data.phoneNumber}
                                                    name="phoneNumber"
                                                    placeholder="Contact person phone"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                </Card> */}
                                <Card className="createopportunity-card">
                                    <div style={{ marginLeft: "3%", marginRight: "3%" }} >
                                        <div className="form-group mt-2">
                                            <input type="text"
                                                className="form-control opp-fs"
                                                aria-describedby="text"
                                                value={data.company}
                                                readOnly
                                                name="company"
                                                placeholder="Organization Name"
                                            />
                                            <input type="text"
                                                className="form-control opp-fs"
                                                aria-describedby="text"
                                                value={data.url}
                                                readOnly
                                                name="url"
                                                placeholder="Posting Url"
                                            />
                                            <Row className="no-gutters opp-row-width">
                                                <Col xs={6}>
                                                    <input type="text"
                                                        className="form-control opp-fs"
                                                        aria-describedby="text"
                                                        value={data.nameOfTheContactPerson}
                                                        name="nameOfTheContactPerson"
                                                        readOnly
                                                        placeholder="Contact person"
                                                    />
                                                </Col>
                                                <Col xs={6}>
                                                    <input type="text"
                                                        className="ml-2 form-control opp-fs"
                                                        aria-describedby="text"
                                                        value={data.phoneNumber}
                                                        readOnly
                                                        name="phoneNumber"
                                                        placeholder="Contact Phone/email"
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Card>
                                <Card className="createopportunity-card">
                                    <div style={{ marginLeft: "3%", marginRight: "3%" }} >
                                        <div className="form-group mt-2">
                                            <Row className="no-gutters opp-row-width">
                                                <Col xs={8}>
                                                    <input type="text"
                                                        className="form-control opp-fs"
                                                        aria-describedby="text"
                                                        value={data.jobRole}
                                                        readOnly
                                                        name="jobRole"
                                                        placeholder="Role Title"
                                                    />
                                                </Col>
                                                <Col xs={4}>
                                                    <input type="number"
                                                        className="ml-2 form-control opp-fs"
                                                        aria-describedby="text"
                                                        value={data.vacancies}
                                                        readOnly
                                                        name="vacancies"
                                                        placeholder="Position(s)"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="no-gutters opp-row-width">
                                                <Col xs={4}>
                                                    <input type="number"
                                                        className="form-control opp-fs"
                                                        aria-describedby="text"
                                                        value={data.remuneration}
                                                        readOnly
                                                        name="remuneration"
                                                        placeholder={`${data.remuneration} (INR/month)`}
                                                    />
                                                </Col>
                                                <Col xs={8}>
                                                    <input type="text"
                                                        className="ml-2 form-control opp-fs"
                                                        aria-describedby="text"
                                                        readOnly
                                                        value={data.location}
                                                        name="location"
                                                        placeholder="Location"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row style={{ width: "100%" }} className="no-gutters">
                                                <Col xs={2}>
                                                    <input type="text"
                                                        className="form-control opp-fs"
                                                        aria-describedby="text"
                                                        value={data.typeOfOpportunity}
                                                        readOnly
                                                        name="typeOfOpportunity"
                                                    />
                                                </Col>
                                                <Col xs={3}>
                                                    <input type="text"
                                                        className="form-control opp-fs custom-form-control"
                                                        aria-describedby="text"
                                                        value={data.tenure}
                                                        readOnly
                                                        name="tenure"
                                                    />
                                                </Col>
                                                <Col xs={3}>
                                                    <input type="text"
                                                        style={{ padding: 'auto 2px' }}
                                                        className="form-control opp-fs"
                                                        aria-describedby="text"
                                                        value={data.type == 1 ? "Part-time" : "Full-time"}
                                                        readOnly
                                                        name="type"
                                                    />
                                                </Col>
                                                <Col xs={4}>
                                                    <DatePicker
                                                        className="form-control opp-date"
                                                        dateFormat="dd-MM-yyyy"
                                                        readOnly
                                                        selected={selectedStartDate}
                                                        minDate={new Date()}
                                                        placeholderText="Last date to apply"
                                                    />
                                                </Col>
                                            </Row>
                                            <textarea rows={12} type="text"
                                                className="mt-1 form-control"
                                                style={{ resize: "none", fontSize: "14px" }}
                                                value={data.briefJobDescription}
                                                name="briefJobDescription"
                                                readOnly
                                                placeholder="Brief Job Description"
                                            />

                                        </div>
                                    </div>
                                </Card>

                            </div >))}
                    </div>
                </div>
            </div >

        )
    }
}

