import React, { useState, useEffect } from "react";
import { Button, Carousel, Row, Col } from "react-bootstrap";
import { Link, Redirect, useParams } from 'react-router-dom';
import profileuser from '../images/profile-new.png';
import likes from '../images/sceem-likes.png';
import writearticle from '../images/sceem-writearticle.png';
import readarticle from '../images/sceem-readarticle.png';
import qna from '../images/QnA.svg';
import followers from '../images/sceem-followers.png';
import following from '../images/sceem-following.png';
import opportunity from '../images/opportunity.png';
import referrals from '../images/referral.svg';
import pollscreated from '../images/sceem-polls created.png';
import pollsparticipate from '../images/sceem-polls participate.png';
import months from '../images/sceem-months.png';
import smeappointments from '../images/smeappointments.svg';
import smerating from '../images/smerating.svg';
import moment from "moment-timezone";
import axios from 'axios';
import { config } from '../Config/Config';
import Header from "../Header/Header";
import "./ProfileScreen.css";
import SimpleCrypto from 'simple-crypto-js';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import qrlink from '../images/signupqr.svg';
import nebutech from '../images/nebutech-logo.svg';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function ProfileScreen() {
    const [name, setName] = useState("")
    const [posts, setPosts] = useState([])
    const [profilePicture, setProfilePicture] = useState("")
    const [role, setRole] = useState(localStorage.getItem('role'))
    const [userRole, setUserRole] = useState("")
    const [followerCount, setFollowerCount] = useState()
    const [followingCount, setFollowingCount] = useState()
    const [likesCount, setLikesCount] = useState()
    const [monthsActive, setMonthsActive] = useState()
    const [pollAnsweredCount, setPollAnsweredCount] = useState()
    const [pollCreatedCount, setPollCreatedCount] = useState()
    const [answersCount, setAnswersCount] = useState()
    const [opportunityCount, setOpportunityCount] = useState()
    const [questionsCount, setQuestionsCount] = useState()
    const [postCount, setPostCount] = useState()
    const [referralCount, setReferralCount] = useState()
    const [userArticleViews, setUserArticleViews] = useState()
    const [description, setDescription] = useState()
    const [author, setAuthor] = useState("")
    const [reviewer, setReviewer] = useState("")
    const [sme, setSme] = useState(false)
    const [disable, setDisable] = useState(false)
    const [disableSme, setDisableSme] = useState(false)
    const [disableAuthor, setDisableAuthor] = useState(false)
    const [disableReviewer, setDisableReviewer] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [authorText, setAuthorText] = useState()
    const [roleAsAuthor, setRoleAsAuthor] = useState()
    const [reviewerText, setReviewerText] = useState()
    const [roleAsReviewer, setRoleAsReviewer] = useState()
    const [smeText, setSmeText] = useState()
    const [roleAsSme, setRoleAsSme] = useState()
    const [rewardPoints, setRewardPoints] = useState()
    const [showExpress, setShowExpress] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [userTopics, setUserTopics] = useState([])
    const [topics, setTopics] = useState([])
    const [membership, setMembership] = useState()
    const [createdAt, setCreatedAt] = useState()
    const [updatedAt, setUpdatedAt] = useState()
    const [encryptedId, setEncryptedId] = useState('');
    const [decryptedId, setDecryptedId] = useState('');
    const [userId, setUserId] = useState('');
    const { id } = useParams();
    const [linkedin, setLinkedin] = useState("")
    const [commercial, setCommercial] = useState("")
    const [smeDesc, setSmeDesc] = useState("")
    const [rating, setRating] = useState("")
    const [bookings, setBookings] = useState("")
    const [smeTopics, setSmeTopics] = useState([])
    const [allOfferings, setAllOfferings] = useState([])
    const [selectedOfferings, setSelectedOfferings] = useState([]);
    // const location = useLocation()
    // const searchParams = new URLSearchParams(location.search)
    // const id = searchParams.get("id")


    useEffect(() => {
        console.log("params", id)
        getUserProfile()
        getTopics()
    }, [])

    const getTopics = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var payload = {
            "uniquecode": id
        }
        axios.post(config.userUrl + 'user/getUserTopicsByWeb', payload, { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    setTopics(response.data.topics)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const getUserProfile = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var payload = {
            "uniquecode": id
        }
        axios.post(config.userUrl + 'user/getProfileByWeb', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    let user = response.data;
                    setFollowerCount(user.followerCount)
                    setFollowingCount(user.followingCount)
                    setAnswersCount(user.totalAnswerCount)
                    setQuestionsCount(user.totalQuestionCount)
                    setLikesCount(user.likesCount)
                    setMonthsActive(user.monthsActive)
                    setPollAnsweredCount(user.pollAnsweredCount)
                    setPollCreatedCount(user.pollCreatedCount)
                    setReferralCount(user.referralCount)
                    setOpportunityCount(user.userOpportunityCount)
                    setPostCount(user.postCount)
                    setUserArticleViews(user.userArticleViews)
                    setDescription(user.user.description)
                    setMembership(user.user.merchantType)
                    setName(user.user.name)
                    setUserRole(user.user.userRoleId)
                    setCreatedAt(user.user.createdAt)
                    setUpdatedAt(user.user.updatedAt)
                    setRewardPoints(Number(response.data.getTotalRewardPointsForIndividual[0].count))
                    localStorage.setItem("planType", user.user.merchantType)
                    if (user.user.userRoleId == 5) {
                        getAllSmeOfferings()
                        getSmeUserData(user.user.uuid);
                    }
                    // if (role === "generalUser") {
                    //     setUserRole("User")
                    // } else {
                    //     const data = role?.charAt(0)?.toUpperCase() + role?.slice(1)
                    //     setUserRole(data)
                    // }
                    if ((user.user.profilePicture === null) && (user.user.gender === "0")) {
                        setProfilePicture("uploads/1652942537335Avatar%20Users2_37.png")
                    } else if ((user.user.profilePicture === null) && (user.user.gender === "1")) {
                        setProfilePicture("uploads/1652942450250Avatar Users2_31.png")
                    } else if ((user.user.profilePicture === null) && (user.user.gender === null)) {
                        setProfilePicture("uploads/1652942537335Avatar%20Users2_37.png")
                    } else {
                        setProfilePicture(user.user.profilePicture)
                    }
                    setIsLoading(false)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    }
    const getSmeUserData = (val) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var payload = {
            "userId": val
        }
        axios.post(config.userUrl + 'user/getSmeUserDataByIdWeb', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    if (response.data.data.length == 0) {
                        setSme(false)
                    } else {
                        // setSelectedDay(response.data.data[0]?.day)
                        // setSelectedTimeSlot(response.data.data[0]?.time)
                        // setDescriptionText(response.data.data[0].description)
                        // setSmeUserId(response.data.data[0].userId)
                        setSme(true)
                        setRating(response.data.data[0].avg_assessment)
                        setCommercial(response.data.data[0].commercial)
                        setBookings(response.data.data[0].bookings)
                        setSmeTopics(response.data.topics)
                        setSelectedOfferings(response.data.data[0].offering)
                        setLinkedin(response.data.data[0].linkedin)
                        setSmeDesc(response.data.data[0].desc)
                    }
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getAllSmeOfferings = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getAllSmeOfferings', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    setAllOfferings(response.data.offerings)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const kFormatter = (num) => {
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K' : Math.sign(num) * Math.abs(num)
    }
    const onClickUrl = () => {
        window.open(linkedin)
    }
    if (isLoading) {
        return <div>
            <Header />
            <div style={{ marginTop: "4.5rem" }}><div className="your-jitbits">Loading...</div></div>
        </div>
    }

    return (
        <div>
            <Header />
            <div className="ml-Text">
                <div style={{ marginTop: "4.5rem", }}><div className="your-jitbits">Profile</div>
                    <Row style={{ width: "100%", marginTop: "-13px" }} className="no-gutters">
                        <Col md={!sme ? 4 : 3} xs={12}>
                            <div className="self-text">Myself</div>
                            <div className="ml-2 mr-2 profile-border"><br />
                                <center className="mt-3"><img alt="" src={config.userUrl + profilePicture} className="profile-Auth-img" /></center>
                                <center>
                                    <div className="mt-1 user-Name">{name} <span className="author-text mb-2" style={{ color: "#31197C" }}>({userRole == 2 ? "General User" : userRole == 3 ? "Author" : userRole == 4 ? "Reviewer" : "Sme"}, {membership == 1 ? "Anticipator" : (membership == 2 ? "Integrator" : (membership == 3 ? "Collaborator" : "Orchestrator"))})</span></div>
                                    <div className="mt-1 author-text ml-2 mr-2" style={{ color: "#31197C" }}>{description}</div>
                                    <div className="mt-3 rewards-earned">Reward Points Earned: <span style={{ color: "#31197C" }}>{rewardPoints}</span></div>
                                </center>
                                <div className="mt-3 ml-3 mr-3" style={{ fontSize: "12px" }}><span style={{ fontSize: "14px", fontWeight: 600 }}>Interests: </span><br />{topics.map((data, i) => <span>{data.topicName.replace(/ *\([^]*\) */g, "")}, </span>)}</div>
                                <div className="mt-2 j-date mb-2">
                                    <div><b>Joined On:</b> {moment(createdAt).format('DD-MM-YYYY')}</div>
                                    <div><b>Last Login:</b> {moment(updatedAt).format('DD-MM-YYYY')}</div>
                                </div>


                            </div>
                        </Col>
                        <Col md={!sme ? 0 : 3} xs={!sme ? 0 : 12} style={!sme ? { display: "none" } : { display: "inline" }}>
                            <div className="self-text">SME Profile</div>
                            <div className="ml-2 mr-2 profile-border">
                                <div className={!sme ? "profile_Card_ profile_Card_width" : "profile_Card_ profile_Card_width_sme"}>
                                    <div className="flex"><img src={smeappointments} style={{ width: "28px" }} /> &nbsp;<span className="profile-text-top">Appointments</span></div>
                                    <div>{bookings}</div>
                                </div>
                                <div className={!sme ? "profile_Card_ profile_Card_width" : "profile_Card_ profile_Card_width_sme"}>
                                    <div className="flex"><img src={smerating} style={{ width: "28px" }} /> &nbsp;<span className="profile-text-top">Assessment</span></div>
                                    <div>{rating}</div>
                                </div>
                                <div style={{ marginLeft: "10px", marginTop: "-8px", fontSize: "13px", fontWeight: 600 }}>Expertise</div>
                                <div className="profile_Card_sme" style={{ marginTop: "-1px", display: "flex", justifyContent: "space-between" }}>
                                    {smeTopics.map((data, i) => (
                                        <div style={{ display: "flex", justifyContent: "space-between" }}><span style={{ fontSize: "10px" }}>{data.topicName}</span>
                                        </div>
                                    ))}
                                </div>
                                <div style={{ marginLeft: "10px", marginTop: "-8px", fontSize: "13px", fontWeight: 600 }}>Offerings</div>
                                <div className="profile_Card_sme" style={{ marginTop: "-1px", display: "flex", justifyContent: "space-between" }}>
                                    {allOfferings.filter(data => selectedOfferings.includes(data.id)).map((data, i) => (
                                        <div style={{ display: "flex", alignItems: "center" }}> <span style={{ fontSize: "10px", marginLeft: "5px" }}>{data.text}</span>
                                        </div>
                                    ))}
                                </div>
                                <div className="mt-2 ml-3 mr-3" style={{ fontSize: "12px" }}><span style={{ fontSize: "14px", fontWeight: 600 }}>About Me: </span><br />
                                    <div className="smeDesc">{smeDesc}</div>
                                </div>

                                <div className="mt-2 j-date mb-2">
                                </div>


                            </div>
                        </Col>
                        <Col md={!sme ? 4 : 3} xs={12}>
                            <div className="self-text">My Activities</div>
                            <div className="ml-2 mr-2 profile-border">
                                <div className={!sme ? "profile_Card_ profile_Card_width" : "profile_Card_ profile_Card_width_sme"}>
                                    <div className="flex"><img src={readarticle} style={{ width: "28px" }} /> &nbsp;<span className="profile-text-top">Article Reads</span></div>
                                    <div>{kFormatter(userArticleViews)}</div>
                                </div>
                                <div className={!sme ? "profile_Card_ profile_Card_width" : "profile_Card_ profile_Card_width_sme"}>
                                    <div className="flex"><img src={followers} style={{ width: "28px" }} /> &nbsp;<span className="profile-text-top">Followers</span></div>
                                    <div>{followerCount}</div>
                                </div>
                                <div className={!sme ? "profile_Card_ profile_Card_width" : "profile_Card_ profile_Card_width_sme"}>
                                    <div className="flex"><img src={following} style={{ width: "28px" }} /> &nbsp;<span className="profile-text-top">Following</span></div>
                                    <div>{followingCount}</div>
                                </div>
                                <div className={!sme ? "profile_Card_ profile_Card_width" : "profile_Card_ profile_Card_width_sme"}>
                                    <div className="flex"><img src={pollsparticipate} style={{ width: "28px" }} /> &nbsp;<span className="profile-text-top">Poll Answered</span></div>
                                    <div>{pollAnsweredCount}</div>
                                </div>
                                <div className={!sme ? "profile_Card_ profile_Card_width" : "profile_Card_ profile_Card_width_sme"}>
                                    <div className="flex"><img src={qna} style={{ width: "28px" }} /> &nbsp;<span className="profile-text-top">Questions</span></div>
                                    <div>{questionsCount}</div>
                                </div>
                                <div className={!sme ? "profile_Card_ profile_Card_width" : "profile_Card_ profile_Card_width_sme"}>
                                    <div className="flex"><img src={months} style={{ width: "28px" }} /> &nbsp;<span className="profile-text-top">Months Active</span></div>
                                    <div>{monthsActive}</div>
                                </div>
                            </div>
                        </Col>
                        <Col md={!sme ? 4 : 3} xs={12}>
                            <div className="self-text">My Contribution</div>
                            <div className="ml-2 mr-2 profile-border">
                                <div className={!sme ? "profile_Card_ profile_Card_width" : "profile_Card_ profile_Card_width_sme"}>
                                    <div className="flex"><img src={qna} style={{ width: "28px" }} /> &nbsp;<span className="profile-text-top">Answers</span></div>
                                    <div>{answersCount}</div>
                                </div>
                                <div className={!sme ? "profile_Card_ profile_Card_width" : "profile_Card_ profile_Card_width_sme"}>
                                    <div className="flex"><img src={likes} style={{ width: "28px" }} /> &nbsp;<span className="profile-text-top">Likes</span></div>
                                    <div>{likesCount}</div>
                                </div>
                                <div className={!sme ? "profile_Card_ profile_Card_width" : "profile_Card_ profile_Card_width_sme"}>
                                    <div className="flex"><img src={opportunity} style={{ width: "28px" }} /> &nbsp;<span className="profile-text-top">Opportunities</span></div>
                                    <div>{opportunityCount}</div>
                                </div>
                                <div className={!sme ? "profile_Card_ profile_Card_width" : "profile_Card_ profile_Card_width_sme"}>
                                    <div className="flex"><img src={writearticle} style={{ width: "28px" }} /> &nbsp;<span className="profile-text-top">Articles Posted</span></div>
                                    <div>{postCount}</div>
                                </div>
                                <div className={!sme ? "profile_Card_ profile_Card_width" : "profile_Card_ profile_Card_width_sme"}>
                                    <div className="flex"><img src={pollscreated} style={{ width: "28px" }} /> &nbsp;<span className="profile-text-top">Polls Created</span></div>
                                    <div>{pollCreatedCount}</div>
                                </div>
                                <div className={!sme ? "profile_Card_ profile_Card_width" : "profile_Card_ profile_Card_width_sme"}>
                                    <div className="flex"><img src={referrals} style={{ width: "28px" }} /> &nbsp;<span className="profile-text-top">Referrals</span></div>
                                    <div>{referralCount}</div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ width: "100%" }}>
                        <Col md={4} xs={12} sm={12}>     <div> <img alt="" src={nebutech} className="nebutech-image" /> </div></Col>
                        <Col md={4} xs={12} sm={12}>
                            <div className="bg-border-top">
                                <div className="background-new" ><br />
                                    <div className="app-border-top-profile" >
                                        {/* <div style={{ display: "flex" }}> <img alt="" src={logo} className="sceem-logo-download-new-pr" /> <div className="ml-2 article-chain-text-pr">Creating knowledge chain</div></div> */}
                                        <center className="become-member">Become a member !!</center>
                                        {/* <center className="box-text-lp-pr">Visit </center>
                                        <center className="box-text-lp-pr"><a href="https://mob.sceem.org/">mob.sceem.org</a></center>
                                        <center className="box-text-lp-pr">Or </center>
                                        <center className="box-text-lp-pr mb-2"><a href="https://www.sceem.org/signup">https://www.sceem.org/signup</a></center> */}
                                        <center> <img alt="" src={qrlink} style={{ width: "28%" }} /></center>
                                        <center className="box-text-lp"><a href="https://mob.sceem.org/">https://mob.sceem.org</a></center>
                                        {/* <img alt="" src={nebutech} className="powered-by-nebutech-new-pr" /> */}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={4} xs={12} sm={12}></Col>
                    </Row>

                </div>
            </div>
            {/* <div> <img alt="" src={nebutech} className="nebutech-image"/> </div> */}
        </div >
    )
}

