import { React, Component } from 'react';
import { Button, Navbar } from "react-bootstrap";
import logo from '../images/login-logo.png';
import './NavBar.css';

export default class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = { clicked: false }
    }
    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }
    render() {
        return (
            <Navbar bg="white" className="nav-height" fixed="top">
                <nav className='mt-3'>
                    <a >
                        <img className="navbar-logo" src={logo} /></a>
                    <div className="menu_icon" onClick={this.handleClick}>
                        <i className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
                    </div>
                    <ul className={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>
                        <li>
                            <a href="#home" className='nav-links'>Home</a>
                            <a href="#ourvision" className='nav-links'>Commitment</a>
                            <a href="#about" className='nav-links'>4Es Platform</a>
                            <a href="#offerings" className='nav-links'>Features</a>
                            <a href="#team" className='nav-links'>Our Team</a>
                            <a href="/pricing" className='nav-links'>Membership</a>
                            <a href="/signup">
                                <Button variant="default" className='nav-links-signUpbtn nav-margin-right'>Sign Up</Button>
                            </a>
                        </li>
                    </ul>
                </nav>
            </Navbar>
        )
    }
}