export const config = {
    "apiUrl": "https://sceem-auth-ssxk6.ondigitalocean.app/",
    "userUrl": "https://sceem-user-l3s5f.ondigitalocean.app/",
    "landingPageUrl": "https://sceem-landingpage-bt263.ondigitalocean.app/",
    "PASSWORD_ENCRYPTION_KEY": "zxcvbnmasdfghjkl",
    "feddupUserUrl":"https://api.feddup.me/",
    "sceemlink":"https://www.feddup.me/by/"
}
// export const config = {
//     "apiUrl":"http://localhost:8081/",
//     "userUrl": "http://localhost:8080/",
//     "landingPageUrl": "https://sceem-landingpage-bt263.ondigitalocean.app/",
//     "PASSWORD_ENCRYPTION_KEY": "zxcvbnmasdfghjkl",
//     "feddupUserUrl":"https://api.feddup.me/",
//     "sceemlink":"https://www.feddup.me/by/"
// }