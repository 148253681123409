import React, { Component } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";


export default class RedirectLink extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div>
                <Header />
                <center className="no-connection"> 
                <h3><b>OTP verified successfully !</b></h3>
                <h4 className="mt-4">Please use the below link and add it to home screen</h4><br /><a href="https://mob.sceem.org/">https://mob.sceem.org/</a></center>
            </div >
        )
    }
}
