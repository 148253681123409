import React, { Component } from "react";
import { Button, Card, Col } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import { enqueueSnackbar } from 'notistack';
import moment from "moment-timezone";
import Header from "../Header/Header";
import ExpiredPollResults from "./ExpiredPollResults";


export default class AnswerPoll extends Component {
    state = {
        userAnswer: null,
        currentIndex: 0,
        options: [],
        count: [],
        pollEnd: false,
        disabled: true,
        completed: 0,
        pollQuestion: [],
        pollId: this.props.match.params.id,
        isLoading: true,
        redirect: false,
        createdAt: "",
        endDate: "",
        showEmpty: false
    }

    componentDidMount() {
        console.log("poll-id", this.state.pollId)
        this.getPollById();
    }

    getPollById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + `user/getPollByIdWeb/${this.props.match.params.id}`, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    console.log("success!!");
                    if (response.data.pollDetails.length == 0) {
                        self.setState({
                            isLoading: false,
                            showEmpty: true,
                            endDate: response.data.endDate,
                        })
                    } else {
                        self.setState({
                            question: response.data.pollQuestion,
                            options: response.data.pollData,
                            topic: response.data.topicName,
                            createdAt: response.data.pollDetails[0].createdAt,
                            endDate: response.data.pollDetails[0].endDate,
                            isLoading: false
                        })
                    }
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    submitPoll = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var payload = {
            "pollId": parseInt(this.state.pollId),
            "pollQuestionOptionId": this.state.userAnswer
        }
        var self = this
        axios.post(config.userUrl + 'user/submitPollByUnregisteredUsers', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    enqueueSnackbar("Poll Answered Successfully !", { variant: 'success' })
                    // handleClickNavigate(this.state.userAnswer)
                    self.setState({
                        redirect: true
                    })
                    console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });

    };
    checkAnswer = answer => {
        const { options } = this.state
        this.setState({
            userAnswer: answer,
            disabled: false
        })
    }

    finishHandler = () => {
        const { currentIndex } = this.state
        this.submitPoll();

    }
    render() {

        const { isLoading, pollQuestion, redirect, showEmpty, question, options, count, topic, userAnswer, pollEnd, createdAt, endDate } = this.state
        if (isLoading) {
            return <div>{console.log("loading")}</div>
        }
        console.log("userAnswer", userAnswer);
        if (redirect) {
            return <Redirect to={{
                pathname: `/pollresults/${moment(createdAt).format("YYYYMMDD")}/${this.props.match.params.id}`,
                state: { userAnswer }
            }} />
            //     pathname=`/pollresults/${moment(createdAt).format("YYYYMMDD")}/${this.props.match.params.id}`,
            // state={userAnswer}} />
        }
        console.log("abc", moment(endDate).format('DD-MM-YYYY'), moment(Date.now()).format('DD-MM-YYYY'))
        if (showEmpty) {
            return (
                <div>
                    <div style={{ background: "#31197C" }}>
                        <Header /><br />
                        <center style={{ marginTop: "4rem", color: "white" }} className="expired-t"><b>Poll Expired on {moment(endDate).format('DD-MM-YYYY')}</b></center>
                        <center style={{ color: "white" }} className="mt-3 expired-t"><b>Thanks for your interest</b></center>
                       <br/><br/>
                    </div>
                    <ExpiredPollResults id={this.props.match.params.id} />
                </div>
            )
        }
        return (

            <div className="poll-bg"> <div >
                {/* <div className="topblue_bar">
                    <img alt="" src={bluelogo} className="login-logo" />
                    <div style={{ float: "right", marginRight: "2rem", marginTop: "1rem", color: "white" }}>www.sceem.org</div>
                </div> */}
                <Header />
                <div className="enable-center">
                    <div className="answers-card"><br />
                        <div style={{ display: "flex", width: "100%" }} className="mt-3 topic-name">Poll from Sceem on Topic : {topic.replace(/ *\([^]*\) */g, "")}</div>
                        <Col xs={12}>
                            <Card className="mt-3 poll-answer-card">
                                <div className="poll-question">{question}</div>
                                {options.map((option, idx) => {
                                    return (
                                        <div onClick={() => this.checkAnswer(option.id)} className={`polloptions-card ${userAnswer === option.id ? "selected" : null}`}><div className="poll-text">{option.option}</div></div>
                                    )
                                })}
                            </Card></Col>
                        <br />
                        <center><Button variant="default" className="mb-2 poll-submit-btn" onClick={this.submitPoll}><b> &nbsp;Submit &nbsp;</b></Button></center>
                    </div>
                </div>
            </div >
                <br /><br /><br />
            </div >
        )
    }
}

