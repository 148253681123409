import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import axios from 'axios';
import { config } from '../Config/Config';
import articlesPublished from '../images/articles published.png';
import articlesRead from '../images/articles read.png';
import qna from '../images/QnA.svg';
import opportunities from '../images/opportunity.png';
import campaigns from '../images/campaigns.png';
import aboutPolls from '../images/about-polls.png';
import eminentAuthors from '../images/eminent authors.png';
import { Bar } from 'react-chartjs-2';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

export default function Statistics() {

    const [campaignsCount, setCampaignsCount] = useState("");
    const [likesCount, setLikesCount] = useState("");
    const [pollsCount, setPollsCount] = useState("");
    const [publishedPostsCount, setPublishedPostsCount] = useState("");
    const [registeredUserCount, setRegisteredUserCount] = useState("");
    const [totalArticleViews, setTotalArticleViews] = useState("");
    const [totalQuestions, setTotalQuestions] = useState("");
    const [opportunitiesCount, setOpportunitiesCount] = useState("");
    const [topicDetails, setTopicDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        getAboutPageDetails();
    }, []);

    const getAboutPageDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/aboutPageDetails', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    setCampaignsCount(response.data.campaignsCount);
                    setLikesCount(response.data.likesCount);
                    setPollsCount(response.data.pollsCount);
                    setPublishedPostsCount(response.data.publishedPostsCount);
                    setRegisteredUserCount(response.data.registeredUserCount);
                    setTopicDetails(response.data.topicDetails);
                    setTotalArticleViews(response.data.totalArticleViews);
                    setTotalQuestions(response.data.totalQuestions);
                    setOpportunitiesCount(response.data.totalOpportunitiesCount);
                    setIsLoading(false);
                }
            })
            .catch(function (error) {
                console.log("error", error);
            })
    };

    if (isLoading) {
        console.debug("renders: Loading...");
        return <h3 className="App"></h3>;
    }

    let filterTopics = [];
    for (let i = 0; i < topicDetails.length; i++) {
        if (topicDetails[i].postsCount >= 2) {
            filterTopics.push(topicDetails[i]);
        }
    }
    console.log("labels", filterTopics)
    function kFormatter(num) {
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K' : Math.sign(num) * Math.abs(num)
    }
    const data = {
        labels: [topicDetails[0].topicName.replace(/ *\([^]*\) */g, ""), topicDetails[1].topicName.replace(/ *\([^]*\) */g, ""), topicDetails[2].topicName.replace(/ *\([^]*\) */g, ""), topicDetails[3].topicName.replace(/ *\([^]*\) */g, "")],
        datasets: [
            {
                data: [topicDetails[0].postsCount, topicDetails[14].postsCount, topicDetails[2].postsCount, topicDetails[3].postsCount],
                fill: true,
                backgroundColor: ["#31197C"],
                borderColor: "rgba(75,192,192,1)",
                barThickness: 25
            }
        ]
    }
    return (
        <div>
            <Header />
            <Row style={{ marginTop: "4.03rem", width: "100%" }}>
                <Col md={4} xs={12}>
                </Col>
                <Col md={4} xs={12} className="stats">
                    <div className="ml-2 container" >
                        <h6 style={{ color: "#ff942d",fontFamily:"Open Sans",fontWeight:"600", marginTop: "0.7rem" }} className="stats-head">Statistics</h6>
                        <div className="row">
                            <div className="col-xs-4">
                                <Card className="articles-Published">
                                    <center>
                                        <img alt="" src={articlesPublished} style={{ width: "32px", height: "32px" }} />
                                        <div className="bold-text">{kFormatter(publishedPostsCount)}</div>
                                        <div className="text-in-about">Published</div>
                                    </center>
                                </Card>
                            </div>
                            <div className="col-xs-4">
                                <Card className="articles-Published">
                                    <center>
                                        <img alt="" src={articlesRead} style={{ width: "32px", height: "32px" }} />
                                        <div className="bold-text">{kFormatter(totalArticleViews)}</div>
                                        <div className="text-in-about">Read</div>
                                    </center>
                                </Card>
                            </div>
                            <div className="col-xs-4">
                                <Card className="articles-Published">
                                    <center>
                                        <img alt="" src={opportunities} style={{ width: "40px", height: "40px" }} />
                                        <div style={{ marginTop: "-10px" }}>
                                            <div className="bold-text">{kFormatter(opportunitiesCount)}</div>
                                            <div className="text-in-about">Opportunities</div>
                                        </div>
                                    </center>
                                </Card>
                            </div>
                        </div>
                    </div>
                    <Card className="mt-2 articles-graph">
                        <div> <center style={{ marginTop: "-8px" }} className="text-in-about">Top Articles per Topic</center>
                            <Bar data={data}
                                options={{
                                    scales: {
                                        scaleOverride: true,
                                        x: {
                                            grid: {
                                                display: false,
                                            },
                                        },
                                        y: {
                                            grid: {
                                                display: false,
                                            },
                                        },
                                    },
                                    label: {
                                        display: true
                                    },
                                    plugins: {
                                        legend: { display: false }
                                    },
                                    tooltips: {
                                        callbacks: {
                                            label: function (tooltipItem) {
                                                return tooltipItem.yLabel;
                                            }
                                        }
                                    }

                                }
                                }
                            />
                        </div>
                    </Card>
                    <div className="mt-2 ml-2 container">
                        <div className="row" >
                            <div className="col-xs-4">
                            <Card className="campaigns-polls">
                                    <div className="row no-gutters">
                                        <Col xs={5}>
                                            <center className="ml-2"> <img alt="" src={campaigns} style={{ width: "30px", height: "30px" }} /></center>
                                        </Col>
                                        <Col xs={7}>
                                            <center style={{ marginTop: "-1px" }}>
                                                <div className="bold-text">{kFormatter(campaignsCount)}</div>
                                            </center>
                                        </Col>
                                        <div className="text-in-about">Advertisements</div>
                                    </div>
                                </Card>
                            </div>
                            <div className="col-xs-4">
                                <Card className="campaigns-polls">
                                    <div className="row no-gutters">
                                        <Col xs={4}>
                                            <center className="ml-2"> <img alt="" src={aboutPolls} style={{ width: "30px", height: "30px" }} /></center>
                                        </Col>
                                        <Col xs={8}>
                                            <center style={{ marginTop: "-1px" }}>
                                                <div className="bold-text">{kFormatter(pollsCount)}</div>
                                            </center>
                                        </Col>
                                        <div className="text-in-about">Polls</div>
                                    </div>
                                </Card>
                            </div>
                            <div className="col-xs-4">
                            <Card className="campaigns-polls">
                                    <div className="row no-gutters">
                                        <Col xs={4}>
                                            <center className="ml-2"> <img alt="" src={qna} style={{ width: "30px", height: "30px" }} /></center>
                                        </Col>
                                        <Col xs={8}>
                                            <center style={{ marginTop: "-1px" }}>
                                                <div className="bold-text">{kFormatter(totalQuestions)}</div>
                                            </center>
                                        </Col>
                                        <div className="text-in-about">Q&A</div>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>
                    <Card className="mt-2 eminent-authors">
                        <div className="row">
                            <Col xs={4}>
                                <center className="ml-1 mt-2"> <img alt="" src={eminentAuthors} style={{ width: "42px", height: "42px" }} /></center>
                            </Col>
                            <Col xs={8}>
                                <center style={{ marginTop: "-10px", marginLeft: "-20px" }}>
                                    <div className="bold-text">{kFormatter(registeredUserCount)}</div>
                                    <div className="text-in-about">Enterprises, Entrepreneurs, Experts, Enthusiasts</div>
                                </center>
                            </Col>
                        </div>
                    </Card>
                </Col >
                <Col md={4} xs={12}></Col>
            </Row >
            <Footer />
        </div >
    )
}
