import React, { Component } from "react";
import { Button, Form, Col, Row, Modal } from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';


export default function AppLinkPopup({ setRedirect }) {

    return (
        <Modal show={true} onHide={() => setRedirect(false)} size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Body>
                    <center>
                        <h3><b>OTP verified successfully !</b></h3>
                        <h4 className="mt-4">Please use the below link and add it to home screen</h4><br /><a href="https://mob.sceem.org/">https://mob.sceem.org/</a></center>
         
            </Modal.Body>
        </Modal>
    )
}
