import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import axios from 'axios';
import { config } from '../Config/Config';
import { Button, Form, Modal } from "react-bootstrap";
import Header from "../Header/Header";
import AppLinkPopup from "./AppLinkPopup";

export default function VerifyOtpPopup({ loginPage, togglePopup, setRedirect, email }) {
    const [otpValues, setOtpValues] = useState({
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: "",
        otp5: "",
        otp6: "",
    });

    const [errorMsg, setErrorMsg] = useState("");

    // const handleChange = (name, value) => {
    //     setOtpValues({
    //         ...otpValues,
    //         [name]: value
    //     });
    //     setErrorMsg("");
    // }
    const handleChange = (name, value) => {
        // Validate input value to allow only numbers
        if (/^\d*$/.test(value) && value.length <= 1) {
            setOtpValues({
                ...otpValues,
                [name]: value
            });
            setErrorMsg("");
        }
    }

    const inputfocus = (event) => {
        const { key, target: { tabIndex } } = event;

        if (key === "Backspace" || key === "Delete") {
            const next = tabIndex - 2;
            if (next > -1) {
                event.target.form.elements[next].focus();
            }
        } else {
            const next = tabIndex;
            if (next < 6) {
                event.target.form.elements[next].focus();
            }
        }
    }

    const verifyOtp = async (e) => {
        try {
            const headers = {
                "Content-Type": 'application/json',
                "applicationType": "mobile"
            };

            const payload = {
                "email": email,
                "otp": Object.values(otpValues).join("")
            };

            const response = await axios.post(config.apiUrl + 'auth/verifyEmail', payload, { headers });

            if (response.data.status === true) {
                console.log("Email verified successfully!!");
                togglePopup()
                setRedirect(true);
            } else {
                console.log(response.data.message);
                setErrorMsg(response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1));
            }
        } catch (error) {
            console.log(error);
            setErrorMsg("An error occurred. Please try again.");
        }
    }

    const resendOtp = async () => {
        try {
            const headers = {
                "Content-Type": 'application/json',
                "applicationType": "mobile"
            };

            const payload = {
                "email": email,
                "registerFlow": true
            };

            const response = await axios.post(config.apiUrl + 'auth/sendOtp', payload, { headers });

            const msg = "Otp sent successfully!!";
            if (response.data.status === true) {
                console.log("Otp sent successfully!!");
                console.log("payload", payload);
                document.getElementById('otp').innerText = msg;
            } else {
                console.log(response.data.message);
                setErrorMsg(response.data.message); // Set error message received from server
            }
        } catch (error) {
            console.log(error);
            setErrorMsg("An error occurred. Please try again."); // Generic error message
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        verifyOtp();
    }

    // if (redirect) {
    //     return <Redirect to={{ pathname: "/link" }} />;
    // }
    // const toggleLink = () => {
    //     redirect(!redirect)
    // }
    return (
        <div>
            <Modal show={loginPage} size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body>
                    <div className="mt-3 mb-3">
                        <center>
                            <h3><b>Thanks for registering !</b></h3>
                            <h4 className="mt-3">You will receive an OTP to verify on your mail</h4>
                            <h4 className="mt-3">Please enter here and complete the process</h4>
                            <Form className="mt-5 forgot-email" onSubmit={handleSubmit}>
                                <center>
                                    <div style={{ width: "80%" }} className="form-group verify">
                                        {[1, 2, 3, 4, 5, 6].map((index) => (
                                            <input
                                                key={index}
                                                name={`otp${index}`}
                                                type="number"
                                                autoComplete="off"
                                                className="form-control digit-width"
                                                value={otpValues[`otp${index}`]}
                                                onChange={(e) => handleChange(`otp${index}`, e.target.value)}
                                                tabIndex={index}
                                                maxLength={1}
                                                onKeyUp={inputfocus}
                                                inputMode="numeric"
                                            />
                                        ))}
                                    </div>
                                    <div className="errormsg">{errorMsg}</div> 
                                    <br /><br />
                                    <Button variant="default" type="submit" className="btn-block login-btn">Verify</Button><br />
                                    <p >Did not get the OTP?<span className="try cursor-pointer" onClick={resendOtp}> Resend OTP</span></p>
                                </center>
                            </Form>
                        </center>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
// import React, { useEffect, useState } from "react";
// import { Redirect } from "react-router-dom";
// import axios from 'axios';
// import { config } from '../Config/Config';
// import { Button, Form, Modal } from "react-bootstrap";
// import Header from "../Header/Header";
// import AppLinkPopup from "./AppLinkPopup";

// export default function VerifyOtpPopup({ loginPage,togglePopup,setRedirect,email }) {
//     const [otpValues, setOtpValues] = useState({
//         otp1: "",
//         otp2: "",
//         otp3: "",
//         otp4: "",
//         otp5: "",
//         otp6: "",
//     });



//     const handleChange = (name, value) => {
//         setOtpValues({
//             ...otpValues,
//             [name]: value
//         });
//     }

//     const inputfocus = (event) => {
//         const { key, target: { tabIndex } } = event;

//         if (key === "Backspace" || key === "Delete") {
//             const next = tabIndex - 2;
//             if (next > -1) {
//                 event.target.form.elements[next].focus();
//             }
//         } else {
//             const next = tabIndex;
//             if (next < 6) {
//                 event.target.form.elements[next].focus();
//             }
//         }
//     }

//     const verifyOtp = async (e) => {
//         try {
//             const headers = {
//                 "Content-Type": 'application/json',
//                 "applicationType": "mobile"
//             };

//             const payload = {
//                 "email": email,
//                 "otp": Object.values(otpValues).join("")
//             };

//             const response = await axios.post(config.apiUrl + 'auth/verifyEmail', payload, { headers });

//             if (response.data.status === true) {
//                 console.log("Email verified successfully!!");
//                 togglePopup()
//                 setRedirect(true);
//             } else {
//                 console.log(response.data.message);
//                 document.getElementById('status').innerText = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1);
//             }
//         } catch (error) {
//             console.log(error);
//             document.getElementById('status').innerText = error;
//         }
//     }

//     const resendOtp = async () => {
//         try {
//             const headers = {
//                 "Content-Type": 'application/json',
//                 "applicationType": "mobile"
//             };

//             const payload = {
//                 "email": email,
//                 "registerFlow": true
//             };

//             const response = await axios.post(config.apiUrl + 'auth/sendOtp', payload, { headers });

//             const msg = "Otp sent successfully!!";
//             if (response.data.status === true) {
//                 console.log("Otp sent successfully!!");
//                 console.log("payload", payload);
//                 document.getElementById('otp').innerText = msg;
//             } else {
//                 console.log(response.data.message);
//                 console.log("err", payload);
//             }
//         } catch (error) {
//             console.log(error);
//         }
//     }

//     const handleSubmit = (event) => {
//         event.preventDefault();
//         verifyOtp();
//     }

//     // if (redirect) {
//     //     return <Redirect to={{ pathname: "/link" }} />;
//     // }
//     // const toggleLink = () => {
//     //     redirect(!redirect)
//     // }
//     return (
//         <div>
//             <Modal show={loginPage} size="md"
//                 aria-labelledby="contained-modal-title-vcenter"
//                 centered>
//                 <Modal.Body>
//                     <div className="mt-3 mb-3">
//                         <center>
//                             <h3><b>Thanks for registering !</b></h3>
//                             <h4 className="mt-3">You will receive an OTP to verify on your mail</h4>
//                             <h4 className="mt-3">Please enter here and complete the process</h4>
//                             <Form className="mt-5 forgot-email" onSubmit={handleSubmit}>
//                                 <center>
//                                     <div style={{ width: "80%" }} className="form-group verify">
//                                         {[1, 2, 3, 4, 5, 6].map((index) => (
//                                             <input
//                                                 key={index}
//                                                 name={`otp${index}`}
//                                                 type="text"
//                                                 autoComplete="off"
//                                                 className="form-control digit-width"
//                                                 value={otpValues[`otp${index}`]}
//                                                 onChange={(e) => handleChange(`otp${index}`, e.target.value)}
//                                                 tabIndex={index}
//                                                 maxLength={1}
//                                                 onKeyUp={inputfocus}
//                                                 inputMode="numeric"
//                                             />
//                                         ))}
//                                     </div>
//                                     <div id="status" className="errormsg"></div>
//                                     <br /><br />
//                                     <Button variant="default" type="submit" className="btn-block login-btn">Verify</Button><br />
//                                     <p >Did not get the OTP?<span className="try cursor-pointer" onClick={resendOtp}> Resend OTP</span></p>
//                                     <div id="tryanother" className="errormsg"></div>
//                                 </center>
//                             </Form>
//                         </center>
//                     </div>
//                 </Modal.Body>
//             </Modal>
//         </div>
//     );
// }
