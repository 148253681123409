import React, { Component } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";


export default class FAQ extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div>
                <Header />
                <p className="terms-heading">FAQ's</p>
                <div className="ml-4 mr-2">
                    <b className="heading">What is <span style={{ color: "#31197C" }}>sceem</span>?</b>
                    <p className="terms_Text">“sceem” is a platform solely for Supply Chain Professionals, Experts and Aspirants to share, learn and experience.
                        This platform provides various options to stay connected by means of polls, Ask an Expert, Opportunities and many more.</p>
                    <b className="heading mt-2">How to use <span style={{ color: "#31197C" }}>sceem</span> ?</b>
                    <p className="terms_Text">Yes, you can sign-in on <span><a href="http://www.sceem.org" target="_blank" rel="noopener noreferrer" style={{ color: "#31197C" }}>www.sceem.org</a></span> or use a referral link from your friend or use the mobile app to register.</p>
                    <b className="heading">Can I register to use?</b>
                    <p className="terms_Text">Anyone who is aspiring to learn about Supply Chain can register here. It is free. For certain additional services it costs nominal. For further details, please refer to Pricing page (url).</p>
                    <b className="heading">Is my data safe?</b>
                    <p className="terms_Text">Yes. We do not ask too much of personal information. Information which is relevant to the platform and other users is collected and stored safe.</p>
                    <b className="heading">What are the articles about?</b>
                    <p className="terms_Text">All articles are written by authors of sceem. Before publishing, they are peer reviewed by another sceem user who is an expert in that area.</p>
                    <b className="heading">How do I share articles with others?</b>
                    <p className="terms_Text">You can share articles with others using the mobile app. It is simple and user friendly.</p>
                    <b className="heading">Can I contribute to articles?</b>
                    <p className="terms_Text">Yes. You can add as author or reviewer or SME depending on your interest. sceem admin will review the request.</p>
                    <b className="heading">Where can I write articles?</b>
                    <p className="terms_Text">There is a separate web app for write, save and send for review your article content. When once your request is approved by the admin you will get access to the same.</p>
                    <b className="heading">Can I put advertisement of my product?</b>
                    <p className="terms_Text">Yes. You can send a mail to admin@sceem.org with your advertisement content. Admin will revert back with form to fill and then publish. Commercial advertisements are charged.</p>
                    <b className="heading">I need more information on this platform, can I connect with <span style={{ color: "#31197C" }}>sceem</span>?</b>
                    <p className="terms_Text">Yes. You can drop an email admin@sceem.org and sceem team will connect to provide further information during working days.</p>
                    <b className="heading">Can I publish a job opportunity?</b>
                    <p className="terms_Text">Yes. You can publish job opportunity. Sceem admin will review it once before publishing</p>
                    <b className="heading">What is the publication process of any content?</b>
                    <p className="terms_Text">Any content (article, Opportunity or any thing added further) will be of the author of the content. Role of Reviewer or Admin is to moderate the content only but not modify or suggest to modify.</p>
                </div>
                <br />
                <Footer />
            </div >
        )
    }
}
