import { React, Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from "react-bootstrap";
import navlogo from '../images/logo-white.svg';
import bottomicon from '../images/footer-bottom.png';
import iso from '../images/isocertified.svg';
import moment from 'moment-timezone';

export default class Footer extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="mt-3 footer_bg">
                <Row style={{ width: "100%" }}>
                    <Col xs={12} md={6}>
                        <img src={navlogo} className="sceem-logo" /><br />
                        <img src={iso} className="iso" />
                    </Col>
                    <Col xs={12} md={6}>
                        <Row style={{ width: "100%" }}>
                            <Col xs={12} md={3}>
                                {/* <div className='links'><b>Community</b></div>
                                <a> <p className='mt-4 links-sm'>Telegram</p></a> */}
                                {/* <a> <p className='links-sm'>Instagram</p></a>
                                <a> <p className='links-sm'>Slack</p></a> */}
                            </Col>
                            <Col xs={12} md={3}>
                                <div className='links'><b>Support</b></div>
                                <a href="/faq"> <p className='mt-4 links-sm'>FAQ</p></a>
                                <a> <p className='links-sm'>Help & Support</p></a>
                                <a> <p className='links-sm'>Contact Us</p></a>
                            </Col>
                            <Col xs={12} md={4}>
                                <div className='links'><b>Policies</b></div>
                                <a href="https://www.sceem.org/privacypolicy" target="_blank" rel="noopener noreferrer"> <p className='mt-4 links-sm'>Privacy Policy</p></a>
                                <a href="https://www.sceem.org/terms" target="_blank" rel="noopener noreferrer"> <p className='links-sm'>Terms & Conditions</p></a>
                                <a href="https://www.sceem.org/codeofconduct" target="_blank" rel="noopener noreferrer"> <p className='links-sm'>Code of Conduct</p></a>
                            </Col>
                            <Col xs={12} md={2}>
                                <img src={bottomicon} className="bottom-icon" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                    <footer className='copyright'>Copyright {moment().year()} Sceem. All rights reserved.</footer>
            </div>
        )
    }
}