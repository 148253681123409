import React, { Component } from "react";
import { Button, Navbar, Nav, Form, Container, Card, Col, Row, Image } from "react-bootstrap";
import landingimg from '../images/landingpage-image1.png';
import about from '../images/about-sceem.svg';
import './LandingPage.css';
import NavBar from "../NavBar/NavBar";
import logo from '../images/login-logo.png';
import games from '../images/games cir.svg';
import rewards from '../images/rewards cir.svg';
import legalaudit from '../images/Legal and Audit.svg';
import people from '../images/People.svg';
import globaltrends from '../images/Global trends.svg';
import governance from '../images/Governance.svg';
import leadership from '../images/Leadership_new.svg';
import fullfilment from '../images/Fullfilment.svg';
import gogreen from '../images/Go Green.svg';
import industry from '../images/Industry 4.0.svg';
import customercentric from '../images/Customer centric_new.svg';
// import emergingtech from '../images/emergingtech.png';
// import workforce from '../images/Workforce.png';
// import security from '../images/Security.png';
// import financetrends from '../images/Financetrends.png';
// import circularsupply from '../images/Circularsupply.png';
// import circulareconomy from '../images/Circulareconomy.png';
// import wastedisposal from '../images/Wastedisposal.png';
// import businesstransform from '../images/Businesstransform.png';
// import models from '../images/Models.png';
// import businesscontinuity from '../images/Businesscontinuity.png';
// import logistics from '../images/Logistics.png';
// import procurement from '../images/Procurement.png';
// import regulations from '../images/Regulations.png';
// import compliance from '../images/Compliance.png';
// import managementoffice from '../images/Managementoffice.png';
// import audit from '../images/Audit.png';
// import growthmindset from '../images/Growthmindset.png';
// import workspace from '../images/Workspace.png';
// import smartgovernance from '../images/Smartgovernance.png';
// import sharingeconomy from '../images/Sharingeconomy.png';
// import nextgen from '../images/Nextgen.png';
// import localisation from '../images/Localisation.png';
// import lastmiledelivery from '../images/Lastmiledelivery.png';
// import supportresiliency from '../images/Supportresilency.png';
// import flawlessdelivery from '../images/Flawlessdelivery.png';
import assets from '../images/assets-g.png';
import operations from '../images/operations-g.png';
import infotechnology from '../images/info-technology-g.png';
import crm from '../images/crm-g.png';
import erp from '../images/erp-g.png';
import payments from '../images/payments&financing-g.png';
import sourcing from '../images/sourcing-g.png';
import vendormanagement from '../images/vendormanagement-g.png';
import milestone from '../images/milestone feddup.svg';
import contactmanagement from '../images/contactmanagement-g.png';
import inventorycontrol from '../images/inventorycontrol-g.png';
import demandplanning from '../images/demandplanning-g.png';
import internationaltrade from '../images/internationaltrade-g.png';
import strategicsourcing from '../images/strategicsourcing-g.png';
import disposalmanagement from '../images/disposalmanagement-g.png';
import wastemanagement from '../images/wastemanagement-g.png';
import sustainablegrowth from '../images/sustainablegrowth-g.png';
// import models from '../images/models-g.png';
import articles from '../images/article-services.svg';
import qna from '../images/qna cir.svg';
import quizzes from '../images/quiz-services.svg';
import networking from '../images/networking-services.svg';
import adds from '../images/add-services.svg';
import opportunity from '../images/opportunity-services.svg';
import team1 from '../images/team-img1.svg';
import team2 from '../images/team-img2.svg';
import team3 from '../images/team-img3.svg';
import team4 from '../images/team-img4.svg';
import team5 from '../images/team-img5.svg';
import team6 from '../images/team-img6.svg';
import team7 from '../images/team-img7.svg';
import contact from '../images/contact-us.svg';
import background from '../images/background.png';
import nebutechpng from '../images/nebutech1.png';
import nebutech from '../images/nebutech-logo.svg';
import creativedays from '../images/creative_days.png';
import iscpa from '../images/iscpa.png';
import sugoilabs from '../images/sugoilabs.jpeg';
import Footer from "../Footer/Footer";
import { Link } from 'react-router-dom';
import navlogo from '../images/logo-white.svg';
import axios from 'axios';
import { config } from '../Config/Config';
import rating1 from '../images/rating1.png';
import rating2 from '../images/rating2.png';
import rating3 from '../images/rating3.png';
import rating4 from '../images/rating4.png';
import rating5 from '../images/rating5.png';
import ratingImg from '../images/showrating.PNG';
import Loading from "../Loading/Loading";
import ScrollToTop from "../ScrollToTop";
import LandingPagePopup from "./LandingPagePopup";

export default class LandingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            rating: "",
            ratings: [],
            avgRating: "",
            isLoading: true,
            averageRatingCount: "",
            url: "",
            load: true,
            isLoad: true,
            noOfRaters: "",
            showSignup: false
        }
    }
    componentDidMount = () => {
        // let data = document.getElementById('#feddup12345')?.innerHTML;
        // if (data) {
        //   let dataId = data.split("\"");
        //   if (dataId[dataId.length - 1].length > 12) {
        //     dataId = dataId[dataId.length - 1];
        //     this.setState({id:dataId})
        //   }
        //   else if (dataId[dataId.length - 2].length > 12) {
        //     dataId = dataId[dataId.length - 2];
        //     this.setState({id:dataId})
        //   }
        //   else{
        //     this.setState({id:dataId})
        //   }
        // }

        this.setState({ load: false })
        setTimeout(() => { this.setState({ isLoad: false }) }, 6000);
        this.getProductDetails()
        const timer = setTimeout(() => {
            this.setState({ showSignup: true })
        }, 1000);
        console.log("red", this.state.showSignup)
        return () => clearTimeout(timer);
    }

    closePopup = () => {
        this.setState({ showSignup: false })
    }

    getProductDetails = () => {
        let data = document.getElementById('#feddup12345')?.innerHTML;
        if (data) {
            let dataId = data.split("\"");
            if (dataId[dataId.length - 1].length > 12) {
                dataId = dataId[dataId.length - 1];
                this.setState({ id: dataId })
            }
            else if (dataId[dataId.length - 2].length > 12) {
                dataId = dataId[dataId.length - 2];
                this.setState({ id: dataId })
            }
            else {
                this.setState({ id: dataId })
            }
            var payload = {
                "productId": dataId
            }
        }
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        console.log("payload", payload)
        var self = this;
        axios.post(config.feddupUserUrl + "user/getTopThreeRatingByProductId", payload, { headers: headers })
            .then(function (response) {
                console.log("getTopThreeRatingByProductId", response);
                if (response.data.status === true) {
                    self.setState({
                        ratings: response.data?.numberOfRating,
                        averageRatingCount: response.data?.averageRating[0]?.avgrating,
                        noOfRaters: response.data?.numberOfRaters,
                        url: response.data?.url,
                        isLoading: false,
                        avgRating: `${((Math.round(response.data?.averageRating[0]?.avgrating * 10) / 10) / 5) * 100}%`,
                        rating: "100" - `${((Math.round(response.data?.averageRating[0]?.avgrating * 10) / 10) / 5) * 100}`
                    })
                    console.log("res", response.data)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
                self.setState({
                    isLoading: false,
                })
            });
    };
    render() {
        const { isLoad, load, showSignup, avgRating, isLoading, averageRatingCount, noOfRaters, ratings, rating } = this.state;
        console.log("rating", avgRating, rating)
        if (isLoading) {
            console.debug("renders: Loading...");
            return <div className="App"></div>;
        }
        if (isLoad) {
            return <Loading />
        }

        return (
            <div>   {showSignup && (
                <LandingPagePopup showSignUp={showSignup} isLoad={isLoad} closePopup={this.closePopup} />
            )}
                <div>
                    <NavBar />
                    <div className="loading-animation">
                        <div id="home" ><img className="service-home home-image-lan" src="landingpage-image1.png" alt="" /></div>
                        <div >
                            <div className="l-page-top">Creating Knowledge Chain</div>
                            <div className="mt-2 l-page-text"><b>Supply Chain 4Es Platform (sceem)</b> is for <b>E</b>nterprises, <b>E</b>ntrepreneurs, <b>E</b>xperts, <b>E</b>nthusiasts. It is to create a knowledge chain on latest trends, technology innovations and business advancements
                                under one roof of supply chain economy.</div>
                        </div></div><br />
                    <div className="service-commit" id="ourvision">
                        <div className="vision-bg"><br /><br /><br />
                            <div className="our-vision">Our Commitment</div>
                            <div className="mt-3 vision">
                                <div className="vision-text">Our <b>vision</b> is to create knowledge chain platform to seamlessly learn, share, connect and explore about rapidly transforming supply chain management.</div>
                                <div className="mt-2 vision-text">Our <b>mission</b> is to enrich content with text, audio, video and AR/VR as we grow together.</div>
                                <div className="mt-2 vision-text">Our <b>objective</b> is based on “Supply chain is not a business; it is obligation of a business to ensure everyone in world have access to supplies”.</div>
                            </div><br /><br />
                            {/* <div className="mt-3 service" id="topics">
                            <div className="our-vision">Common interests</div>
                            <div className="mt-3 vision">
                                <div className="topic-allicons">
                                    <img src={emergingtech} className="topic-each-icon" />
                                    <img src={workforce} className="topic-each-icon" />
                                    <img src={security} className="topic-each-icon" />
                                    <img src={financetrends} className="topic-each-icon" />
                                    <img src={circularsupply} className="topic-each-icon" />
                                    <img src={circulareconomy} className="topic-each-icon" />
                                    <img src={wastedisposal} className="topic-each-icon-1" />
                                    <img src={businesstransform} className="topic-each-icon" />
                                </div>
                                <div className="topic-allicons mt-3 mb-3">
                                    <img src={models} className="topic-each-icon" />
                                    <img src={businesscontinuity} className="topic-each-icon" />
                                    <img src={logistics} className="topic-each-icon" />
                                    <img src={procurement} className="topic-each-icon" />
                                    <img src={regulations} className="topic-each-icon" />
                                    <img src={compliance} className="topic-each-icon" />
                                    <img src={managementoffice} className="topic-each-icon" />
                                    <img src={audit} className="topic-each-icon-1" />
                                </div>
                                <div className="topic-allicons">
                                    <img src={growthmindset} className="topic-each-icon-2" />
                                    <img src={workspace} className="topic-each-icon" />
                                    <img src={smartgovernance} className="topic-each-icon" />
                                    <img src={sharingeconomy} className="topic-each-icon" />
                                    <img src={nextgen} className="topic-each-icon-1" />
                                    <img src={localisation} className="topic-each-icon-1" />
                                    <img src={lastmiledelivery} className="topic-each-icon-logis" />
                                    <img src={supportresiliency} className="topic-each-icon-2" />
                                    <img src={flawlessdelivery} className="topic-each-icon" />

                                </div>
                            </div><br /><br /><br />
                        </div> */}
                            <div className="mt-3 service" id="topics">
                                <div className="our-vision">Common interests</div>
                                <div className="mt-3 vision">
                                    <center>
                                        <div className="row no-gutters">
                                            <div className="col-md-4 col-xs-4">
                                                <div><img src={industry} className="topic-each-icon" /><span className="games-Text">Industry 4.0</span><br /><span className="topics">Emerging Tech, Workforce, Security, Finance Trends</span></div>
                                            </div>
                                            <div className="col-md-4 col-xs-4">
                                                <div><img src={gogreen} className="topic-each-icon" /><span className="games-Text">Go Green</span><br /><span className="topics">Circular Supply, Circular Economy, Waste Disposal</span></div>
                                            </div>
                                            <div className="col-md-4 col-xs-4">
                                                <div><img src={leadership} className="topic-each-icon" /><span className="games-Text">Leadership</span><br /><span className="topics">Business Transform, Models, Business Continuity</span></div>
                                            </div>
                                        </div>
                                        <div className="mt-3 row no-gutters">
                                            <div className="col-md-4 col-xs-4">  <div><img src={fullfilment} className="topic-each-icon" /><span className="games-Text">Fullfilment</span><br /><span className="topics">Logistics, Procurement</span></div>
                                            </div>
                                            <div className="col-md-4 col-xs-4">
                                                <div><img src={legalaudit} className="topic-each-icon" /><span className="games-Text">Legal Audit</span><br /><span className="topics">Regulations, Compliance</span></div>
                                            </div>
                                            <div className="col-md-4 col-xs-4">
                                                <div><img src={governance} className="topic-each-icon" /><span className="games-Text">Governance</span><br /><span className="topics">Management Office, Audit</span></div>
                                            </div>
                                        </div>
                                        <div className="mt-3 row no-gutters">
                                            <div className="col-md-4 col-xs-4"> <div><img src={people} className="mt-3 topic-each-icon" /><span className="games-Text">People</span><br /><span className="topics">Growth Mindset, workspace 4.0</span></div>
                                            </div>
                                            <div className="col-md-4 col-xs-4"> <div><img src={globaltrends} className="topic-each-icon" /><span className="games-Text">Global Trends</span><br /><span className="topics">Smart Governance, Sharing Economy, Next Gen, Localisation</span></div>
                                            </div>
                                            <div className="col-md-4 col-xs-4">
                                                <div><img src={customercentric} className="topic-each-icon" /><span className="games-Text">Customer Centric</span><br /><span className="topics">Last-mile Delivery, Support Resiliency, Flawless Delivery</span></div>
                                            </div>
                                        </div>
                                    </center>
                                    {/* <center>
                                        <div className="topic-allicons  mt-4 mb-2">
                                            <div><img src={games} className="topic-each-icon" /><span className="games-Text">Leadership</span></div>
                                            <div><img src={fullfilment} className="topic-each-icon" /><span className="games-Text">Fullfilment</span></div>
                                            <div><img src={gogreen} className="topic-each-icon" /><span className="games-Text">Go Green</span></div>
                                            <div><img src={customercentric} className="topic-each-icon" /><span className="games-Text">Customer Centric</span></div>
                                           

                                        </div>
                                    </center> */}
                                    {/* <img src={people} className="topic-each-icon" />
                                    <img src={globaltrends} className="topic-each-icon" />
                                    <img src={governance} className="topic-each-icon" />
                                    <img src={leadership} className="topic-each-icon" />
                                    <img src={fulfilment} className="topic-each-icon" />
                                    <img src={gogreen} className="topic-each-icon" /> */}

                                </div><br /><br /><br />
                            </div>
                            <div>
                            </div>
                        </div>

                        <div className="service-about sceem-4es" id="about">
                            <Row style={{ width: "100%" }}  >
                                <Col xs={12} md={6}>
                                    <div className="about-sceem-top">
                                        <div className="about-sceem-heading">Sceem 4Es Platform</div>
                                        <div className="about-sceem-textsm">Sceem 4Es platform to form a knowledge chain. Sceem is short form of pronouncing SCM and 4Es cover Experts, Enterprises, Entrepreneurs and Enthusiasts in supply chain ecosystem.<br /><br />
                                            Supply chain is traditionally driven by operations to help organisations plan internally. Now it is changing drastically through new patters of last mile
                                            delivery to customers and smart devices purchasing directly. Cloud computing, Industry 4.0, IoT, 5G/6G are globally influencing this transformation.
                                            Over 80% of the companies are driving supply chain transformation to align with rapidly changing customer needs and revenue growth and 10% of global GDP.<br /><br />
                                            Geopolitical trade risks and pandemic are creating new scenarios where sustainability, resilience is shaping the future of supply chain beyond globalisation.<br /><br />
                                            Sceem 4Es platform is to bring together all direct and indirect players in supply chain ecosystem. Thus Suppliers, Bankers, Insurer, Telecom, Wholesalers, Distributors, Dealers, Logistics and many more can benefit.<br /><br /></div>
                                        <span><Link to="/knowmore"><Button variant="default" className='knowmore-left know-more'>Explore more</Button></Link><Link to="/statistics"><Button variant="default" className='ml-3 know-more'>Stats</Button></Link></span>
                                    </div >
                                </Col >
                                <Col xs={12} md={6}>
                                    <img src={about} className="about-sceem" />
                                </Col>
                            </Row >
                            <br />
                        </div ><br />
                        <div className="mt-5 service-offerings features-vh" id="offerings">
                            <div className="enable-center">
                                <div className="offerings">Features</div>
                                <center style={{ fontSize: "12px", fontFamily: "Open Sans" }}><i>Hover on circles to know more</i></center>
                                {/* <div className="offerings-allicons">
                                <center className="team-role">
                                    <div class="flip-box">
                                        <div class="flip-box-inner">
                                            <div class="flip-box-front">
                                                <img src={networking} className="offer-each-icon" />
                                            </div>
                                            <div class="flip-box-back">
                                                <p className="network">Connect with eminent authors</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-1 offer-text">Networking</div>
                                </center>
                                <center className="team-role">
                                    <div class="flip-box">
                                        <div class="flip-box-inner">
                                            <div class="flip-box-front">
                                                <img src={opportunity} className="offer-each-icon" />
                                            </div>
                                            <div class="flip-box-back">
                                                <p className="opportunity">Share job postings to find right talent</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-1 offer-text">Opportunities</div>
                                </center>
                                <center className="team-role">
                                    <div class="flip-box">
                                        <div class="flip-box-inner">
                                            <div class="flip-box-front">
                                                <img src={adds} className="offer-each-icon" />
                                            </div>
                                            <div class="flip-box-back">
                                                <p className="advertisement">Recomned your products and services here for focused reach</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-1 offer-text">Advertisements</div>
                                </center>
                            </div>
                            <div className="offerings-allicons-two mb-5">
                                <center className="team-role">
                                    <div class="flip-box">
                                        <div class="flip-box-inner">
                                            <div class="flip-box-front">
                                                <img src={qna} className="offer-each-icon" />
                                            </div>
                                            <div class="flip-box-back">
                                                <p className="articles">Clear your queries from experts</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-1 offer-text">Ask an Expert</div>
                                </center>
                                <center className="team-role">
                                    <div class="flip-box">
                                        <div class="flip-box-inner">
                                            <div class="flip-box-front">
                                                <img src={quizzes} className="offer-each-icon" />
                                            </div>
                                            <div class="flip-box-back">
                                                <p className="polls">Participate in polls and share your option</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-1 offer-text">Polls</div>
                                </center>
                            </div><br /> */}
                                <br /><br />
                                <center>
                                    <div className="flip-lr">
                                        <Row style={{ width: "100%" }} className="no-gutters">
                                            <Col md={3} xs={3}>
                                                <center className="team-role">
                                                    <div class="flip-box">
                                                        <div class="flip-box-inner">
                                                            <div class="flip-box-front">
                                                                <center>
                                                                    <img src={networking} className="offer-each-icon" />
                                                                </center>
                                                            </div>
                                                            <div class="flip-box-back">
                                                                <p className="network">Connect with eminent authors and experts</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-1 offer-text">Networking</div>
                                                </center>
                                            </Col>
                                            <Col md={3} xs={3}>

                                                <center className="team-role">
                                                    <div class="flip-box">
                                                        <div class="flip-box-inner">
                                                            <div class="flip-box-front">
                                                                <center>
                                                                    <img src={opportunity} className="offer-each-icon" />
                                                                </center>
                                                            </div>
                                                            <div class="flip-box-back">
                                                                <p className="opportunity">Share job postings to find right talent</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-1 offer-text">Opportunities</div>
                                                </center>

                                            </Col>
                                            <Col md={3} xs={3}>
                                                <center className="team-role">
                                                    <div class="flip-box">
                                                        <div class="flip-box-inner">
                                                            <div class="flip-box-front">
                                                                <img src={adds} className="offer-each-icon" />
                                                            </div>
                                                            <div class="flip-box-back">
                                                                <p className="polls-adv">Recommend products and services for focused reach</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-1 offer-text">Advertisements</div>
                                                </center>
                                            </Col>
                                            <Col md={3} xs={3}>
                                                <center className="team-role">
                                                    <div class="flip-box">
                                                        <div class="flip-box-inner">
                                                            <div class="flip-box-front">
                                                                <img src={games} className="offer-each-icon" />
                                                            </div>
                                                            <div class="flip-box-back">
                                                                <p className="advertisement">Learn and earn from knowledge based rewarding games</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-1 offer-text">Games</div>
                                                </center>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="flip-lr-2">
                                        <Row className="mt-3" style={{ width: "100%" }}>
                                            <Col md={4} xs={4}>
                                                <center className="team-role">
                                                    <div class="flip-box">
                                                        <div class="flip-box-inner">
                                                            <div class="flip-box-front">
                                                                <img src={qna} className="offer-each-icon" />
                                                            </div>
                                                            <div class="flip-box-back">
                                                                <p className="articles">Get queries answered from domain experts</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-1 offer-text">Ask an Expert</div>
                                                </center>
                                            </Col>
                                            <Col md={4} xs={4}>
                                                <center className="team-role">
                                                    <div class="flip-box">
                                                        <div class="flip-box-inner">
                                                            <div class="flip-box-front">
                                                                <img src={quizzes} className="offer-each-icon" />
                                                            </div>
                                                            <div class="flip-box-back">
                                                                <p className="polls">Vote for opinion that can matter for all</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-1 offer-text">Polls</div>
                                                </center>
                                            </Col>
                                            <Col md={4} xs={4}>
                                                <center className="team-role">
                                                    <div class="flip-box">
                                                        <div class="flip-box-inner">
                                                            <div class="flip-box-front">
                                                                <img src={rewards} className="offer-each-icon" />
                                                            </div>
                                                            <div class="flip-box-back">
                                                                <p className="advertisement">Reach new goals in knowledge chain as an active member</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-1 offer-text">Rewards</div>
                                                </center>
                                            </Col>
                                        </Row>
                                    </div>
                                </center>
                                {/* <div className="offerings-allicons-four">
                                <center className="team-role">
                                    <div class="flip-box">
                                        <div class="flip-box-inner">
                                            <div class="flip-box-front">
                                                <img src={networking} className="offer-each-icon" />
                                            </div>
                                            <div class="flip-box-back">
                                                <p className="network">Connect with eminent authors</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-1 offer-text">Networking</div>
                                </center>
                                <center className="team-role">
                                    <div class="flip-box">
                                        <div class="flip-box-inner">
                                            <div class="flip-box-front">
                                                <img src={opportunity} className="offer-each-icon" />
                                            </div>
                                            <div class="flip-box-back">
                                                <p className="opportunity">Share job postings to find right talent</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-1 offer-text">Opportunities</div>
                                </center>
                                <center className="team-role">
                                    <div class="flip-box">
                                        <div class="flip-box-inner">
                                            <div class="flip-box-front">
                                                <img src={adds} className="offer-each-icon" />
                                            </div>
                                            <div class="flip-box-back">
                                                <p className="advertisement">Recomned your products and services here for focused reach</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-1 offer-text">Advertisements</div>
                                </center>
                                <center className="team-role">
                                    <div class="flip-box">
                                        <div class="flip-box-inner">
                                            <div class="flip-box-front">
                                                <img src={networking} className="offer-each-icon" />
                                            </div>
                                            <div class="flip-box-back">
                                                <p className="network">Connect with eminent authors</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-1 offer-text">Networking</div>
                                </center>
                            </div>
                            <div className="offerings-allicons mb-5">
                                <center className="team-role">
                                    <div class="flip-box">
                                        <div class="flip-box-inner">
                                            <div class="flip-box-front">
                                                <img src={qna} className="offer-each-icon" />
                                            </div>
                                            <div class="flip-box-back">
                                                <p className="articles">Clear your queries from experts</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-1 offer-text">Ask an Expert</div>
                                </center>
                                <center className="team-role">
                                    <div class="flip-box">
                                        <div class="flip-box-inner">
                                            <div class="flip-box-front">
                                                <img src={quizzes} className="offer-each-icon" />
                                            </div>
                                            <div class="flip-box-back">
                                                <p className="polls">Participate in polls and share your option</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-1 offer-text">Polls</div>
                                </center>
                                <center className="team-role">
                                    <div class="flip-box">
                                        <div class="flip-box-inner">
                                            <div class="flip-box-front">
                                                <img src={qna} className="offer-each-icon" />
                                            </div>
                                            <div class="flip-box-back">
                                                <p className="articles">Clear your queries from experts</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-1 offer-text">Ask an Expert</div>
                                </center>
                            </div> */}
                                <br />
                            </div>
                        </div>
                    </div ><br /><br /><br />
                    <div className="service-team" id="team">
                        <div className="team-bg"><br />
                            <div className="team-heading">Our Team</div><br />
                            <center>
                                <Row style={{ width: "80%" }}>
                                    <Col md={4} xs={4} lg={4}>
                                        <center className="team-role"><img src={team4} className="team-each-icon" />
                                            <div className="team-name">Mukundh Bhushan <br /><span style={{ color: "white", fontSize: "10px" }}>Founder & CEO of Nebutech and Threadality</span></div><div className="designation">Head of Product Design</div>
                                        </center></Col>
                                    <Col md={4} xs={4} lg={4}>
                                        <center className="team-role"><img src={team1} className="team-each-icon" />
                                            <div className="team-name">Kasthuri A <br /><span style={{ color: "white", fontSize: "10px" }}>Co-founder of Nebutech</span></div><div className="designation">Head of Product Development</div>
                                        </center>
                                    </Col>
                                    <Col md={4} xs={4} lg={4}>
                                        <center className="team-role"><img src={team6} className="team-each-icon" />
                                            <div className="team-name">Dr.Chakrapani Gopal <br /><span style={{ color: "white", fontSize: "10px" }}> SCM Expert, Author and Advisor</span></div><div className="designation">Product Ideation and mentorship</div>
                                        </center>
                                    </Col>
                                </Row>
                            </center><br />
                            <center>
                                <Row style={{ width: "95%" }}>
                                    <Col md={3} xs={3} lg={3}>
                                        <center className="team-role"><img src={team3} className="team-each-icon" />
                                            <div className="team-name">Srigowri B <br /><span style={{ color: "white", fontSize: "10px" }}> Lead Developer</span>
                                            </div><div className="designation">Build and implementation</div>
                                        </center>
                                    </Col>
                                    <Col md={3} xs={3} lg={3}>
                                        <center className="team-role"><img src={team5} className="team-each-icon" />
                                            <div className="team-name">Kumar Vikash <br /><span style={{ color: "white", fontSize: "10px" }}>Founder of Creative Days</span>

                                            </div><div className="designation">UI/UX design
                                            </div>
                                        </center>
                                    </Col>
                                    <Col md={3} xs={3} lg={3}>
                                        <center className="team-role"><img src={team7} className="team-each-icon" />
                                            <div className="team-name">Zeba Arsi <br /><span style={{ color: "white", fontSize: "10px" }}>Co-founder of Creative Days</span>
                                            </div><div className="designation">UI/UX design</div>
                                        </center>
                                    </Col>
                                    <Col md={3} xs={3} lg={3}>
                                        <center className="team-role"><img src={team2} className="team-each-icon" />
                                            <div className="team-name">Shashank <br /><span style={{ color: "white", fontSize: "10px" }}>Industrialist</span>
                                            </div><div className="designation">Business analyst </div>
                                        </center>
                                    </Col>
                                </Row>
                            </center>
                        </div>
                    </div >
                    <div className="team-bg"><br />
                        <div className="team-heading">Our Partners</div>
                        <center className=" mt-3">
                            <Row style={{ width: "95%" }}>
                                <Col md={3} xs={3} lg={3}>
                                    <center className="team-role"><img src={nebutechpng} className="partner-icon" />
                                        <div className="mt-2 designation">Product Management</div>
                                    </center>
                                </Col>
                                <Col md={3} xs={3} lg={3}>
                                    <center className="team-role"><img src={iscpa} className="partner-icon" />
                                        <div className="mt-2 designation">Product Explication</div>
                                    </center>
                                </Col>
                                <Col md={3} xs={3} lg={3}>
                                    <center className="team-role"><img src={creativedays} className="partner-icon" />
                                        <div className="mt-2 designation">UI/UX Design</div>
                                    </center>
                                </Col>
                                <Col md={3} xs={3} lg={3}>
                                    <center className="team-role"><img src={sugoilabs} className="partner-icon" />
                                        <div className="mt-2 designation">Tech Development</div>
                                    </center>
                                </Col>
                            </Row>
                        </center><br />
                    </div><br /><br /><br />
                    <Row style={{ width: "100%" }} >
                        <Col xs={6} md={6}>
                            <div className="connect">Connect with us</div>
                            <div className="connect-text">We'd love to <br />hear from you</div><br />
                            <div className="connect-gmail">Reach us at <a href="mailto:admin@sceem.org?subject=Connecting%20with%20sceem" className="admin-color">admin@sceem.org</a></div>
                        </Col>
                        <Col xs={6} md={6}>
                            <img src={contact} className="contact-img" />
                        </Col>
                    </Row><br />
                    <div>
                        <hr />
                        <div>
                            {/* <div className="mt-3 latest-ratings">FeddUp Ratings</div> */}
                            <Row style={{ width: "100%", padding: "20px" }}>
                                <Col md={4}>
                                </Col>
                                <Col md={4}>

                                    {/* <center >
                                    <div style={{ fontSize: "20px", marginTop: "10px", fontFamily: "Open Sans" }}>Overall Feddup Rating</div>
                                    <img src={ratingImg} style={{ backgroundImage: `linear-gradient(to right,#ff942c ${avgRating},white ${rating}%)`, width: "140px" }} />
                                    <div className="raters">{this.state.averageRatingCount} ({noOfRaters})</div>
                                </center> */}
                                    <div className="feddback_card">
                                        <div className="feed-ratings">
                                            <Row style={{ width: "100%" }} className="no-gutters">
                                                <Col md={3} xs={3}>
                                                    <div className="rating-v-align">
                                                        <div className="avgRatingCount">{this.state.averageRatingCount}</div>
                                                        <img src={milestone} className="milestone-img" /></div>

                                                </Col>
                                                <Col md={9} xs={9}>
                                                    <div className="listen-T ml-3 latest-ratings">We Listen to your feedback</div>
                                                    <div className="ml-3 latest-ratings">Received insightful feedback from {noOfRaters} users</div>
                                                    <a href={config.sceemlink + this.state.url} target="_blank" style={{ textDecoration: "none" }}>
                                                        <Button variant="default" className="ml-3 btn-block visit-btn">Visit our Feddup page</Button>
                                                    </a>
                                                </Col>
                                            </Row>

                                        </div>
                                    </div>


                                </Col>
                                <Col md={4}>
                                </Col>
                            </Row>
                        </div>
                        <center>
                            <Row className="mt-3 card-b no-gutters">
                                {ratings.map((data, i) => (
                                    <Col md={4}>
                                        <Card className="mt-2 card-padding">
                                            <span style={{ display: "flex", float: "left" }}><img src={rating1} style={data.rating >= 1 ? { backgroundColor: "#ff942c", marginRight: "2px", width: "25px" } : { width: "25px", marginRight: "2px" }} /><img src={rating2} style={data.rating >= 2 ? { backgroundColor: "#ff942c", width: "25px", marginRight: "2px" } : { width: "25px", marginRight: "2px" }} /><img src={rating3} style={data.rating >= 3 ? { backgroundColor: "#ff942c", width: "25px", marginRight: "2px" } : { width: "25px", marginRight: "2px" }} /><img src={rating4} style={data.rating >= 4 ? { backgroundColor: "#ff942c", width: "25px", marginRight: "2px" } : { width: "25px", marginRight: "2px" }} /><img src={rating5} style={data.rating == 5 ? { backgroundColor: "#ff942c", width: "25px" } : { width: "25px" }} /></span>
                                            <i className="mt-3 mb-1 desc">"{data.comment}"</i>
                                            <strong>   <div className="anonymous" >{data.userName == null ? "Anonymous" : data.userName}</div></strong>
                                            <Row style={{ width: "100%" }}>
                                                <Col md={6} xs={6}>
                                                    {/* <i className="anonymous" style={{ textAlign: "left" }}>{data.userName == null ? "Anonymous" : data.userName}</i> */}
                                                </Col>
                                                <Col md={6} xs={6}>
                                                    <div className="rating-date" style={{ float: "right" }}>on {data.createdAt.split("T")[0].split("-").reverse().join("-")}</div>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </center>

                    </div>
                    <div className="background-lp mt-4" >
                        {/* <img alt="" src={background} className="app-background" /> */}
                        <div className="app-border-lp" >
                            <img alt="" src={logo} className="sceem-logo-download-lp" />
                            <center className="chain-text">Creating knowledge chain</center>
                            <div className="p-nebutech-right"> <img alt="" src={nebutech} className="powered-by-nebutech-lp" /></div>
                        </div>
                    </div>
                    <Footer />
                    {/* <div>
                    <img src={background} className="l-app-background service_signup" id="signup" />
                    <div className="l-app-border">
                        <img src={sceemlogo} className="l-sceem-logo-download" /><br />
                        <img src={nebutech} className="l-powered-by-nebutech" />
                        <center className="l-download-text">Download the App now</center>
                        <center className="l-download-text-sm">Connect and collaborate with industry experts and academicians</center>
                        <center><img src={googleplay} className="l-googleplay" /></center>
                    </div>
                </div> */}
                    {/* <Footer /> */}
                </div>


            </div >
        )
    }
}